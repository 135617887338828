<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">

      <div class="mt-3">

        <v-data-table dense :headers="gridDataHeaders" :items="gridDataText" item-key="index"
          :items-per-page="30" :page.sync="page" hide-default-footer @page-count="pageCount = $event"
          height="250px"
          class="grid-default">
          <template v-slot:item="row">
            <tr class="py-2">
              <td class="text-center">{{ row.item.index }}</td>
              <td class="text-center">{{ row.item.index01 }}</td>
              <td class="text-center">{{ row.item.index02 }}</td>
              <td class="text-center">{{ row.item.index03 }}</td>
              <td class="text-center">{{ row.item.index04 }}</td>
              <td class="text-center py-2">
                <v-btn v-if="mixin_set_btn($options.name, 'btnCallCheck')" outlined small class="btn-default" @click="onButtonClick(row.item)">선택</v-btn>
              </td>
            </tr>

          </template>
        </v-data-table>
      </div>

    </div>
  </div>

</template>

<script>
import { mixin } from "@/mixin/mixin.js";
  export default {
    name: "MENU_M110101P02", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    props: {
      headerTitle: {
        type: String,
        default: "제목",
      },
    },
    data: () => ({
      dropItems: [{
        title: "option1"
      }],
      gridDataHeaders: [{
          text: "No",
          align: "center",
          value: "index",
          width: "50px",
          sortable: false,
        },
        {
          text: "고객명",
          value: "index01",
          align: "center",
        },
        {
          text: "전화번호1",
          value: "index02",
          align: "center",
        },
        {
          text: "전화번호2",
          value: "index03",
          align: "center",
        },
        {
          text: "최근상담일시",
          value: "index04",
          align: "center",
        },
        {
          text: "",
          value: "index05",
          align: "center",
          width: "70px"
        },

      ],
      gridDataText: [{
          index: 1,
          index01: "1팀",
          index02: "김상담",
          index03: "3444",
          index04: "상담중",
        },
        {
          index: 2,
          index01: "1팀",
          index02: "김상담",
          index03: "3444",
          index04: "상담중",
        },
        {
          index: 3,
          index01: "1팀",
          index02: "김상담",
          index03: "3444",
          index04: "상담중",
        },
        {
          index: 4,
          index01: "1팀",
          index02: "김상담",
          index03: "3444",
          index04: "상담중",
        },
        {
          index: 5,
          index01: "1팀",
          index02: "김상담",
          index03: "3444",
          index04: "상담중",
        },
        {
          index: 6,
          index01: "1팀",
          index02: "김상담",
          index03: "3444",
          index04: "상담중",
        },
        {
          index: 7,
          index01: "1팀",
          index02: "김상담",
          index03: "3444",
          index04: "상담중",
        },
        {
          index: 8,
          index01: "1팀",
          index02: "김상담",
          index03: "3444",
          index04: "상담중",
        },
        {
          index: 9,
          index01: "1팀",
          index02: "김상담",
          index03: "3444",
          index04: "상담중",
        },

      ],
      page: 1,
      pageCount: 0,
      totalVisible: 10,
      lapseTime: '00:00:00'
    }),
    methods: {
      onButtonClick(item) {
        console.log('click on ' + item.index)
      }
    },
  };
</script>

<style></style>