 <template>
  <div class="layout-maincontainer" style="height: 100%">
    <h1 class="tit-h1">
      <v-icon class="svg-cs-main"></v-icon>
      상담메인
    </h1>
    <div class="layout-chatmain">
      <!-- //mark:콜상담 -->
      <div class="layout-chatmain--call">
        <!-- top -->
        <div class="layout-chatmain--call-top layout-chatmain-box">
          <div class="d-flex">
            <span class="in-label">재택여부</span>
            <v-switch hide-details class="custom-switch3 ml-3" @click="btnCtiHome()"  :disabled="CTI_LOGIN_BOOLEAN === true" v-model="CTI_HOME_BOOLEAN"> <!-- :disabled="CTI_LOGIN_BOOLEAN === true" -->
            </v-switch>
          </div>
          <div class="d-flex ml-4">
            <span class="in-label">콜 상담</span>
            <v-switch hide-details class="custom-switch2 ml-3" @click="btnCtiLogin()" :disabled="CTI_BOOLEAN === true || CTI_ANSWER_BOOLEAN === true" v-model="CTI_LOGIN_BOOLEAN">
            </v-switch>
          </div>
          <div class="layout-chatmain--call-top--info">
            <v-icon :class="
              CTI_CALL_STATUS.incall === true
                ? 'svg-chatmain-incall'
                : 'svg-chatmain-outcall'
            "></v-icon>
            <div class="layout-chatmain--call-top--ci">
              <img :src="CTI_CALL_STATUS.img" :alt="CTI_CALL_STATUS.imgAlt" />
            </div>
            <div class="layout-chatmain--call-top--cs">
              <span class="layout-chatmain--call-top--name">{{
                CTI_CALL_STATUS.name
                }}</span>
              <span class="layout-chatmain--call-top--phone" @mouseover="CTI_CALL_STATUS.mask = true"
                @mouseleave="CTI_CALL_STATUS.mask = false">
                {{ CTI_CALL_STATUS.phoneMaskNumber }}
              </span>
              <span class="layout-chatmain--call-top--phone-unmask" :class="{ active: CTI_CALL_STATUS.mask === true }">{{ CTI_CALL_STATUS.phoneNumber }}</span>
            </div>
          </div>
        </div>
        <!--// top -->
        <!-- //mark: 콜상담저장, 고객정보, 이력 -->
        <div class="layout-chatmain--call-ct d-flex">
          <!-- //mark:고객정보 -->
          <div class="d-flex flex-column">
            <!-- 버튼 -->
            <div class="layout-chatmain--btn layout-chatmain-box">
              <v-btn v-for="(item, index) in CTI_BTN" :key="index" class="cti-btn" :disabled="item.disalb === true" :name=item.icon :id=item.icon
                :class="{'v-btn--active': item.cls === true, 'cti-btn--expand': item.evt === true, 'mr-2' : item.mr === true}"
                @click="
                  item.restEvt ? btnCtiActiveExpand($event) : btnCtiActive($event);
                  item.evt ? showDialog(item.evtId) : '';
                ">
                <v-icon :class="item.refIcon ? CTI_BRD_MOVE.icon : item.icon"></v-icon>
                <span class="layout-chatmain--btn-text">{{ item.refText ? CTI_BRD_MOVE.current : item.text }}</span>
              </v-btn>
              <!-- 전화수신모달 -->
              <v-dialog max-width="320" persistent v-model="dialogM110101P05"
                content-class="square-modal maincall-modal">
                <!-- 고객등급에 따라 modal-wrap 클래스 추가 cs-bl01,cs-bl02,cs-bl03,cs-vip -->
                <div class="modal-wrap"  :class="CTI_TAKE_CALL.blCss">
                  <h1 class="tit-h1 d-flex">
                    수신전화
                    <div class="ml-auto align-self-center">
                      <!--
                      <v-icon
                        x-small
                        class="svg-close"
                        @click="hideDialog('M110101P05')"
                      ></v-icon>
                      사용자 오클릭 방지_주석처리 -->
                    </div>
                  </h1>
                  <div class="modal-box">
                    <div>
                      <div calss="maincall-modal--ci">
                        <img :src="CTI_TAKE_CALL.ci" :alt="CTI_TAKE_CALL.company" />
                      </div>
                      <span class="maincall-modal--company">{{
                        CTI_TAKE_CALL.company
                      }}</span>
                      <span class="maincall-modal--phone">{{
                        CTI_TAKE_CALL.phone
                      }}</span>
                      <span class="maincall-modal--text">{{
                        CTI_TAKE_CALL.text
                      }}</span>
                      <div class="maincall-modal--btn">
                        <v-btn outlined large class="btn-etc col-12" @click="btnCtiAnser()">
                          전화받기
                          <v-icon x-small class="svg-chatmain-btn-call ml-2"></v-icon>
                        </v-btn>
                      </div>
                      <!-- 추가 거부,전환 버튼 -->
                      <div class="maincall-modal--btn d-flex mt-4">
                        <v-btn outlined class="btn-point" @click="btnCtiSkin()">
                          거부
                        </v-btn>
                        <v-btn outlined class="btn-etc2 ml-2" @click="btnCtiTrans()">
                          전환
                        </v-btn>
                        <v-text-field class="form-inp ml-2 pt-0 " name="name" label="전환할 내선번호" outlined v-model="TRANS_INLINE_NO">
                        </v-text-field>
                      </div>
                    </div>
                  </div>
                </div>
              </v-dialog>

              <!-- 호전환 모달 -->
              <v-dialog max-width="655" persistent v-model="dialogM110101P01" content-class="square-modal maincalltrans-modal">
                <!-- @submit="submitDialog('M110101P01')" -->
                <dialog-M110101P01 headerTitle="호전환"
                  @hide="hideDialog('M110101P01')"
                  @ctiPhoneTran="ctiPhoneTran('M110101P01')"
                  @ctiPhoneTrno="ctiPhoneTrno('M110101P01')"
                  @ctiPhoneTrok="ctiPhoneTrok('M110101P01')"
                  @ctiPhoneConf="ctiPhoneConf('M110101P01')"
                  @ctiPhoneCfno="ctiPhoneCfno('M110101P01')"
                  @ctiPhoneCfok="ctiPhoneCfok('M110101P01')"
                  :CTI_TRANSFER_INFO="CTI_TRANSFER_INFO"
                  @input="CTI_TRANSFER_INFO = $event">
                </dialog-M110101P01>
              </v-dialog>

              <!-- 이석 모달 -->
              <div class="cti-toggle-menu" v-show="CTI_BRD.expand == true">
                <button v-for="(item, index) in CTI_REASON" :key="index" @click="btnCtiReason(index)" class="mb-4">
                  <v-icon :class="item.className"></v-icon><span class="cti-btn--expand-title">{{
                    item.text
                  }}</span>
                </button>
              </div>
              <!-- OB전용 -->
              <div class="d-flex align-center mt-2 mr-2" v-if="CTI_OB_DISABLE === true">
                <v-checkbox
                :on-icon="'svg-chk-on'"
                :off-icon="'svg-chk-off'"
                label="OB 전용"
                hide-details
                v-model="CTI_OB_CHK"
                :disabled="CTI_DB_STATE != 'handle'"
                @click="btnCtiObChk"
                ></v-checkbox>
              </div>
              <!-- cti 상태 -->
              <div class="cti-current-wrap">
                <span class="cti-current" :class="{'ani-twinkle': CTI_BRD.ctiTextLapse === true}">
                  {{ ctiStateText }}
                  <!-- {{ ctiBoardMove.current ? ctiBoard.current : "CTI OFF" }} -->
                  <v-icon :class="CTI_BRD_MOVE.status == true ? CTI_BRD_MOVE.icon : CTI_BRD.icon" class="ml-2">
                  </v-icon>
                </span>
                <span class="cti-time" :class="{'clr-error': CTI_BRD.ctiTimeLapse === true}">{{ CTI_BRD.lapseTime }}</span>
              </div>
            </div>
            <!-- //버튼 -->
            <!--// 콜 상담저장 -->
            <div class="layout-chatmain--form layout-chatmain-box flex-grow-1">
              <h2 class="tit-h2 mt-3 d-flex justify-space-between">콜 상담저장
                <div class="d-flex justify-space-between align-center">
                  <v-radio-group v-model="CNSL_CALL_TY" row class="radio-inline">
                    <template v-slot:label>
                      <span class="in-label">콜유형</span>
                    </template>
                    <v-radio v-for="(rad01, index) in CNSL_CALL_TY_RADIOGRP" :key="CNSL_CALL_TY_RADIOGRP[index].text"
                      :label="rad01.text" :on-icon="'svg-rad-on'" :off-icon="'svg-rad-off'" :value="rad01.value"
                      :disabled="CNSL_DISABLED === true"
                       v-on:click="CHG_CNSL_BLNG">
                    </v-radio>
                  </v-radio-group>
                  <div class="form-inp pa-0 ml-2" style="width: 80px">
                    <!-- 퍼블 : 임시로 disable 풀어놈 -->
                    <v-select :items="BL_DROPITEMS" item-text="icon" outlined placeholder="B/L" hide-details
                      v-model="CNSL_ATENT_CUSTOMER" :disabled="CNSL_DISABLED === true">
                      <template slot="selection" slot-scope="data">
                        <v-icon :class="[data.item.icon]"></v-icon>
                        <div class="v-select__selection v-select__selection--comma">
                          {{ data.item.title }}
                        </div>
                      </template>
                      <template slot="item" slot-scope="data">
                        <v-icon :class="[data.item.icon]"></v-icon>
                        <div class="v-list-item__title" style="flex-basis: auto">
                          {{ data.item.title }}
                        </div>
                      </template>
                    </v-select>
                  </div>
                </div>
              </h2>

              <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                  <v-select
                      :items="CNSL_RESULT_LIST"
                      outlined
                      placeholder="상담완료"
                      v-model="CNSL_RESULT"
                    >
                      <template v-slot:label>
                        상담결과
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                <!--저장로직 확인을 위해 임시로 CNSL_DISABLED === false-->
               <!-- label="예약콜"  form-inp pa-0 -->
               <div class="mt-2 ml-2">
                <v-checkbox
                  :on-icon="'svg-chk-on'"
                  :off-icon="'svg-chk-off'"
                  hide-details
                  v-model="CNSL_RESVE_CHK"
                ></v-checkbox> <!-- :disabled="CNSL_DISABLED === true" -->
                 </div>
                <div >
                <v-menu
                  v-model="cnslResveMenu"
                  :close-on-content-click="false"
                  :nudge-right="10"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  content-class="calendar-modal"
                  color="#FFF"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="CNSL_RESVE_DT"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      hide-details
                      class="form-inp free icon-calendar pt-0"
                      style="width: 135px"
                      append-icon="svg-calendar"
                    >
                      <template v-slot:label>
                        예약콜 일자
                      </template>
                    </v-text-field>
                  </template>
                  <div class="d-flex align-center">
                    <v-date-picker
                      v-model="cnslResveDate"
                      dark
                      color="#F7AFAE"
                      no-title
                      show-current="false"
                      :first-day-of-week="1"
                      locale="ko-KR"
                      :day-format="mixin_getDate"
                    ></v-date-picker>
                    <v-time-picker
                      v-model="cnslResveTimeStep"
                      :allowed-minutes="allowedStep"
                      color="#F7AFAE"
                      format="24hr"
                    >
                    </v-time-picker>
                  </div>
                  <div class="text-center pt-3 pb-3">
                    <v-btn
                      outlined
                      small
                      class="btn-point ml-3"
                      @click="cnslResveMenu = false"
                      >확인</v-btn
                    >
                  </div>
                </v-menu>
                </div>
              </div>

                <!-- 대분류, 중분류 -->
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      :items="CNSL_LV_1_LIST"
                      item-text = "CD_NM"
                      item-value = "CD"
                      outlined
                      placeholder="상담유형1"
                      v-model="CNSL_LV_1"
                      v-on:change="getConsultTY('1')"
                    >
                      <template v-slot:label>
                        상담유형1
                        <v-icon v-if="CNSL_BLNG != 'OUT'" color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select
                      :items="CNSL_LV_2_LIST"
                      item-text = "CD_NM"
                      item-value = "CD"
                      outlined
                      placeholder="상담유형2"
                      v-model="CNSL_LV_2"
                      v-on:change="getConsultTY('2')"
                    >
                      <template v-slot:label>
                        상담유형2
                        <v-icon v-if="CNSL_BLNG != 'OUT'" color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <!-- 소분류 -->
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      :items="CNSL_LV_3_LIST"
                      item-text = "CD_NM"
                      item-value = "CD"
                      outlined
                      placeholder="상담유형3"
                      v-model="CNSL_LV_3"
                      v-on:change="getConsultTY('3')"
                    >
                      <template v-slot:label>
                        상담유형3
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                  <v-select
                      :items="CNSL_LV_4_LIST"
                      item-text = "CD_NM"
                      item-value = "CD"
                      outlined
                      placeholder="상담유형4"
                      v-model="CNSL_LV_4"
                    >
                      <template v-slot:label>
                        상담유형4
                      </template>
                    </v-select>
                  </div>
                </div>
              <!-- IN -->
              <template v-if="CNSL_BLNG == 'IN'">
                <!-- 상담채널, 채널기타 -->
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      :items="CNSL_CHN_1_LIST"
                      outlined
                      placeholder="상담채널"
                      v-model="CNSL_CHN_1"
                      v-on:change="chgCnslChn"
                    >
                      <template v-slot:label>
                        상담채널
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select
                      :items="CNSL_CHN_2_LIST"
                      outlined
                      placeholder="채널기타"
                      v-model="CNSL_CHN_2"
                    >
                      <template v-slot:label>
                        채널기타
                      </template>
                    </v-select>
                  </div>
                </div>
                <!-- 상담예약구분 -->
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0 line-clr01">
                    <v-select
                      :items="CONS_BOOK_LIST"
                      outlined
                      placeholder="상담예약"
                      v-model="CONS_BOOK"
                    >
                      <template v-slot:label>
                        상담예약
                      </template>
                    </v-select>
                  </div>
                  <v-menu
                    ref="consBookMenu"
                    v-model="consBookMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal dateTime"
                    color="#FFF"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="CONS_BOOK_DT"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp line-clr01 ml-2 free pt-0 icon-calendar"
                        append-icon="svg-calendar"
                      >
                        <template v-slot:label>
                          상담예약일자
                        </template>
                      </v-text-field>
                    </template>
                    <div class="d-flex">
                      <v-date-picker
                        v-model="consBookDate"
                        dark
                        color="#F7AFAE"
                        show-current="false"
                        class="borderR16"
                        :first-day-of-week="1"
                        locale="ko-KR"
                        :day-format="mixin_getDate"
                      ></v-date-picker>
                      <v-time-picker
                        v-model="consBookTimeStep"
                        :allowed-minutes="allowedStep"
                        color="#F7AFAE"
                        format="24hr"
                        class="borderR16"
                      ></v-time-picker>
                    </div>
                      <div class="text-center pt-3 pb-3">
                        <v-btn
                          outlined
                          small
                          class="btn-point ml-3"
                          @click="consBookMenu = false"
                          >확인</v-btn
                        >
                      </div>
                  </v-menu>
                </div>
                <!-- 문의내용, 내원경로 -->
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      :items="CNSL_CONT_LIST"
                      outlined
                      placeholder="문의내용"
                      v-model="CNSL_CONT"
                    >
                      <template v-slot:label>
                        문의내용
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select
                      :items="VIS_PATH_LIST"
                      outlined
                      placeholder="내원경로"
                      v-model="VIS_PATH"
                    >
                      <template v-slot:label>
                        내원경로
                      </template>
                    </v-select>
                  </div>
                </div>
                <!-- 진료항목, 간격일수 -->
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      :items="DISA_ITEM_LIST"
                      outlined
                      placeholder="진료항목"
                      v-model="DISA_ITEM"
                    >
                      <template v-slot:label>
                        진료항목
                      </template>
                    </v-select>
                  </div>
                  <v-text-field
                    class="form-inp free pa-0 ml-2"
                    outlined
                    hide-details
                    v-model="VIS_TERM"
                  >
                    <template v-slot:label> 간격일수 </template>
                  </v-text-field>
                </div>
                <!-- 문의/예약구분 -->
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp line-clr01 col-6 pa-0">
                    <v-select
                      :items="CNSL_BOOK_LIST"
                      outlined
                      placeholder="문의/예약구분"
                      v-model="CNSL_BOOK"
                    >
                      <template v-slot:label>
                        문의/예약구분
                      </template>
                    </v-select>
                  </div>
                  <v-menu
                    ref="cnslBookmenu"
                    v-model="cnslBookMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal dateTime"
                    color="#FFF"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="CNSL_BOOK_DT"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp line-clr01 ml-2 free pt-0 icon-calendar"
                        append-icon="svg-calendar"
                      >
                        <template v-slot:label>
                          문의/예약일자
                        </template>
                      </v-text-field>
                    </template>
                    <div class="d-flex">
                      <v-date-picker
                        v-model="cnslBookDate"
                        dark
                        color="#F7AFAE"
                        show-current="false"
                        class="borderR16"
                        :first-day-of-week="1"
                        locale="ko-KR"
                        :day-format="mixin_getDate"
                      ></v-date-picker>
                      <v-time-picker
                        v-model="cnslBookTimeStep"
                        :allowed-minutes="allowedStep"
                        color="#F7AFAE"
                        format="24hr"
                        class="borderR16"
                      ></v-time-picker>
                    </div>
                      <div class="text-center pt-3 pb-3">
                        <v-btn
                          outlined
                          small
                          class="btn-point ml-3"
                          @click="cnslBookMenu = false"
                          >확인</v-btn
                        >
                      </div>
                  </v-menu>
                </div>
              </template>

              <!-- OB -->
              <template v-if="CNSL_BLNG == 'OUT'">
                <!-- 카테고리, 상태 -->
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      :items="CATEGORY_LIST"
                      outlined
                      placeholder="카테고리"
                      v-model="CATEGORY"
                    >
                      <template v-slot:label>
                        카테고리
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select
                      :items="CONDITION_LIST"
                      outlined
                      placeholder="상태"
                      v-model="CONDITION"
                    >
                      <template v-slot:label>
                        상태
                      </template>
                    </v-select>
                  </div>
                </div>
                <!-- 상담예약구분 -->
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0 line-clr01">
                    <v-select
                      :items="CONS_BOOK_LIST"
                      outlined
                      placeholder="상담예약"
                      v-model="CONS_BOOK"
                    >
                      <template v-slot:label>
                        상담예약
                      </template>
                    </v-select>
                  </div>
                  <v-menu
                    ref="consBookMenu"
                    v-model="consBookMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal dateTime"
                    color="#FFF"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="CONS_BOOK_DT"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp line-clr01 ml-2 free pt-0 icon-calendar"
                        append-icon="svg-calendar"
                      >
                        <template v-slot:label>
                          상담예약일자
                        </template>
                      </v-text-field>
                      </v-text-field>
                    </template>
                    <div class="d-flex">
                      <v-date-picker
                        v-model="consBookDate"
                        dark
                        color="#F7AFAE"
                        show-current="false"
                        class="borderR16"
                        :first-day-of-week="1"
                        locale="ko-KR"
                        :day-format="mixin_getDate"
                      ></v-date-picker>
                      <v-time-picker
                        v-model="consBookTimeStep"
                        :allowed-minutes="allowedStep"
                        color="#F7AFAE"
                        format="24hr"
                        class="borderR16"
                      ></v-time-picker>
                    </div>
                      <div class="text-center pt-3 pb-3">
                        <v-btn
                          outlined
                          small
                          class="btn-point ml-3"
                          @click="consBookMenu = false"
                          >확인</v-btn
                        >
                      </div>
                  </v-menu>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <!-- 상담내원 -->
                  <div class="form-inp col-6 pa-0 line-clr02">
                    <v-select
                      :items="VIS_BOOK_LIST"
                      outlined
                      placeholder="상담내원"
                      v-model="VIS_BOOK"
                    >
                      <template v-slot:label>
                        상담내원
                      </template>
                    </v-select>
                    <v-menu
                      ref="visBookMenu"
                      v-model="visBookMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      content-class="calendar-modal dateTime"
                      color="#FFF"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="VIS_BOOK_DT"
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          hide-details
                          class="form-inp line-clr02 full mt-2 pt-0 icon-calendar"
                          append-icon="svg-calendar"
                        >
                          <template v-slot:label>
                            상담내원일자
                          </template>
                        </v-text-field>
                      </template>
                      <div class="d-flex">
                        <v-date-picker
                          v-model="visBookDate"
                          dark
                          color="#F7AFAE"
                          show-current="false"
                          class="borderR16"
                          :first-day-of-week="1"
                          locale="ko-KR"
                          :day-format="mixin_getDate"
                        ></v-date-picker>
                        <v-time-picker
                          v-model="visBookTimeStep"
                          :allowed-minutes="allowedStep"
                          color="#F7AFAE"
                          format="24hr"
                          class="borderR16"
                        ></v-time-picker>
                      </div>
                        <div class="text-center pt-3 pb-3">
                          <v-btn
                            outlined
                            small
                            class="btn-point ml-3"
                            @click="visBookMenu = false"
                            >확인</v-btn
                          >
                        </div>
                    </v-menu>
                    <div class="form-inp free pa-0 mt-2 line-clr02">
                      <v-select
                        :items="dropItems"
                        item-text="title"
                        outlined
                        placeholder="상담내원담당"
                        v-model="VIS_MANAGE"
                      >
                        <template v-slot:label>
                          상담내원담당자
                        </template>
                      </v-select>
                    </div>
                  </div>
                  <!-- 수술예약 -->
                  <div class="form-inp line-clr03 free pa-0 ml-2">
                    <v-select
                      :items="OPER_BOOK_LIST"
                      outlined
                      placeholder="수술예약"
                      v-model="OPER_BOOK"
                    >
                      <template v-slot:label>
                        수술예약
                      </template>
                    </v-select>
                    <v-menu
                      ref="operBookMenu"
                      v-model="operBookMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      content-class="calendar-modal dateTime"
                      color="#FFF"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="OPER_BOOK_DT"
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          hide-details
                          class="form-inp line-clr03 full mt-2 pt-0 icon-calendar"
                          append-icon="svg-calendar"
                        >
                          <template v-slot:label>
                            수술예약일자
                          </template>
                        </v-text-field>
                      </template>
                      <div class="d-flex">
                        <v-date-picker
                          v-model="operBookDate"
                          dark
                          color="#F7AFAE"
                          show-current="false"
                          class="borderR16"
                          :first-day-of-week="1"
                          locale="ko-KR"
                          :day-format="mixin_getDate"
                        >
                        </v-date-picker>
                        <v-time-picker
                          v-model="operBookTimeStep"
                          :allowed-minutes="allowedStep"
                          color="#F7AFAE"
                          format="24hr"
                          class="borderR16"
                        ></v-time-picker>
                      </div>
                        <div class="text-center pt-3 pb-3">
                          <v-btn
                            outlined
                            small
                            class="btn-point ml-3"
                            @click="operBookMenu = false"
                            >확인</v-btn
                          >
                        </div>
                    </v-menu>
                    <div class="form-inp line-clr03 free pa-0 mt-2">
                      <v-select
                        :items="dropItems"
                        item-text="title"
                        outlined
                        placeholder="수술예약담당"
                        v-model="OPER_MANAGE"
                      >
                        <template v-slot:label>
                          수술예약담당자
                        </template>
                      </v-select>
                    </div>
                  </div>
                </div>
                <!-- 수술 -->
                <!--
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp line-clr04 col-6 pa-0">
                    <v-select
                      :items="OPERATION_LIST"
                      outlined
                      placeholder="수술"
                      v-model="OPERATION"
                    >
                      <template v-slot:label>
                        수술
                      </template>
                    </v-select>
                  </div>
                  <v-menu
                    ref="operationMenu"
                    v-model="operationMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal dateTime"
                    color="#FFF"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="OPERATION_DT"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp line-clr04 ml-2 free pt-0 icon-calendar"
                        append-icon="svg-calendar"
                      >
                        <template v-slot:label>
                          수술일자
                        </template>
                      </v-text-field>
                    </template>
                    <div class="d-flex">
                      <v-date-picker
                        v-model="operationDate"
                        dark
                        color="#F7AFAE"
                        show-current="false"
                        class="borderR16"
                        :first-day-of-week="1"
                        locale="ko-KR"
                        :day-format="mixin_getDate"
                      ></v-date-picker>
                      <v-time-picker
                        v-model="operationTimeStep"
                        :allowed-minutes="allowedStep"
                        color="#F7AFAE"
                        format="24hr"
                        class="borderR16"
                      ></v-time-picker>
                    </div>
                        <div class="text-center pt-3 pb-3">
                          <v-btn
                            outlined
                            small
                            class="btn-point ml-3"
                            @click="operationMenu = false"
                            >확인</v-btn
                          >
                        </div>
                  </v-menu>
                </div>
                -->
                <!-- 수술금액/상담결과 -->
                <!--
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="col-6  pa-0">
                    <v-text-field
                      class="form-inp full pa-0"
                      outlined
                      hide-details
                      v-model="OPERATION_COST"
                    >
                      <template v-slot:label> 수술 금액 </template>
                    </v-text-field>
                  </div>
                </div>
                -->
              </template>

              <!--
              <div class="d-flex justify-space-between align-center mt-2">
                <div class="form-inp col-6 pa-0">
                  <v-select
                    :items="CNSL_PROC_TY_DROPITEMS"
                    :item-value="CNSL_PROC_TY_DROPITEMS.value"
                    :item-text="CNSL_PROC_TY_DROPITEMS.text"
                    outlined
                    placeholder="처리구분"
                    v-model="CNSL_PROC_TY"
                    >
                    <template v-slot:label>
                      처리구분(콜백/예약/캠페인)
                    </template>
                  </v-select>
                </div>
                <div class="form-inp free pa-0 ml-2">
                  <v-select
                    :items="CNSL_PROC_RLT_DROPITEMS"
                    :item-value="CNSL_PROC_RLT_DROPITEMS.value"
                    :item-text="CNSL_PROC_RLT_DROPITEMS.text"
                    outlined
                    placeholder="처리구분"
                    v-model="CNSL_PROC_RLT"
                    >
                    <template v-slot:label>
                      처리결과(콜백/캠페인)
                    </template>
                  </v-select>
                </div>
              </div>
              -->

              <!-- 공통 비고, 상담내용 -->
              <div class="mt-2">
                <!--저장로직 확인을 위해 임시로 CNSL_DISABLED === false-->
                <v-textarea
                  label=""
                  no-resize
                  outlined
                  height="30px"
                  :rows="1"
                  hide-details
                  dense
                  class="mt-0"
                  placeholder="특이사항/비고"
                  v-model="ETC"
                ></v-textarea>        <!-- :disabled="CNSL_DISABLED === true" -->
              </div>
              <div class="mt-2">
                <!--저장로직 확인을 위해 임시로 CNSL_DISABLED === false-->
                <v-textarea
                  label=""
                  no-resize
                  outlined
                  height="48px"
                  hide-details
                  v-model="CNSL_CNTN"
                > <!-- :disabled="CNSL_DISABLED === true" -->
                  <template v-slot:label> 상담내용 </template>
                </v-textarea>
              </div>
              <!--저장로직 확인을 위해 임시로 CNSL_DISABLED === false-->
              <div class="d-flex justify-space-between align-center mt-2">
              <div v-if="CNSL_BLNG != 'FUNU'" class="mt-2 text-right">
                <!--저장로직 확인을 위해 임시로 CNSL_DISABLED === false-->
                <v-checkbox
                  :on-icon="'svg-chk-on'"
                  :off-icon="'svg-chk-off'"
                  label="차수추가"
                  hide-details
                  v-model="CNSL_NUM_CHK"
                ></v-checkbox> <!-- :disabled="CNSL_DISABLED === true" -->
              </div>
              <div class="mt-2 text-right">
                <v-btn
                  outlined
                  class="btn-point ml-3"
                  :disabled="CTI_DB_STATE != 'hangup'"
                  v-on:click="btnCnslSave()"
                  >저장
                </v-btn><!-- v-on:click="confirmSave('CALL', '상담을 저장하시겠습니까?')" -->
              </div>
              </div>
              <!--저장로직 확인을 위해 임시로 CNSL_DISABLED === false-->
            </div>
            <!--// 콜 상담저장 -->
          </div>
          <!-- //mark:고객정보, 고객상담이력, -->
          <div class="layout-chatmain--cs-info layout-chatmain-box">
            <v-tabs class="tabs-form" slider-color="#6C6E7A" slider-size="3">
              <v-tab>고객정보</v-tab>
              <v-tab>고객상담이력</v-tab>
              <!-- 고객정보 컨텐츠 -->
              <v-tab-item>
                <div class="layout-chatmain--cs-info-ct">
                  <!-- 전화상담 고객정보 조회 시작 Arthur.Kim -->
                  <div class="align-center gray-box">
                    <div class="d-flex">
                      <v-select class="form-inp free pt-0" :items="ASP_CUST_KEY" item-value="CD" item-text="CD_NM"
                        outlined hide-details label="업체명" placeholder="선택하세요" v-model="SCH_ASP_CUST_KEY"
                        :readonly = "CTI_ANSWER_BOOLEAN === true || RDOnly === true"
                        style="width: 198px"> <!-- :readonly = "CTI_DB_STATE === 'ready' || CTI_INFO.strCallMode === 'inbound' || CTI_INFO.strCallMode === 'outbound'" -->
                        <template v-slot:label>
                          업체명
                          <v-icon color="#EF9191">mdi-circle-small</v-icon>
                        </template>
                      </v-select>
                      <v-text-field class="form-inp pt-0 ml-2"
                        label="전화번호 "
                        id="callnum"
                        outlined
                        hide-details
                        v-model="SCH_CUSTOMER_PHONE_NO"> <!-- :readonly = "CTI_DB_STATE === 'ready' || CTI_INFO.strCallMode === 'inbound' || CTI_INFO.strCallMode === 'outbound'" -->
                      </v-text-field>
                      <!-- 복사 버튼 -->
                      <v-btn
                        dark
                        small
                        class="btn-main-refresh pa-0 ml-2"
                        min-width="32px"
                        height="32px"
                        plain
                        >
                        <v-icon small class="svg-copy-white"></v-icon>
                      </v-btn>
                    </div>
                    <div class="d-flex mt-2">
                      <v-text-field
                        class="form-inp free pt-0"
                        label="고객명"
                        outlined
                        hide-details
                        v-model="SCH_CUSTOMER_NM"> <!-- :readonly = "CTI_DB_STATE === 'ready' || CTI_INFO.strCallMode === 'inbound' || CTI_INFO.strCallMode === 'outbound'" -->
                      </v-text-field>
                      <div class="ml-2 align-self-center mr-0">
                        <!-- 조회 버튼 @click="showDialog('M110101P02')"-->
                        <v-btn dark small class="btn-main-search pa-0" min-width="32px" height="32px" plain
                          @click="[btnCust(CNSL_WK[0]), btnCusSrch()]"> <!-- showDialog('MP01') :readonly = "CTI_DB_STATE === 'ready' || CTI_INFO.strCallMode === 'inbound' || CTI_INFO.strCallMode === 'outbound'" -->
                          <v-icon small class="svg-main-searh white"></v-icon>
                        </v-btn>
                        <!-- 수정/저장 버튼 -->
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn dark small class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px" plain
                              v-bind="attrs" v-on="on"
                              @click="[CUS_DISABLED = !CUS_DISABLED, btnCusInfo()]">    <!--  :disabled="CUS_INPUT_DISABLED === true" -->
                              <v-icon small class="svg-main-down"></v-icon>
                            </v-btn>
                          </template>
                          <span>수정/저장</span>
                        </v-tooltip>
                        <!-- 초기화 버튼 -->
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn dark small class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px" plain
                              v-bind="attrs" v-on="on"
                              :readonly = "CTI_DB_STATE === 'hangup'"
                              @click="btnCusInit()">                                                                    <!-- @click="[CUS_DISABLED = !CUS_DISABLED, initCallCusSch(), initCallCus()]" :readonly = "CTI_DB_STATE === 'hangup' || CTI_INFO.strCallMode === 'inbound' || CTI_INFO.strCallMode === 'outbound'" -->
                              <v-icon small class="svg-main-refresh"></v-icon>
                            </v-btn>
                          </template>
                          <span>초기화</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <!-- 조회 모달 -->
                    <v-dialog max-width="810" persistent v-model="dialogM110101P02"
                      content-class="square-modal maincalltrans-modal">
                      <dialog-M110101P02 headerTitle="고객정보조회" @hide="hideDialog('M110101P02')">
                      </dialog-M110101P02>
                    </v-dialog>
                  </div>
                  <!-- 전화상담 고객정보 조회 종료 Arthur.Kim -->

                  <!-- 전화상담 고객정보 시작 Arthur.Kim -->
                  <div class="mt-3 layout-chatmain--cs-info-ct-form">
                    <div class="d-flex align-stretch">
                      <span class="in-label text-left col-4">업체명 </span>
                      <!-- <v-text-field class="form-inp free pt-0 ml-2" outlined hide-details readonly v-model="CUS_ASP_CUST_NM"></v-text-field> --> <!-- :readonly="CUS_DISABLED === true" -->
                      <v-select class="form-inp free pt-0 ml-2" :items="ASP_CUST_KEY" item-value="CD" item-text="CD_NM"
                        outlined hide-details placeholder="업체명" v-model="CUS_ASP_CUST_KEY"
                        :readonly="CUS_OB_DISABLED === true || RDOnly === true">
                      </v-select>
                   </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">
                        <v-badge dot color="#EF9191">고객명</v-badge>
                      </span>
                      <v-text-field class="form-inp free pt-0 ml-2" outlined hide-details :readonly="CUS_DISABLED === true" v-model="CUS_CUSTOMER_NM"></v-text-field>
                    </div>
                    <!-- 동행자 -->
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">
                        <v-checkbox :on-icon="'svg-chk-on'" :off-icon="'svg-chk-off'" :readonly="CUS_DISABLED === true" v-model="CUS_CUSTOMER_SIDE_DISABLED">
                          <template v-slot:label>
                            <span class="in-label pa-0">동행자</span>
                          </template>
                        </v-checkbox>
                      </span>

                      <v-text-field class="form-inp free pt-0 ml-2" outlined hide-details
                        :readonly="CUS_CUSTOMER_SIDE_DISABLED === false" v-model="CUS_CUSTOMER_SIDE_NM"></v-text-field>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">Key 값</span>
                      <v-text-field class="form-inp free pt-0 ml-2" outlined hide-details readonly v-model="CUS_TALK_USER_KEY"></v-text-field> <!-- :readonly="CUS_DISABLED === true" -->
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">
                        <v-badge dot color="#EF9191">전화번호1</v-badge>
                        <template>
                          <v-btn absolute depressed icon x-small @click="showDialog('M110101P03')" class="fomr-inp--btn svg-call-red ml-6" :disabled="CUS_PHONE_DISABLED === true"> <!-- :disabled="CUS_PHONE_DISABLED === true" -->
                          </v-btn>
                        </template>
                      </span>
                      <v-text-field class="form-inp free pt-0 ml-2" name="name" value="" outlined hide-details v-model="CUS_PHONE_NO" :readonly="CUS_OB_DISABLED === true"> <!-- readonly -->
                        <template v-slot:append>
                          <v-btn depressed icon x-small @click="btnCustPhoneCpy()" class="fomr-inp--btn svg-copy" :disabled="CUS_PHONE_DISABLED === true">
                          </v-btn>
                        </template>
                      </v-text-field>
                      <!-- 전화 걸기 모달 -->
                      <v-dialog max-width="320" persistent v-model="dialogM110101P03"
                        content-class="square-modal maincall-modal">
                        <div class="modal-wrap">
                          <h1 class="tit-h1 d-flex">
                            발신전화
                            <div class="ml-auto align-self-center">
                              <v-icon x-small class="svg-close" @click="hideDialog('M110101P03')"></v-icon>
                            </div>
                          </h1>
                          <div class="modal-box type-call">
                            <div>
                              <div calss="maincall-modal--ci">
                                <img :src="CTI_SEND_CALL.ci" :alt="CTI_SEND_CALL.company" />
                              </div>
                              <div class="form-inp pa-0 mt-2" style="width: 163px">
                                <v-select :items="ASP_CUST_KEY" item-value="CD" item-text="CD_NM" outlined hide-details placeholder="업체명" v-model="CTI_SEND_ASP_CUST_KEY"  readonly></v-select>
                              </div>
                              <!--
                              <span class="maincall-modal--company type-call" style="width: 163px">{{ CTI_SEND_CALL.first }}</span>
                              -->
                              <div class="form-inp pa-0 mt-2" style="width: 163px">
                                <v-select :items="TAKE_CUST_TEL" :item-value="TAKE_CUST_TEL.value" :item-text="TAKE_CUST_TEL.text" outlined hide-details placeholder="발신구분" v-model="CTI_TAKE_CUST_TEL"></v-select>
                              </div>
                              <span class="maincall-modal--phone type-call"></span>
                              <span class="maincall-modal--text type-inp">
                                <input type="text" class="text-center" v-model="CTI_SEND_CALL_PHONE" :readonly="CTI_OB_CHK === false">
                              </span>
                              <div class="maincall-modal--btn">
                                <v-btn outlined large class="btn-point" @click="btnCtiMakeCall()">
                                  걸기
                                </v-btn>
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-dialog>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">전화번호2 </span>
                      <v-text-field class="form-inp free pt-0 ml-2" name="name" outlined hide-details :readonly="CUS_DISABLED === true" v-model="CUS_WRC_TEL"></v-text-field>
                    </div>
                    <!-- 성별 -->
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">성별</span>
                      <div class="form-inp full pa-0 ml-2">
                        <v-select :items="CUS_GENDER_DROPITEMS" :item-value="CUS_GENDER_DROPITEMS.value" :item-text="CUS_GENDER_DROPITEMS.text" outlined placeholder="성별" hide-details
                          :readonly="CUS_DISABLED === true" v-model="CUS_CUSTOMER_GENDER">
                        </v-select>
                      </div>
                    </div>
                    <!-- 연령 -->
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">연령 </span>
                      <v-text-field class="form-inp free pt-0 ml-2" outlined hide-details
                        :readonly="CUS_DISABLED === true" v-model="CUS_CUSTOMER_AGE"></v-text-field>
                    </div>
                    <!-- 지역 -->
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">지역 </span>
                      <v-text-field class="form-inp free pt-0 ml-2" outlined hide-details
                        :readonly="CUS_DISABLED === true" v-model="CUS_CUSTOMER_LOCAL"></v-text-field>
                    </div>
                    <!-- 상담희망시간 -->
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">상담희망시간 </span>
                      <v-menu
                        ref="CUS_CS_TIME_CAL"
                        v-model="CUS_CS_TIME_CAL"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        content-class="calendar-modal"
                        color="#FFF"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            hide-details
                            class="form-inp icon-calendar ml-2 pt-0"
                            append-icon="svg-time"
                            :disabled="CUS_DISABLED === true"
                            v-model="CUS_CUSTOMER_CS_TM"
                          >
                          </v-text-field>
                        </template>
                        <div class="d-flex align-center">
                          <v-time-picker
                            v-model="CUS_CUSTOMER_CS_TM"
                            :allowed-minutes="allowedStep"
                            color="#F7AFAE"
                            format="24hr"
                            @click:minute="$refs.CUS_CS_TIME_CAL.save()"
                          ></v-time-picker>
                        </div>
                      </v-menu>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">등급 (VIP , B/L)</span>
                      <div class="form-inp full pa-0 ml-2">
                        <v-select :items="BL_DROPITEMS" item-text="icon" outlined placeholder="등급 (VIP , B/L)" hide-details :readonly="CUS_DISABLED === true" v-model="CUS_ATENT_CD">
                          <template slot="selection" slot-scope="data">
                            <v-icon :class="[data.item.icon]"></v-icon>
                            <div class="v-select__selection v-select__selection--comma">
                              {{ data.item.title }}
                            </div>
                          </template>
                          <template slot="item" slot-scope="data">
                            <v-icon :class="[data.item.icon]"></v-icon>
                            <div class="v-list-item__title" style="flex-basis: auto">
                              {{ data.item.title }}
                            </div>
                          </template>
                        </v-select>
                      </div>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">최근 상담일시</span>
                      <v-text-field class="form-inp free pt-0 ml-2" outlined hide-details readonly v-model="CUS_CNSL_EOT_DATE"></v-text-field> <!-- :readonly="CUS_DISABLED === true"  -->
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">최근 상담내용</span>
                      <v-textarea class="ml-2" no-resize outlined rows="3" hide-details readonly v-model="CUS_CNSL_CNTN"></v-textarea> <!-- :readonly="CUS_DISABLED === true" -->
                    </div>
                    <!-- 고객특이사항 -->
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">고객특이사항</span>
                      <v-textarea class="ml-2" no-resize outlined rows="3" hide-details :readonly="CUS_DISABLED === true" v-model="CUS_CUSTOMER_USER_MEMO"></v-textarea>
                    </div>
                    <!-- 내용 -->
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">내용</span>
                      <v-textarea class="ml-2" no-resize outlined rows="3" hide-details :readonly="CUS_DISABLED === true" v-model="CUS_CUSTOMER_MEMO"></v-textarea>
                    </div>
                  </div>
                  <!-- 전화상담 고객정보 종료 Arthur.Kim -->
                </div>
              </v-tab-item>
              <!-- 고객상담이력 컨탠츠 -->
              <v-tab-item>
                <div class="layout-chatmain--cs-info-ct">
                  <div class="d-flex align-center gray-box">
                    <div class="form-inp free pa-0">
                      <v-select :items="CHANNEL_DROPITEMS" :item-value="CHANNEL_DROPITEMS.value" :item-text="CHANNEL_DROPITEMS.text" outlined placeholder="채널유형" hide-details v-model="CNSL_CHANNEL_TY_SCH"></v-select>
                    </div>
                    <div class="form-inp free pa-0 ml-2">
                      <v-select :items="PROC_DROPITEMS" :item-value="PROC_DROPITEMS.value" :item-text="PROC_DROPITEMS.text" outlined placeholder="상담결과" hide-details v-model="CNSL_PROC_CODE_LCLS_SCH"></v-select>
                    </div>
                    <!-- 조회 버튼 -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn dark small class="btn-main-search pa-0 ml-2" min-width="32px" height="32px" plain
                          @click="csDetailSearch = true" v-bind="attrs" v-on="on"> <!-- :disabled="CUS_DISABLED === true" -->
                          <v-icon small class="svg-main-search2"></v-icon>
                        </v-btn>
                      </template>
                      <span>상세조회</span>
                    </v-tooltip>
                    <!-- 새로고침 버튼 -->
                    <v-btn dark small class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px" plain v-on:click="getPhnCnsl('', '', '', 'call')"> <!-- :disabled="CUS_DISABLED === true" -->
                    <v-icon small class="svg-main-refresh"></v-icon>
                    </v-btn>
                  </div>
                  <!-- 상세조회 슬라이드-->
                  <div class="csDetailSearch" :class="{ active: csDetailSearch == true }">
                    <h2 class="tit-h2 d-flex">
                      <div class="ml-auto align-self-center">
                        <v-icon x-small class="svg-close20" @click="csDetailSearch = false"></v-icon>
                      </div>
                    </h2>
                    <div>
                      <div>
                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" content-class="calendar-modal" color="#FFF">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateRangeText" v-bind="attrs" v-on="on" readonly outlined hide-details class="form-inp full icon-calendar" append-icon="svg-calendar"></v-text-field>
                          </template>
                          <div class="datepicker-range">
                            <v-date-picker v-model="CNSL_EOT_DATE_SCH[0]" no-title dark color="#F7AFAE" @change="onchgCnslEotDateStDt" :first-day-of-week="1" locale="ko-KR" :day-format="mixin_getDate"></v-date-picker>
                            <v-date-picker v-model="CNSL_EOT_DATE_SCH[1]" dark color="#F7AFAE" no-title show-current="false" :min="CNSL_EOT_DATE_SCH[0]" @change="onchgCnslEotDateEdDt" :first-day-of-week="1" locale="ko-KR" :day-format="mixin_getDate"></v-date-picker>
                          </div>
                          <div class="text-center pt-3 pb-3">
                            <v-btn outlined class="btn-default" @click="menu2 = false">확인</v-btn>
                          </div>
                        </v-menu>
                      </div>
                      <div class="d-flex align-center mt-2">
                        <div class="form-inp full pa-0">
                          <v-select :items="CHANNEL_DROPITEMS" :item-value="CHANNEL_DROPITEMS.value"
                            :item-text="CHANNEL_DROPITEMS.text" outlined hide-details label="채널유형"
                            v-model="CNSL_CHANNEL_TY_DTLSCH"></v-select>
                        </div>
                        <div class="form-inp full pa-0 ml-2">
                          <v-text-field class="form-inp full pa-0" name="name" placeholder="상담사" outlined
                            hide-details="" v-model="CNSL_REG_MAN_DTLSCH"></v-text-field>
                        </div>
                      </div>
                      <div class="d-flex align-center mt-2">
                        <div class="form-inp full pa-0">
                          <v-select :items="PROC_DROPITEMS" :item-value="PROC_DROPITEMS.value"
                            :item-text="PROC_DROPITEMS.text" outlined hide-details label="상담결과"
                            v-model="CNSL_PROC_CODE_LCLS_DTLSCH"></v-select>
                        </div>
                        <div class="form-inp full pa-0 ml-2">
                          <v-select :items="CNSL_CALL_TY_RADIOGRP" :item-value="CNSL_CALL_TY_RADIOGRP.value"
                            :item-text="CNSL_CALL_TY_RADIOGRP.text" outlined hide-details label="유형"
                            v-model="CNSL_CALL_TY_DTLSCH"></v-select>
                        </div>
                      </div>
                      <div class="mt-2 text-right">
                        <v-btn outlined small class="btn-etc2 ml-2" v-on:click="getPhnCnsl('','','detail', 'call')">조회</v-btn>
                      </div>
                    </div>
                  </div>
                  <!-- //상세조회 -->
                  <!-- 상담이력 목록 -->
                  <div class="layout-chatmain--cs-info-history-wrap">
                    <div
                      class="layout-chatmain--cs-info-history"
                      :class="{ active: item.EXPAND }"
                      v-for="item in CALL_PHN_CNSL_HISTORY"
                      :key="item.ID"
                    >
                      <div
                        class="layout-chatmain--cs-info-title"
                        @click="btnTogglePhnCnsl(item,'call')"
                      >
                        <v-icon :class="item.ICON"></v-icon>
                        <span
                          class="layout-chatmain--cs-info-history-result"
                          :class="[item.PROC_CODE]"
                          >{{ item.PROC_CODE_NM }}</span
                        >
                        <span class="layout-chatmain--cs-info-history-date">{{
                          item.REG_DTTM
                        }}</span>
                        <span
                          class="layout-chatmain--cs-info-history-manager ml-1"
                          >{{ item.USER_NM }}</span
                        >
                        <span
                          v-if="CUS_ASP_CUST_KEY != 'FUNU' && item.HST_TYPE =='inbound'"
                          class="layout-chatmain--cs-info-history-category">
                          {{ item.CNSL_CHN_1_NM }} &gt;
                          {{ item.CNSL_CONT_NM }} &gt;
                          {{ item.VIS_PATH_NM }}
                        </span>
                        <span
                          v-if="CUS_ASP_CUST_KEY != 'FUNU' && item.HST_TYPE =='outbound'"
                          class="layout-chatmain--cs-info-history-category">
                          {{ item.CATGEGORY_NM }} &gt;
                          {{ item.CONDITION_NM }}
                        </span>
                        <span
                          v-if="CUS_ASP_CUST_KEY == 'FUNU'"
                          class="layout-chatmain--cs-info-history-category">
                          {{ item.CNSL_TYP }} &gt;
                          {{ item.CNSL_TYP2 }} &gt;
                          {{ item.CNSL_TYP3 }}
                        </span>
                        <span
                          v-if="item.HST_TYPE =='kakao' || item.HST_TYPE =='ttalk' || item.HST_TYPE =='line' || item.HST_TYPE =='wechat'"
                          class="layout-chatmain--cs-info-history-category">
                          {{ item.CNSL_TYP }} &gt;
                          {{ item.CNSL_TYP2 }}
                        </span>
                      </div>
                      <!-- 펼쳤을때 컨텐츠 -->
                      <div class="layout-chatmain--cs-info-detail">
                        <div class="layout-chatmain--cs-info-result">
                          처리결과 : {{ item.PROC_CODE_NM }}
                        </div>
                        <div class="d-flex align-center mt-2">
                          <v-textarea
                            class=""
                            no-resize
                            outlined
                            rows="3"
                            background-color="white"
                            hide-details
                            :value="decode(item.HST_CONTEXT)"
                          ></v-textarea>
                        </div>
                        <div v-if="item.HST_TYPE =='outbound' || item.HST_TYPE =='inbound'" class="d-flex align-center mt-2">
                          <audio
                            controls=""
                            name="media"
                            :src="'https://meta.hkipcc.co.kr/hdd2/rec/' + item.CALL_REC + '.mp3'"
                            type="audio/mpeg"
                          >
                          </audio>
                        </div>
                        <!-- 채팅창 불러오기 내역 -->
                        <div class="layout-chatmain--chat-history-wrap type02">
                          <div class="layout-chatmain--chat-history layout-chatmain-box" >
                            <div
                              class="layout-chatmain--chat-history-box"
                              v-scroll.self="onScroll"
                              :class="{fz10: chatHistoryTextsize == '100', fz12: chatHistoryTextsize == '120', fz14: chatHistoryTextsize == '140',}">
                              <template
                                v-for="(item, index) in CALL_PHN_CNSL_DTL[item.ID - 1]">
                                <div
                                  :class=" item.SNDRCV_CD == 'RCV' ? 'layout-chatmain--chat-history--cs-msg' : 'layout-chatmain--chat-history--manager-msg'" :key="index">
                                  <img v-if="(item.TYPE == 'IM' || item.TYPE == 'photo') && item.SNDRCV_CD == 'RCV'" :src="item.IMAGE_URL" />
                                  <img v-if="(item.TYPE == 'IM' || item.TYPE == 'photo') && item.SNDRCV_CD == 'SND'" :src="'/upload'+item.IMAGE_URL" />
                                  <div v-if="item.TYPE != 'IM' && item.TYPE != 'photo'">
                                    {{ item.CONTENT }}
                                  </div>
                                  <div
                                    class="layout-chatmain--chat-history--time"
                                  >
                                    {{ item.CHAT_DATE }}
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                        </div>
                        <!--// 채팅창 불러오기 내역 -->
                      </div>
                    </div>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs>
          </div>
          <!--// 고객정보, 고객상담이력-->
        </div>
      </div>
      <!--// 콜상담 -->
      <!-- //mark:상담현황 -->
      <div class="layout-chatmain--cs layout-chatmain-box">
        <div class="layout-chatmain--cs-title">
          <h2 class="tit-h2">상담현황</h2>
        </div>
        <div class="layout-chatmain--cs-ct">
          <!-- 상담대기 -->
          <div class="layout-chatmain--cs-ct--tit type01">
            상담대기
            <span class="clr-point ml-1">{{ chatWaitList.length }}</span>
            <div class="ml-auto">
              <div class="form-inp pt-0" style="width: 95px">
                <v-select
                  :items="
                    mixin_common_code_get(
                      this.commonCodeLst,
                      'TALK030_NOCACHE',
                      '채팅OFF'
                    )
                  "
                  v-model="dropItemsUserStatCd"
                  outlined
                  placeholder="선택하세요"
                  hide-details
                  v-on:change="changeUserStat(true)"
                  :disabled="chatYN"
                ></v-select>
              </div>
            </div>
          </div>
          <div class="layout-chatmain--cs-ct--box">
            <div
              class="layout-chatmain--cs-ct--talk"
              v-for="(item, index) in chatWaitList"
              :key="index"
              v-on:click="clickChatWait(index)"
            >
              <v-icon :class="'svg-chatmain-' + item.CHN_ICON"></v-icon>
              <div class="layout-chatmain--cs-ct--stat ml-2">
                {{ item.ASP_SENDER_KEY_NM }}
              </div>
              <!--
              <div class="layout-chatmain--cs-ct--ci">
                <img :src="item.ci" :alt="item.alt"></div>
              -->
              <div class="ml-auto my-1 d-flex align-center flex-column">
                <div class="layout-chatmain--cs-ct--time">
                  {{ item.TALK_DIST_DT }}분
                </div>
                <div class="layout-chatmain--cs-ct--cnt mt-1">
                  {{ item.CNT }}
                </div>
              </div>
            </div>
          </div>
          <!-- 상담목록 -->
          <div class="layout-chatmain--cs-ct--tit type02">
            <span
              class="layout-chatmain--cs-ct--tit-in"
              :class="{ active: csStatList === true }"
              @click="
                csStatList = !csStatList;
                csStatChat = false;
              "
              >상담목록</span
            >
            <span
              class="layout-chatmain--cs-ct--tit-in ml-2"
              :class="{ active: csStatChat === true }"
              @click="
                FnChatListCallback;
                csStatChat = !csStatChat;
                csStatList = false;
              "
              >챗콜백
              <span class="clr-point ml-1">{{ chatListCallback.length }}</span>
            </span>
            <div class="ml-auto">
              <!-- 새로고침 버튼 -->
              <v-btn
                dark
                small
                class="btn-main-refresh pa-0 ml-2"
                min-width="32px"
                height="32px"
                plain
                v-on:click="getTalkList"
              >
                <v-icon small class="svg-main-refresh"></v-icon>
              </v-btn>
              <!-- 필터 버튼 -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    small
                    class="btn-main-refresh pa-0 ml-2"
                    min-width="32px"
                    height="32px"
                    plain
                    v-bind="attrs"
                    v-on="on"
                    @click="chatFilter = !chatFilter"
                  >
                    <v-icon small class="svg-main-filter"></v-icon>
                  </v-btn>
                </template>
                <span>필터</span>
              </v-tooltip>
            </div>
          </div>
          <!-- 필터 show-->
          <div class="layout-chatmain--cs-filter" v-show="chatFilter === true">
            <v-menu
              v-model="menu3"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
              dark
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  hide-details
                  class="form-inp sm icon-calendar pt-0"
                  append-icon="svg-calendar"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                color="#F7AFAE"
                no-title
                @input="menu3 = false"
                :first-day-of-week="1"
                locale="ko-KR"
                :day-format="mixin_getDate"
              ></v-date-picker>
            </v-menu>
            <div class="form-inp sm pt-0 ml-2" style="width: 92px">
              <v-select
                :items="
                  mixin_common_code_get(this.commonCodeLst, 'TALK006', '')
                "
                outlined
                placeholder="채널"
                hide-details
                v-model="TALK_NEWSTAT"
              ></v-select>
            </div>
          </div>
          <!--// 필터 show-->
          <div class="layout-chatmain--cs-ct--btn">
            <v-btn-toggle v-model="toggle_csCtBtn">
              <v-btn class="cs-ct-btn" @click="FnChatListAll"
                >전체
                <span class="cs-cti-btn--cnt">{{ chatList.length }}</span>
              </v-btn>
              <v-btn class="cs-ct-btn" @click="FnChatListIng"
                >상담중
                <span class="cs-cti-btn--cnt">{{ chatListIng.length }}</span>
              </v-btn>
              <v-btn class="cs-ct-btn" @click="FnChatListDone"
                >완료
                <span class="cs-cti-btn--cnt">{{ chatListDone.length }}</span>
              </v-btn>
              <v-btn class="cs-ct-btn" @click="FnChatListAfter"
                >후처리
                <span class="cs-cti-btn--cnt">{{ chatListAfter.length }}</span>
              </v-btn>
              <v-btn class="cs-ct-btn" @click="FnChatListBookmark"
                >책갈피
                <span class="cs-cti-btn--cnt">{{
                  chatListBookmark.length
                }}</span>
              </v-btn>
            </v-btn-toggle>
          </div>
          <div
            class="layout-chatmain--cs-ct--box type02"
            :class="[chatListFilter, chatFilter === true ? 'type03' : '']"
          >
            <div
              class="layout-chatmain--cs-ct--talk"
              :class="`type-${item.type} ${
                item.countdown === true ? 'countdown' : ''
              }`"
              v-for="(item, index) in chatList"
              :key="index"
              v-show="
                chatListFilter === 'all' ||
                chatListFilter === item.type ||
                chatListFilter === item.bookmark ||
                chatListFilter === item.callback
              "
              v-on:click="clickChat(index)"
            >
              <v-icon :class="'svg-chatmain-' + item.CHN_ICON"></v-icon>
              <div class="layout-chatmain--cs-ct--stat ml-2">
                {{ item.ASP_SENDER_KEY_NM }}
              </div>
              <div class="ml-auto d-flex align-center flex-column">
                <div
                  class="layout-chatmain--cs-ct--stat"
                  :class="{ statChat: item.statChat === true }"
                >
                  {{ item.TALK_STAT_NM }}
                </div>
                <div class="layout-chatmain--cs-ct--time">
                  {{ item.TALK_DIST_DT }}
                </div>
              </div>
              <div class="d-flex">
                <div class="layout-chatmain--cs-ct--text">
                  {{ item.TALK_TITLE }}
                </div>
                <div class="layout-chatmain--cs-ct--cnt mt-1">
                  {{ item.CNT }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--// 상담현황 -->
      <!--//mark: 챗상담 메인-->
      <div class="layout-chatmain--talk">
        <!-- top -->
        <div class="layout-chatmain--call-top layout-chatmain-box">
          <div class="layout-chatmain--call-top--info type02">
            <v-icon
              :class="chatTop.channel === true ? 'svg-main-chat-channel' : ''"
            ></v-icon>
            <div class="layout-chatmain--call-top--ci">
              <img :src="chatTop.img" :alt="chatTop.imgAlt" />
            </div>
            <div class="layout-chatmain--call-top--cs">
              <span class="layout-chatmain--call-top--name">{{ USER_NM }}</span>
              <span class="layout-chatmain--call-top--phone">{{
                USER_TEL_NO
              }}</span>
            </div>
          </div>
          <div class="d-flex ml-auto align-center">
            <v-btn
              outlined
              small
              class="btn-etc3 ml-2"
              @click="showAlertBookmark"
            >
              <v-icon small class="mr-2">mdi-bookmark-outline</v-icon>
              책갈피
            </v-btn>
            <!-- 새로고침 버튼 -->
            <v-btn
              dark
              small
              class="btn-main-refresh pa-0 ml-2"
              min-width="32px"
              height="32px"
              plain
            >
              <v-icon small class="svg-main-refresh"></v-icon>
            </v-btn>
            <!-- 수정/저장 버튼 -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  small
                  class="btn-main-refresh pa-0 ml-2"
                  min-width="32px"
                  height="32px"
                  plain
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small class="svg-main-down"></v-icon>
                </v-btn>
              </template>
              <span>수정/저장</span>
            </v-tooltip>
          </div>
        </div>
        <!--// top -->
        <div class="layout-chatmain--chat-wrap">
          <!--//mark: 채팅창 -->
          <div class="layout-chatmain--chat-history-wrap">
            <div class="layout-chatmain--chat-history layout-chatmain-box">
              <div
                class="layout-chatmain--chat-history-box"
                v-scroll.self="onScroll"
                :class="{
                  fz10: chatHistoryTextsize == '100',
                  fz12: chatHistoryTextsize == '120',
                  fz14: chatHistoryTextsize == '140',
                }"
              >
                <div class="text-center mt-5 pr-0">
                  <v-btn outlined x-small class="btn-round">이전대화내역</v-btn>
                </div>
                <template v-for="(item, index) in talkHistory">
                  <div
                    :class="
                      item.SNDRCV_CD == 'RCV'
                        ? 'layout-chatmain--chat-history--cs-msg'
                        : 'layout-chatmain--chat-history--manager-msg'
                    "
                    :key="index"
                  >
                    <img v-if="(item.TYPE == 'IM' || item.TYPE == 'photo') && item.SNDRCV_CD == 'RCV'" :src="item.IMAGE_URL" />
                    <img v-if="(item.TYPE == 'IM' || item.TYPE == 'photo') && item.SNDRCV_CD == 'SND'" :src="'/upload'+item.IMAGE_URL" />
                    <div v-if="item.TYPE != 'IM' && item.TYPE != 'photo'">
                      {{ item.CONTENT }}
                    </div>
                    <div
                      class="layout-chatmain--chat-history--time"
                    >
                      {{ item.CHAT_DATE }}
                    </div>
                  </div>
                </template>
                <!-- 맨위로 -->
                <v-btn
                  icon
                  class="btn-to-top"
                  :class="scroll > 0 ? 'active' : null"
                  @click="scrlToTop"
                >
                  <v-icon class="svg-to-top"></v-icon>
                </v-btn>
              </div>
            </div>
            <!-- 버튼 -->
            <div class="layout-chatmain--chat-history-btn">
              <v-btn
                outlined
                class="btn-default"
                @click="chatTemp = !chatTemp"
                :disabled="CANT_MODI"
                >임시저장</v-btn
              >
              <v-btn
                outlined
                class="btn-etc ml-2"
                @click="chatDone = !chatDone"
                :disabled="CANT_END"
              >
                상담종료
              </v-btn>
            </div>
            <!-- 입력 form -->
            <div class="layout-chatmain--chat-history--form">
              <v-textarea
                label=""
                no-resize
                outlined
                placeholder="내용을 입력하세요"
                background-color="white"
                dense
                class="layout-chatmain--chat-history--form-textarea"
                v-model.trim="CHAT_MSG"
                v-on:keyup.enter="sendMsg"
                :disabled="CANT_MODI"
              ></v-textarea>
            </div>
            <!-- 기능 버튼 -->
            <div class="layout-chatmain--chat-history--func">
              <div>
                <!-- 이미지 -->
                <v-btn
                  dark
                  small
                  class="btn-main-chatfunc pa-0"
                  min-width="32px"
                  height="32px"
                  plain
                  v-on:click="showDialogImg('M110102P01')"
                  :disabled="CANT_MODI"
                >
                  <v-icon small class="svg-main-chatfunc-img"></v-icon>
                </v-btn>
                <!-- 시스템메시지중단 -->
                <v-btn
                  dark
                  small
                  class="btn-main-chatfunc pa-0 ml-2"
                  min-width="32px"
                  height="32px"
                  plain
                  v-on:click="SystemMsgSkip"
                  :disabled="CANT_MODI"
                >
                  <v-icon small class="svg-main-chatfunc-stop"></v-icon>
                </v-btn>
                <!-- 종료링크전송 -->
                <v-btn
                  dark
                  small
                  class="btn-main-chatfunc pa-0 ml-2"
                  min-width="32px"
                  height="32px"
                  plain
                  v-on:click="sendEndLink"
                  :disabled="CANT_MODI"
                >
                  <v-icon small class="svg-main-chatfunc-send"></v-icon>
                </v-btn>
                <!-- 챗 이관 -->
                <v-btn
                  dark
                  small
                  class="btn-main-chatfunc pa-0 ml-2"
                  min-width="32px"
                  height="32px"
                  plain
                  @click="[getUserSelect(), (chatTrans = !chatTrans)]"
                  :disabled="CANT_MODI"
                >
                  <v-icon small class="svg-main-chatfunc-trans"></v-icon>
                </v-btn>
                <!-- 챗 콜백 -->
                <v-btn
                  dark
                  small
                  class="btn-main-chatfunc pa-0 ml-2"
                  min-width="32px"
                  height="32px"
                  plain
                  @click="chatCallback = !chatCallback"
                  :disabled="CANT_MODI"
                >
                  <v-icon small class="svg-main-chatfunc-reserve"></v-icon>
                </v-btn>
                <!-- 글자크기 -->
                <v-btn
                  dark
                  small
                  class="btn-main-chatfunc pa-0 ml-2"
                  min-width="32px"
                  height="32px"
                  plain
                  @click="chatFontsize = !chatFontsize"
                  :disabled="CANT_MODI"
                >
                  <v-icon small class="svg-main-chatfunc-size"></v-icon>
                </v-btn>
                <!-- 모달 : 글자크기  -->
                <div
                  class="layout-chatmain--chat-history--func-size"
                  :class="{ active: chatFontsize == true }"
                >
                  <div class="form-inp pa-0 full">
                    <v-select
                      :items="dropItemTextsize"
                      item-text="title"
                      item-value="size"
                      width="120px"
                      outlined
                      placeholder="글자크기선택"
                      @input="FnChatHistoryTextsize"
                      hide-details
                    >
                      <template slot="selection" slot-scope="data">
                        <div
                          class="v-select__selection v-select__selection--comma"
                        >
                          {{ data.item.title }}
                        </div>
                      </template>
                      <template slot="item" slot-scope="data">
                        <div
                          class="v-list-item__title"
                          style="flex-basis: auto"
                        >
                          {{ data.item.title }}
                        </div>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
              <!-- 전송 -->
              <v-btn
                outlined
                class="btn-point ml-auto"
                v-on:click="sendMsg"
                :disabled="CANT_MODI"
              >
                전송
              </v-btn>
            </div>
            <!-- //mark: 채팅 임시저장 바텀시트 -->
            <div
              class="layout-chatmain--chatDone"
              :class="{ active: chatTemp == true }"
            >
              <h2 class="tit-h2 mt-3 type02">임시저장</h2>
              <div class="mt-3">
                <div class="d-flex justify-space-between align-center">
                  <div class="form-inp full pa-0">
                    <v-select
                      :items="
                        mixin_common_code_get(this.commonCodeLst, 'TALK005', '')
                      "
                      v-model="CHT_NEWRESULT"
                      outlined
                      hide-details
                    >
                      <template v-slot:label>
                        상담결과
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      outlined
                      placeholder="상담유형1"
                      hide-details
                      :items="CNSL_TY1"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY1"
                      v-on:change="chnCnsl1()"
                    >
                      <template v-slot:label>
                        상담유형1
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select
                      outlined
                      placeholder="상담유형2"
                      hide-details
                      :items="CNSL_TY2"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY2"
                      v-on:change="chnCnsl2()"
                    >
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      outlined
                      placeholder="상담유형3"
                      hide-details
                      :items="CNSL_TY3"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY3"
                      v-on:change="chnCnsl3()"
                    >
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select
                      outlined
                      placeholder="상담유형4"
                      hide-details
                      :items="CNSL_TY4"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY4"
                    >
                    </v-select>
                  </div>
                </div>
                <div class="mt-2">
                  <v-text-field
                    class="form-inp full pt-0"
                    name="name"
                    label="고객명"
                    id="id"
                    outlined
                    v-model="CHT_TIT"
                    hide-details
                  >
                    <template v-slot:label>
                      상담제목
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-text-field>
                  <v-textarea
                    class="mt-2"
                    label=""
                    no-resize
                    outlined
                    rows="5"
                    hide-details
                    placeholder="상담내용"
                    v-model="CHT_CON"
                  >
                    <template v-slot:label>
                      상담내용
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-textarea>
                </div>
                <div class="text-right mt-2">
                  <v-btn
                    outlined
                    class="btn-default"
                    v-on:click="[(chatTemp = false), initCnsl()]"
                  >
                    취소
                  </v-btn>
                  <v-btn
                    outlined
                    class="btn-point ml-2"
                    v-on:click="confirmSave('', '상담을 임시저장하시겠습니까?')"
                  >
                    저장
                  </v-btn>
                </div>
              </div>
            </div>
            <!--// 채팅 임시저장 바텀시트 -->
            <!-- //mark: 채팅 상담완료 바텀시트 -->
            <div
              class="layout-chatmain--chatDone"
              :class="{ active: chatDone == true }"
            >
              <h2 class="tit-h2 mt-3 type02">상담완료</h2>
              <div class="mt-3">
                <div class="d-flex justify-space-between align-center">
                  <div class="form-inp full pa-0">
                    <v-select
                      :items="
                        mixin_common_code_get(this.commonCodeLst, 'TALK005', '')
                      "
                      v-model="CHT_NEWRESULT"
                      outlined
                      hide-details
                    >
                      <template v-slot:label>
                        상담결과
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      outlined
                      placeholder="상담유형1"
                      hide-details
                      :items="CNSL_TY1"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY1"
                      v-on:change="chnCnsl1()"
                    >
                      <template v-slot:label>
                        상담유형1
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select
                      outlined
                      placeholder="상담유형2"
                      hide-details
                      :items="CNSL_TY2"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY2"
                      v-on:change="chnCnsl2()"
                    >
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      outlined
                      placeholder="상담유형3"
                      hide-details
                      :items="CNSL_TY3"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY3"
                      v-on:change="chnCnsl3()"
                    >
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select
                      outlined
                      placeholder="상담유형4"
                      hide-details
                      :items="CNSL_TY4"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY4"
                    >
                    </v-select>
                  </div>
                </div>
                <div class="mt-2">
                  <v-text-field
                    class="form-inp full"
                    name="name"
                    label="고객명"
                    id="id"
                    outlined
                    v-model="CHT_TIT"
                    hide-details
                  >
                    <template v-slot:label>
                      상담제목
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-text-field>
                  <v-textarea
                    label=""
                    no-resize
                    outlined
                    rows="5"
                    hide-details
                    placeholder="상담내용"
                    v-model="CHT_CON"
                  >
                    <template v-slot:label>
                      상담내용
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-textarea>
                </div>
                <div class="text-right mt-2">
                  <v-btn
                    outlined
                    class="btn-default"
                    v-on:click="[(chatDone = false), initCnsl()]"
                  >
                    취소
                  </v-btn>
                  <v-btn
                    outlined
                    class="btn-point ml-2"
                    v-on:click="
                      confirmSave('3', '상담이력을 저장하시겠습니까?')
                    "
                  >
                    저장
                  </v-btn>
                </div>
              </div>
            </div>
            <!--// 채팅 상담완료 바텀시트 -->

            <!-- //mark: 채팅 전달 바텀시트 -->
            <div
              class="layout-chatmain--chatCallback"
              :class="{ active: chatTrans == true }"
            >
              <h2 class="tit-h2 mt-3 type02">전달</h2>
              <div class="mt-3">
                <div class="d-flex align-center">
                  <div class="form-inp full pt-0">
                    <v-select
                      v-model="USER_NEWID"
                      :items="USER_ID"
                      no-data-text="일치하는 데이터가 없습니다."
                      item-text="USER_ID"
                      item-value="USER_ID"
                    >
                      <template v-slot:label>
                        상담사선택
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      outlined
                      placeholder="상담유형1"
                      hide-details
                      :items="CNSL_TY1"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY1"
                      v-on:change="chnCnsl1()"
                    >
                      <template v-slot:label>
                        상담유형1
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select
                      outlined
                      placeholder="상담유형2"
                      hide-details
                      :items="CNSL_TY2"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY2"
                      v-on:change="chnCnsl2()"
                    >
                      <template v-slot:label>
                        상담유형2
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      outlined
                      placeholder="상담유형3"
                      hide-details
                      :items="CNSL_TY3"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY3"
                      v-on:change="chnCnsl3()"
                    >
                      <template v-slot:label>
                        상담유형3
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select
                      outlined
                      placeholder="상담유형4"
                      hide-details
                      :items="CNSL_TY4"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY4"
                    >
                      <template v-slot:label>
                        상담유형4
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="mt-2">
                  <v-text-field
                    class="form-inp full"
                    name="name"
                    label="고객명"
                    id="id"
                    outlined
                    v-model="CHT_TIT"
                    hide-details
                  >
                    <template v-slot:label>
                      상담제목
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-text-field>
                  <v-textarea
                    label=""
                    no-resize
                    outlined
                    rows="5"
                    hide-details
                    placeholder="상담내용"
                    v-model="CHT_CON"
                  >
                    <template v-slot:label>
                      상담내용
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-textarea>
                </div>
                <div class="text-right mt-2">
                  <v-btn
                    outlined
                    class="btn-default"
                    @click="[(chatTrans = false), initCnsl()]"
                  >
                    취소
                  </v-btn>
                  <v-btn
                    outlined
                    class="btn-point ml-2"
                    v-on:click="confirmTrans"
                  >
                    전달
                  </v-btn>
                </div>
              </div>
            </div>
            <!-- //mark: 채팅 전달 바텀시트 -->

            <!-- //mark: 채팅 콜백 바텀시트 -->
            <div
              class="layout-chatmain--chatCallback"
              :class="{ active: chatCallback == true }"
            >
              <h2 class="tit-h2 mt-3 type02">콜백</h2>
              <div class="mt-3">
                <div class="d-flex align-center">
                  <div class="form-inp full pt-0">
                    <v-select
                      :items="
                        mixin_common_code_get(this.commonCodeLst, 'TALK005', '')
                      "
                      v-model="CHT_BACKRESULT"
                      outlined
                      hide-details
                      placeholder="선택하세요"
                      :readonly="true"
                    >
                      <template v-slot:label>
                        상담결과
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center">
                  <v-menu
                    v-model="chatCalendar2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    color="#FFF"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedDateFormatted2"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp icon-calendar pt-0"
                        append-icon="svg-calendar"
                      ></v-text-field>
                    </template>
                    <div class="d-flex align-center">
                      <v-date-picker
                        v-model="date"
                        dark
                        color="#F7AFAE"
                        no-title
                        show-current="false"
                        :first-day-of-week="1"
                        locale="ko-KR"
                        :day-format="mixin_getDate"
                      ></v-date-picker>
                      <v-time-picker
                        v-model="timeStep"
                        :allowed-minutes="allowedStep"
                        color="#F7AFAE"
                        format="24hr"
                      >
                      </v-time-picker>
                    </div>
                    <div class="text-center pt-3 pb-3">
                      <v-btn
                        outlined
                        small
                        class="btn-point ml-3"
                        @click="chatCalendar2 = false"
                        >확인</v-btn
                      >
                    </div>
                  </v-menu>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      outlined
                      placeholder="상담유형1"
                      hide-details
                      :items="CNSL_TY1"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY1"
                      v-on:change="chnCnsl1()"
                    >
                      <template v-slot:label>
                        상담유형1
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select
                      outlined
                      placeholder="상담유형2"
                      hide-details
                      :items="CNSL_TY2"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY2"
                      v-on:change="chnCnsl2()"
                    >
                      <template v-slot:label>
                        상담유형2
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="d-flex justify-space-between align-center mt-2">
                  <div class="form-inp col-6 pa-0">
                    <v-select
                      outlined
                      placeholder="상담유형3"
                      hide-details
                      :items="CNSL_TY3"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY3"
                      v-on:change="chnCnsl3()"
                    >
                      <template v-slot:label>
                        상담유형3
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                  <div class="form-inp free pa-0 ml-2">
                    <v-select
                      outlined
                      placeholder="상담유형4"
                      hide-details
                      :items="CNSL_TY4"
                      item-value="CD"
                      item-text="CD_NM"
                      v-model="CNSL_NEWTY4"
                    >
                      <template v-slot:label>
                        상담유형4
                        <v-icon color="#EF9191">mdi-circle-small</v-icon>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="mt-2">
                  <v-text-field
                    class="form-inp full"
                    name="name"
                    label="고객명"
                    id="id"
                    outlined
                    v-model="CHT_TIT"
                    hide-details
                  >
                    <template v-slot:label>
                      상담제목
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-text-field>
                  <v-textarea
                    label=""
                    no-resize
                    outlined
                    rows="5"
                    hide-details
                    placeholder="상담내용"
                    v-model="CHT_CON"
                  >
                    <template v-slot:label>
                      상담내용
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                  </v-textarea>
                </div>
                <div class="text-right mt-2">
                  <v-btn
                    outlined
                    class="btn-default"
                    @click="[(chatCallback = false), initCnsl()]"
                  >
                    취소
                  </v-btn>
                  <v-btn
                    outlined
                    class="btn-point ml-2"
                    v-on:click="confirmSave('4', '콜백을 저장하시겠습니까?')"
                  >
                    저장
                  </v-btn>
                </div>
              </div>
            </div>
            <!-- //mark: 채팅 콜백 바텀시트 -->
          </div>
          <!--// 채팅창 -->
          <!-- //mark: 챗 상담정보 -->
          <div class="layout-chatmain--chat-info layout-chatmain-box">
            <v-tabs class="tabs-form" slider-color="#6C6E7A" slider-size="3">
              <v-tab>고객정보</v-tab>
              <v-tab>고객상담이력</v-tab>
              <!-- 고객정보 컨텐츠 -->
              <v-tab-item>
                <div class="layout-chatmain--cs-info-ct">
                  <div class="align-center gray-box">
                    <div class="d-flex">
                      <v-select
                        class="form-inp free pt-0"
                        :items="ASP_CUST_KEY"
                        item-value="CD"
                        item-text="CD_NM"
                        outlined
                        hide-details
                        label="업체명"
                        placeholder="선택하세요"
                        v-model="SRH_ASP_CUST"
                        style="width: 198px"
                        :disabled="CANT_CUST"
                      >
                        <template v-slot:label>
                          업체명
                          <v-icon color="#EF9191">mdi-circle-small</v-icon>
                        </template>
                      </v-select>
                      <v-text-field
                        class="form-inp free ml-2 pt-0"
                        name="name"
                        label="전화번호 "
                        outlined
                        hide-details
                        v-model="SRH_PHONE_NUM"
                      >
                      </v-text-field>
                    </div>
                    <div class="d-flex mt-2">
                      <v-text-field
                        class="form-inp free pt-0 flex-grow-0"
                        name="name"
                        label="고객명 "
                        outlined
                        hide-details
                        style="width: 118px"
                        v-model="SRH_CUST_NM"
                      >
                      </v-text-field>
                      <div class="ml-2 align-self-center mr-0">
                        <!-- 조회 버튼 -->
                        <v-btn
                          dark
                          small
                          class="btn-main-search pa-0"
                          min-width="32px"
                          height="32px"
                          plain
                          @click="[btnCust(CNSL_WK[1]), showDialog('MP01', 'chat')]"
                        >
                          <v-icon small class="svg-main-searh white"></v-icon>
                        </v-btn>
                        <!-- 수정/저장 버튼 -->
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              dark
                              small
                              class="btn-main-refresh pa-0 ml-2"
                              min-width="32px"
                              height="32px"
                              plain
                              v-bind="attrs"
                              v-on="on"
                              v-on:click="showAlertReg"
                              :disabled="CLK_CUST === '' ? true : false"
                            >
                              <v-icon small class="svg-main-down"></v-icon>
                            </v-btn>
                          </template>
                          <span>수정/저장</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3 layout-chatmain--cs-info-ct-form">
                    <div class="d-flex align-stretch">
                      <span class="in-label text-left col-4">업체명 </span>
                      <v-select
                        :items="ASP_CUST_KEY"
                        item-value="CD"
                        item-text="CD_NM"
                        class="form-inp free pt-0 ml-2"
                        name="name"
                        value=""
                        outlined
                        hide-details
                        readonly
                        v-model="CLK_CUST"
                      ></v-select>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4"> 고객명 </span>
                      <v-text-field
                        class="form-inp free pt-0 ml-2"
                        name="name"
                        value=""
                        outlined
                        hide-details
                        :readonly="chatmainChatEdit === true"
                        v-model="USER_NM"
                      ></v-text-field>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">
                        <v-badge dot color="#EF9191">Key 값</v-badge>
                      </span>
                      <v-text-field
                        class="form-inp free pt-0 ml-2"
                        name="name"
                        value="N/A"
                        outlined
                        hide-details
                        v-model="CLK_USER"
                        readonly
                      ></v-text-field>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4"> 전화번호1 </span>
                      <v-text-field
                        v-model="USER_TEL_NO"
                        class="form-inp free pt-0 ml-2"
                        name="name"
                        value=""
                        outlined
                        hide-details
                        :readonly="chatmainChatEdit === true"
                      >
                      </v-text-field>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">전화번호2 </span>
                      <v-text-field
                        v-model="USER_TEL_NO2"
                        class="form-inp free pt-0 ml-2"
                        name="name"
                        outlined
                        hide-details
                        :readonly="chatmainChatEdit === true"
                      ></v-text-field>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">이메일</span>
                      <v-text-field
                        class="form-inp free pt-0 ml-2"
                        name="name"
                        value="palette@hkcloud.co.kr "
                        outlined
                        hide-details
                        v-model="EMAIL"
                        :readonly="chatmainChatEdit === true"
                      ></v-text-field>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">성별</span>
                      <v-select
                        :items="CUS_GENDER_DROPITEMS"
                        :item-value="CUS_GENDER_DROPITEMS.value"
                        :item-text="CUS_GENDER_DROPITEMS.text"
                        outlined
                        placeholder="성별"
                        hide-details
                        class="form-inp free pt-0 ml-2"
                        name="name"
                        v-model="CLK_CUSTOMER_GENDER"
                        :readonly="chatmainChatEdit === true"
                      ></v-select>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">연령</span>
                      <v-text-field
                        class="form-inp free pt-0 ml-2"
                        name="name"
                        value="palette@hkcloud.co.kr "
                        outlined
                        hide-details
                        v-model="CLK_CUSTOMER_AGE"
                        :readonly="chatmainChatEdit === true"
                      ></v-text-field>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <!--
                      <span class="in-label text-left col-4">주소</span>
                      <div class="d-flex flex-wrap">
                        <div class="d-flex col-12 pa-0">
                          <v-text-field
                            class="form-inp full pt-0 ml-2"
                            name="name"
                            outlined
                            hide-details
                            :readonly="chatmainChatEdit === true"
                            placeholder="우편번호"
                            v-model="postcode"
                          ></v-text-field>
                          <div class="ml-2 mr-2">
                            <v-btn
                              dark
                              small
                              class="btn-main-search pa-0"
                              min-width="32px"
                              height="32px"
                              plain
                              v-on:click="execDaumPostcode()"
                              :disabled="CLK_CUST === '' ? true : false"
                            >
                              <v-icon
                                small
                                class="svg-main-searh white"
                              ></v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <v-textarea
                          class="ml-2"
                          no-resize
                          outlined
                          rows="2"
                          hide-details
                          :readonly="chatmainChatEdit === true"
                          v-model="address"
                        ></v-textarea>
                        <v-text-field
                          class="form-inp full pt-0 ml-2"
                          name="name"
                          outlined
                          hide-details
                          :readonly="chatmainChatEdit === true"
                          placeholder="상세주소"
                          v-model="detailAddress"
                        >
                        </v-text-field>
                        <v-text-field
                          class="form-inp full pt-0 ml-2"
                          name="name"
                          outlined
                          hide-details
                          :readonly="chatmainChatEdit === true"
                          placeholder="참고항목"
                          v-model="extraAddress"
                        >
                        </v-text-field>
                        -->
                      <span class="in-label text-left col-4">지역 </span>
                      <div class="d-flex flex-wrap">
                      <v-text-field
                        v-model="SITE"
                        class="form-inp free pt-0 ml-2"
                        name="name"
                        outlined
                        hide-details
                        :readonly="chatmainChatEdit === true"
                      ></v-text-field>
                      </div>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4"
                        >등급 (VIP , B/L)</span
                      >
                      <div class="form-inp full pa-0 ml-2">
                        <v-select
                          :items="BL_DROPITEMS"
                          item-text="icon"
                          item-value="value"
                          outlined
                          placeholder="등급 (VIP , B/L)"
                          hide-details
                          :readonly="chatmainChatEdit === true"
                          v-model="USER_NEWBL"
                        >
                          <template slot="selection" slot-scope="data">
                            <v-icon :class="[data.item.icon]"></v-icon>
                            <div
                              class="v-select__selection v-select__selection--comma"
                            >
                              {{ data.item.title }}
                            </div>
                          </template>
                          <template slot="item" slot-scope="data">
                            <v-icon :class="[data.item.icon]"></v-icon>
                            <div
                              class="v-list-item__title"
                              style="flex-basis: auto"
                            >
                              {{ data.item.title }}
                            </div>
                          </template>
                        </v-select>
                      </div>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4"
                        >최근 상담일시</span
                      >
                      <v-text-field
                        v-model="RCT_CHAT_DATE"
                        class="form-inp free pt-0 ml-2"
                        name="name"
                        value="2022-02-23 14:02"
                        outlined
                        hide-details
                        readonly
                      ></v-text-field>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4"
                        >최근 상담내용</span
                      >
                      <v-textarea
                        v-model="RCT_CHAT_CON"
                        class="ml-2"
                        no-resize
                        outlined
                        rows="3"
                        hide-details
                        value=""
                        readonly
                      ></v-textarea>
                    </div>
                    <div class="d-flex align-stretch mt-2">
                      <span class="in-label text-left col-4">관리메모</span>
                      <v-textarea
                        v-model="USER_MEMO"
                        class="ml-2"
                        no-resize
                        outlined
                        rows="3"
                        hide-details
                        value=""
                        :readonly="chatmainChatEdit === true"
                      ></v-textarea>
                    </div>
                  </div>
                </div>
              </v-tab-item>
              <!-- 고객상담이력 컨탠츠 -->
              <v-tab-item>
                <div class="layout-chatmain--cs-info-ct">
                  <div class="d-flex align-center gray-box">
                    <div class="form-inp free pa-0">
                      <v-select
                        :items="
                          mixin_common_code_get(
                            this.commonCodeLst,
                            'PLT002',
                            ''
                          )
                        "
                        outlined
                        placeholder="채널유형"
                        hide-details
                        v-model="dropItemChatCnslChannel"
                      >
                      </v-select>
                    </div>
                    <div class="form-inp free pa-0 ml-2">
                      <v-select
                        :items="
                          mixin_common_code_get(
                            this.commonCodeLst,
                            'TALK004',
                            ''
                          )
                        "
                        outlined
                        placeholder="상담결과"
                        hide-details
                        v-model="dropItemChatCnslProc"
                      >
                      </v-select>
                    </div>
                    <!-- 조회 버튼 -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          dark
                          small
                          class="btn-main-search pa-0 ml-2"
                          min-width="32px"
                          height="32px"
                          plain
                          @click="csDetailSearch2 = true"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon small class="svg-main-search2"></v-icon>
                        </v-btn>
                      </template>
                      <span>상세조회</span>
                    </v-tooltip>
                    <!-- 새로고침 버튼 -->
                    <v-btn
                      dark
                      small
                      class="btn-main-refresh pa-0 ml-2"
                      min-width="32px"
                      height="32px"
                      plain
                      v-on:click="getPhnCnsl('', '', '', 'chat')"
                    >
                      <v-icon small class="svg-main-refresh"></v-icon>
                    </v-btn>
                  </div>
                  <!-- 상세조회 슬라이드-->
                  <div
                    class="csDetailSearch"
                    :class="{ active: csDetailSearch2 == true }"
                  >
                    <h2 class="tit-h2 d-flex">
                      <div class="ml-auto align-self-center">
                        <v-icon
                          x-small
                          class="svg-close20"
                          @click="csDetailSearch2 = false"
                        ></v-icon>
                      </div>
                    </h2>
                    <div>
                      <div>
                        <v-menu
                          v-model="menu5"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          content-class="calendar-modal"
                          color="#FFF"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="DET_SRC_dateRangeText"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                              outlined
                              hide-details
                              class="form-inp full icon-calendar"
                              append-icon="svg-calendar"
                            >
                            </v-text-field>
                          </template>
                          <div class="datepicker-range">
                            <v-date-picker
                              v-model="DET_SRC_dates[0]"
                              no-title
                              dark
                              color="#F7AFAE"
                              @change="startDate"
                              :first-day-of-week="1"
                              locale="ko-KR"
                              :day-format="mixin_getDate"
                            ></v-date-picker>
                            <v-date-picker
                              v-model="DET_SRC_dates[1]"
                              dark
                              color="#F7AFAE"
                              no-title
                              show-current="false"
                              :min="DET_SRC_dates[0]"
                              @change="endDate"
                              :first-day-of-week="1"
                              locale="ko-KR"
                              :day-format="mixin_getDate"
                            ></v-date-picker>
                          </div>
                          <div class="text-center pt-3 pb-3">
                            <v-btn
                              outlined
                              class="btn-default"
                              @click="menu5 = false"
                              >확인</v-btn
                            >
                          </div>
                        </v-menu>
                      </div>
                      <div class="d-flex align-center mt-2">
                        <div class="form-inp full pa-0">
                          <v-select
                            :items="
                              mixin_common_code_get(
                                this.commonCodeLst,
                                'PLT002',
                                ''
                              )
                            "
                            outlined
                            placeholder="채널유형"
                            hide-details
                            v-model="DET_SRC_CHENNEL"
                          >
                          </v-select>
                        </div>
                        <div class="form-inp full pa-0 ml-2">
                          <v-text-field
                            class="form-inp full pa-0"
                            name="name"
                            placeholder="상담사"
                            outlined
                            hide-details=""
                            v-model="DET_SRC_AGENT_ID"
                          ></v-text-field>
                        </div>
                      </div>
                      <div class="d-flex align-center mt-2">
                        <div class="form-inp full pa-0">
                          <v-select
                            :items="
                              mixin_common_code_get(
                                this.commonCodeLst,
                                'PLT024',
                                ''
                              )
                            "
                            outlined
                            placeholder="상담결과"
                            hide-details
                            v-model="DET_SRC_RESULT"
                          >
                          </v-select>
                        </div>
                        <div class="form-inp full pa-0 ml-2">
                          <v-select
                            outlined
                            item-value="id"
                            item-text="title"
                            hide-details
                            label="유형"
                            v-model="DET_SRC_CHAT_RESULT"></v-select>
                        </div>
                      </div>
                      <div class="mt-2 text-right">
                        <v-btn
                          dark
                          small
                          class="btn-main-refresh pa-0 ml-2"
                          min-width="32px"
                          height="32px"
                          plain
                          v-on:click="initSrcDetail">
                          <v-icon small class="svg-main-refresh"></v-icon>
                        </v-btn>
                        <v-btn
                          outlined
                          small
                          class="btn-etc2 ml-2"
                          v-on:click="getPhnCnsl('', '', 'detail', '')">
                          조회
                        </v-btn>
                      </div>
                    </div>
                  </div>
                  <!-- //상세조회 -->
                  <!-- 상담이력 목록 -->
                  <div class="layout-chatmain--cs-info-history-wrap">
                    <div
                      class="layout-chatmain--cs-info-history"
                      :class="{ active: item.EXPAND }"
                      v-for="item in chatHistory"
                      :key="item.ID"
                    >
                      <div
                        class="layout-chatmain--cs-info-title"
                        @click="btnTogglePhnCnsl(item,'chat')"
                      >
                        <v-icon :class="item.ICON"></v-icon>
                        <span
                          class="layout-chatmain--cs-info-history-result"
                          :class="[item.PROC_CODE]"
                          >{{ item.PROC_CODE_NM }}</span
                        >
                        <span class="layout-chatmain--cs-info-history-date">{{
                          item.REG_DTTM
                        }}</span>
                        <span
                          class="layout-chatmain--cs-info-history-manager ml-1"
                          >{{ item.USER_NM }}</span
                        >
                        <span class="layout-chatmain--cs-info-history-category">
                          {{ item.CNSL_TYP }} &gt;
                          {{ item.CNSL_TYP2 }}
                        </span>
                      </div>
                      <!-- 펼쳤을때 컨텐츠 -->
                      <div class="layout-chatmain--cs-info-detail">
                        <div class="layout-chatmain--cs-info-result">
                          처리결과 : {{ item.PROC_CODE_NM }}
                        </div>
                        <div class="d-flex align-center mt-2">
                          <v-textarea
                            class=""
                            no-resize
                            outlined
                            rows="3"
                            background-color="white"
                            hide-details
                            :value="decode(item.HST_CONTEXT)"
                          ></v-textarea>
                        </div>
                        <div v-if="item.HST_TYPE =='outbound' || item.HST_TYPE =='inbound'" class="d-flex align-center mt-2">
                          <audio
                            controls=""
                            name="media"
                            :src="'https://meta.hkipcc.co.kr/hdd2/rec/' + item.CALL_REC + '.mp3'"
                            type="audio/mpeg"
                          >
                          </audio>
                        </div>
                        <!-- 채팅창 불러오기 내역 -->
                        <div class="layout-chatmain--chat-history-wrap type02">
                          <div class="layout-chatmain--chat-history layout-chatmain-box" >
                            <div
                              class="layout-chatmain--chat-history-box"
                              v-scroll.self="onScroll"
                              :class="{fz10: chatHistoryTextsize == '100', fz12: chatHistoryTextsize == '120', fz14: chatHistoryTextsize == '140',}">
                              <template
                                v-for="(item, index) in talkHistList[item.ID - 1]">
                                <div
                                  :class=" item.SNDRCV_CD == 'RCV' ? 'layout-chatmain--chat-history--cs-msg' : 'layout-chatmain--chat-history--manager-msg'" :key="index">

                                  <img v-if="(item.TYPE == 'IM' || item.TYPE == 'photo') && item.SNDRCV_CD == 'RCV'" :src="item.IMAGE_URL" />
                                  <img v-if="(item.TYPE == 'IM' || item.TYPE == 'photo') && item.SNDRCV_CD == 'SND'" :src="'/upload'+item.IMAGE_URL" />
                                  <div v-if="item.TYPE != 'IM' && item.TYPE != 'photo'">
                                    {{ item.CONTENT }}
                                  </div>
                                  <div
                                    class="layout-chatmain--chat-history--time"
                                  >
                                    {{ item.CHAT_DATE }}
                                  </div>
                                </div>
                              </template>
                            </div>
                            <div class="text-center mt-5">
                              <v-btn outlined x-small class="btn-round"
                                >상담이 종료되었습니다.</v-btn
                              >
                            </div>
                          </div>
                        </div>
                        <!--// 채팅창 불러오기 내역 -->
                      </div>
                    </div>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs>
          </div>
          <!--// 챗 상담정보 -->
        </div>
        <!-- 이미지 전송 모달창 -->
        <div class="">
          <v-dialog
            max-width="655"
            max-height="655"
            v-model="DialogM110102P01"
            content-class="square-modal min-auto"
          >
            <dialog-M110102P01
              headerTitle="이미지 전송"
              @hide="hideDialog('M110102P01')"
              :Imgdata="Imgdata"
              @input="Imgdata = $event"
              @selImg="getTalkInfo(), hideDialog('M110102P01'), scrlToBottom()"
            >
            </dialog-M110102P01>
            <template v-slot:body> </template>
          </v-dialog>
        </div>
        <!-- 이미지 전송 모달창 -->

        <!-- 고객정보 모달창 -->
        <div>
          <v-dialog
            max-width="1400"
            max-height="1200"
            v-model="dialogMP01"
            content-class="square-modal"
          >
            <dialog-MP01
              headerTitle="고객정보"
              @hide="hideDialog('MP01')"
              :cusInfo="cusInfo"
              @input="cusInfo = $event"
              @selectCus="[hideDialog('MP01'), setCustData()]"
            ></dialog-MP01>
          </v-dialog>
        </div>
        <!-- 고객정보 모달창 -->
      </div>
      <!--// 챗상담 메인-->
    </div>
  </div>
</template>

<script>
// 호전환 모달
import DialogM110101P01 from "./M110101P01.vue"; //호전환
import DialogM110101P02 from "./M110101P02.vue"; //고객정보조회
import { mixin } from "@/mixin/mixin.js";
import api from "../../store/apiUtil.js";
import apiChat from "../../store/apiChatParamUtil.js";
import { apiCall } from "@/store/callUtil.js";
import DialogM110102P01 from "./M110102P01.vue";
import moment from "moment";
import Vue from "vue";
import io from "socket.io-client"; //socket.io 모듈 추가_김민진
import { mapGetters } from "vuex";

import alertStore from "@/store/modules/alertStore"; //알림
import { eventBus } from "@/store/eventBus.js"; //Event Bus
import { stompUtil } from "@/store/stomp-util.js";

import DialogMP01 from "./MP01.vue";

export default {
  name: "MENU_M110101", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    DialogM110101P01,
    DialogM110101P02,
    DialogM110102P01,
    DialogMP01,
  },

  created() {

    /****************************************************************************************************************************************************
     * 공통 변수 / 함수 정보 START
     ***************************************************************************************************************************************************/

      /** 현재시간 추출 (1/10000 초)  */
      setInterval(() => {
        this.nowTime = moment();
      }, 10000);

    /****************************************************************************************************************************************************
     * 공통 변수 / 함수 정보 END
     ****************************************************************************************************************************************************
     ***************************************************************************************************************************************************/

    /****************************************************************************************************************************************************
     * EventBus created START
     ***************************************************************************************************************************************************/

      /**
       * 로그인/아웃 CTI 배열 조정
       */
      eventBus.$on("busInitCallCti", (e) => {

        let ctiBtnGbn = "";                                           //  CTI 버튼 배열 조정 값

        let result    = "";
        let desc      = "";
        let data      = "";
        let mobile    = "";                                           // 모바일사용여부

        result = e.result;
        desc   = e.desc;

        switch (desc) {

          case "db_login" :                                           // CTI 로그인

            if (result == "true") {

              mobile = e.mobile;

              /** 모바일 사용여부 */
              if (mobile == "1") { mobile = "Y"; }
              else { mobile = "N"; }

              this.$store.dispatch("userStore/AC_MOBIE_YN", mobile);  // 모바일 사용여부 User Store 저장

              this.intiCall('ALL');                                   // 전체 초기화

              this.citHandleAuto();                                   // CTI 후처리 상태값 표시

              ctiBtnGbn = "on";                                       //  CTI 버튼 배열 조정 값
            } else {
              //setTimeout(this.btnCtiLogin(), 3000);
            }

            break;

          case "db_logout" :                                          // CTI 로그아웃

            if (result == "true") {
              ctiBtnGbn = "off";                                      //  CTI 버튼 배열 조정 값
            } else {
              //setTimeout(this.btnCtiLogin(), 3000);
            }

            break;

          case "db_state" :                                           // IPCC 이벤트 결과 ((1:대기, 2:후처리, 이석_3:휴식, 이석_4:교육, 이석_5:식사, 이석_6:기타))

            if (result == "true") {

              data = e.data;

              /** CTI 대기버튼 */
              if (data == "1") {                                                        // 대기

                this.intiCall("ready");                                                 // 변수 초기화

                this.CTI_BTN[2].text = "대기해제";
                ctiBtnGbn = "ready";                                                    //  CTI 버튼 배열 조정 값

              } else if (data == "2") {                                                 // 후처리

                /** 대기해제 -> 대기 타이틀 변경 */
                if (this.CTI_BTN[2].text == "ready" || this.CTI_BTN[2].text == "대기해제") {
                  this.CTI_BTN[2].text = "대기";
                }

                console.log("#####this.CTI_DB_STATE hangup 1 ################################################################");
                console.log("this.CTI_DB_STATE : " + this.CTI_DB_STATE);

                if (this.CTI_DB_STATE == "hangup") {

                  ctiBtnGbn = "hangup";                                                 //  CTI 버튼 배열 조정 값

                  this.CTI_BRD.current = "후처리(상담)";

                  console.log("#####this.CTI_DB_STATE hangup 2 ################################################################");
                  console.log("this.CTI_INFO.strStartDate : " + this.CTI_INFO.strStartDate);
                  console.log("this.CTI_INFO.strStartTime : " + this.CTI_INFO.strStartTime);
                  console.log("this.CTI_INFO.strEndDate : " + this.CTI_INFO.strEndDate);
                  console.log("this.CTI_INFO.strEndTime : " + this.CTI_INFO.strEndTime);
                  console.log("this.CNSL_CALL_TY : " + this.CNSL_CALL_TY);
                  console.log("this.UEI_INFO.strInboundNum : " + this.UEI_INFO.strInboundNum);
                  console.log("this.CUS_CUSTOMER_NM : " + this.CUS_CUSTOMER_NM);
                  console.log("this.CTI_INFO.strRecKey : " + this.CTI_INFO.strRecKey);
				          console.log("tthis.CTI_OB_CHK : " + this.CTI_OB_CHK);
                } else {
                  ctiBtnGbn = "handle";                                                 //  CTI 버튼 배열 조정 값
                }

              } else if (data == "3" || data == "4" || data == "5" || data == "6") {    // 이석처리_3:휴식, 이석_4:교육, 이석_5:식사, 이석_6:기타
                ctiBtnGbn = "reason";                                                   //  CTI 버튼 배열 조정 값
              }
            }

            break;

          case "phone_acce" :                                                           // 전화받기
            break;

          case "phone_mute" :                                                           // 음소거
          case "phone_hold" :                                                           // 보류, 호전환 취소 (내부->내부/외부 두번째 이벤트)

            if (result == "true") {

              if (this.CTI_INFO.strState == "phone_tran" || this.CTI_INFO.strState == "phone_cftel") {  // 호전환 취소 (내부->내부/외부 두번째 이벤트), 3자 취소 (내부->내부 두번째 이벤트)
              } else {
                this.ctiMuteHold(desc);       // ctiInitCall
              }
            }

            break;

          case "phone_call" :
          case "phone_call2" :

            if (result == "true") {
              this.ctiMuteHold(desc);                                   // ctiInitCall
            }

            break;

          case "phone_tran" :                                           //호전환 시도(내부->내부/외부)
            this.ctiTranCftel(desc);

            break;

          case "phone_trno" :                                           // 호전환 취소 (내부->내부/외부 첫번째 이벤트)
            break;

          case "phone_trok" :                                           // 호전환 완료
            this.ctiTrokCfok(desc);

            break;

          case "phone_conf" :                                           // 3자 시도 첫번째 이벤트
            break;

          case "phone_cftel" :                                          // 3자 시도 두번째 이벤트
            this.ctiTranCftel(desc);

            break;

          case "phone_cfno" :                                           // 3자 취소 (내부->내부 첫번째 이벤트)
            break;

          case "phone_cfok" :                                           // 3자 완료
            this.ctiTrokCfok(desc);

            break;
        }

        /** CTI 버튼 배열 조정 */
        if (result == "true") {

          if (desc == "phone_acce") {                                                             // 전화받기 이벤트
            //
          } else if (desc == "phone_mute" || desc == "phone_hold") {                              // 무음, 보류, //  desc == "phone_trok" || desc == "fanvil_phone_trok"

            if ((desc == "phone_hold" && this.CTI_INFO.strState == "phone_tran")                  // 호전환 취소 : 내부 -> 내부/외부(answer)
                || (desc == "phone_hold" && this.CTI_INFO.strState == "phone_cftel")) {           // 3자 취소 : 내부 -> 내부/외부(answer)
              this.ctiTrnoCfno();
            }

          } else if (desc == "phone_hang") {                                                      // 전화끊기 이벤트
          } else if (desc == "phone_tran") {                                                      // 호전환 시도  else if
          } else if (desc == "phone_conf" || desc == "phone_cftel") {                             // 3자 시도
          } else if (desc == "phone_trno") {                                                      // 호전환 취소
          } else if (desc == "phone_trok") {                                                      // 호전환 완료
          } else if (desc == "phone_cfok") {                                                      // 3자 완료
          } else {

            this.CTI_DB_STATE = (ctiBtnGbn == "on" ? "handle" : ctiBtnGbn);

            /** CTI 버튼 배열 조정 */
            if (result == "true") {
              this.initCallCti(ctiBtnGbn);
            } else {
              alert(e.data);                                                         // 오류메시지
            }
          }

        } else {
          alert(e.data);                                                             // 오류메시지
        }
      });

      /**
       * 끊기 이벤트
       */
      eventBus.$on("busCtiHangup", (e) => {

        /** 호전환 취소 : 내부 -> 내부 (ringing/answer 상담방 끊기, 거절) */
        if ((this.CTI_INFO.strTransSucess == "N" && this.CTI_INFO.strState == "phone_tran")) {

          if (this.CTI_TRANSFER_INFO.inLineNo.length == 4) {
            this.ctiHangup();
          }
        }

        /** 호전환 내부 -> 외부 (고객 종료 시) */
        if (this.CTI_INFO.strTransSucess == "N" && this.CTI_INFO.strState == "phone_tran" && this.CTI_TRANSFER_INFO.inLineNo.length > 8) {
          this.ctiHangup();
        }


        if ((this.CTI_DB_STATE == "ringing" && this.CTI_INFO.strState != "answer")
            || (this.CTI_INFO.strCallMode == "outbound" && this.CTI_INFO.strState != "answer")) {

          /** 전화받기 창 닫기 */
          if (this.CTI_DB_STATE == "ringing" && this.CTI_INFO.strState != "answer") {
            this.hideDialog('M110101P05');                                  // 전화수신 모달
          }

          if (this.CTI_INFO.strCallMode == "outbound") {

            this.CTI_INFO.strStartDate  =  this.getSverData("YYYYMMDD", "");    // 통화종료년월일
            this.CTI_INFO.strStartTime  =  this.getSverData("HH24MISS", "");    // 통화종료시분초

            this.CTI_INFO.strEndDate  =  this.getSverData("YYYYMMDD", "");    // 통화종료년월일
            this.CTI_INFO.strEndTime  =  this.getSverData("HH24MISS", "");    // 통화종료시분초

            this.CTI_DB_STATE       = "hangup";
            this.CTI_INFO.strState  = this.CTI_DB_STATE;

            this.getCmmCode();

          } else {
            this.initCallCtiInof();				                                    // CTI_INFO, UEI_INFO 초기화
            this.initCallCtiCall();				                                    // CIT CALL 정보 (수/발신 정보)
          }

        } else {

          this.CTI_INFO.strEndDate  =  this.getSverData("YYYYMMDD", "");    // 통화종료년월일
          this.CTI_INFO.strEndTime  =  this.getSverData("HH24MISS", "");    // 통화종료시분초

          this.CTI_DB_STATE       = "hangup";
          this.CTI_INFO.strState  = this.CTI_DB_STATE;
        }

        /** 3자 완료 Hangup 이벤트 처리 */
        if (this.CTI_INFO.strConference == "Y") {

          let conferencdCancle = (typeof this.CTI_INFO.strConferenceCancle == undefined || typeof this.CTI_INFO.strConferenceCancle == "undefined") ? "" : this.CTI_INFO.strConferenceCancle;

          if (conferencdCancle == "") {                                     // 3자 완료 고객 종료

            this.CTI_INFO.strConferenceCancle = "Y";
            this.ctiHangup();                                               // 상담사 A <-> 상담사 B 통화 전화기 종료
          }
        }

        /** 모달 일괄 숨기기 */
        this.hideDialog("M110101P03");                                        // 전화걸기 모달
        this.hideDialog("M110101P01");                                        // 호전환 모달

        this.btnClassRemove();
        this.ctiBtnEvent('handle');                                           // 후처리 이벤트

      });

      /**
       * 3자 완료 내부->내부 (상대방 상담사 통화 후 종료)
       * @param {} e
       */
      eventBus.$on("busCtiHangAgent", () => {

        let conferencdCancle = (typeof this.CTI_INFO.strConferenceCancle == undefined || typeof this.CTI_INFO.strConferenceCancle == "undefined") ? "" : this.CTI_INFO.strConferenceCancle;

        console.log("#####busCtiHangAgent########################################");
        console.log("this.CTI_INFO.strState : " + this.CTI_INFO.strState);
        console.log("this.CTI_INFO.strConference : " + this.CTI_INFO.strConference);
        console.log("this.CTI_INFO.strConferenceCancle : " + this.CTI_INFO.strConferenceCancle);
        console.log("conferencdCancle : " + conferencdCancle);
        console.log("this.CTI_INFO.strTransSucess : " + this.CTI_INFO.strTransSucess);

        if (this.CTI_INFO.strConference == "Y" && conferencdCancle == "") {

          this.ctiBtnTrans(false);

          this.CTI_INFO.strTransSucess = "";                                  // 호전환 초기화
          this.CTI_INFO.strConference  = "";                                  // 3자 여부 초기화

          this.CTI_BRD.current         = "통화중";
          this.CTI_BRD.icon            = "cti-btn-answer";

          console.log("#####setCtiTrokCfok#################################################################");
          console.log("this.CTI_INFO.strState : " + this.CTI_INFO.strState);
          console.log("this.CTI_INFO.strPreCtiState : " + this.CTI_INFO.strPreCtiState);
          console.log("this.CTI_INFO.strTransSucess : " + this.CTI_INFO.strTransSucess);

        } else {

          let transConferGbn   = "";
          let transConferState = "";

          transConferGbn   = typeof this.CTI_TRANS_CONFER.gbn == undefined || typeof this.CTI_TRANS_CONFER.gbn == "undefined" ? "" : this.CTI_TRANS_CONFER.gbn;
          transConferState = typeof this.CTI_TRANS_CONFER.state == undefined || typeof this.CTI_TRANS_CONFER.state == "undefined" ? "" : this.CTI_TRANS_CONFER.state;

          if (transConferGbn == "CONFERENCE") {

            if (transConferState == "answer") {

              this.CTI_INFO.strEndDate  =  this.getSverData("YYYYMMDD", "");      // 통화종료년월일
              this.CTI_INFO.strEndTime  =  this.getSverData("HH24MISS", "");      // 통화종료시분초

              this.CTI_DB_STATE       = "hangup";
              this.CTI_INFO.strState  = this.CTI_DB_STATE;

              this.btnClassRemove();
              this.initCallCti(this.CTI_DB_STATE);

              this.CTI_BRD.current = "후처리(상담)";
              this.CTI_BRD.icon    = this.CTI_BTN[6].icon;                          //"cti-btn-answer";
              this.CTI_BTN[6].cls = true;

            } else if (transConferState != "answer") {

              this.hideDialog('M110101P05');                                      // 전화수신 모달

              this.initCallCtiInof();				                                      // CTI_INFO, UEI_INFO 초기화
              this.initCallCtiCall();				                                      // CIT CALL 정보 (수/발신 정보)

              this.btnClassRemove();
              this.ctiBtnEvent('handle');                                         // 후처리 이벤트
            }

          } else  if (transConferGbn == "TRANSFER") {                             // 호전환

            if (transConferState == "answer") {

              this.CTI_DB_STATE       = "hangup";
              this.CTI_INFO.strState  = this.CTI_DB_STATE;

            } else if (transConferState != "answer") {

              this.hideDialog('M110101P05');                                      // 전화수신 모달

              this.initCallCtiInof();				                                      // CTI_INFO, UEI_INFO 초기화
              this.initCallCtiCall();				                                      // CIT CALL 정보 (수/발신 정보)
            }

            this.btnClassRemove();
            this.ctiBtnEvent('handle');                                         // 후처리 이벤트
          }
        }

      });

      /**
       * 링 이벤트
       * message : RINGING|01071378458|07080151166|11|초진 문의 - 남성센터|
       */
      eventBus.$on("busCtiRinging", (e) => {

        let aryMsg      = [];
        let aryMsgLen   = 0;

        let msg_evt       = "";                               // 메시지 종류
        let phone         = "";                               // 전화번호
        let aspCustPhone  = "";                               // ASP CUST 대표번호
        let ivrSvc        = "";
        let ivrText       = "";                               // IVR시나리오
        let bl            = "";                               // 고객등급
        let blCss         = "";                               // 고객등급 CSS

        /** 대기상태_상담원 전화 안 받을 경우 연속적인 RINGING 예외처리  */
        if (this.CTI_DB_STATE == "ready" &&  this.CTI_INFO.strCallMode == "inbound") {
        } else {

          if (e.length > 0) {

            aryMsg      = e.split("|");
            aryMsgLen   = aryMsg.length;

            if (aryMsgLen > 0) {
              msg_evt       = aryMsg[0];                       // 메시지 종류
              phone         = aryMsg[1];                       // 전화번호
              aspCustPhone  = aryMsg[2];                       // ASP CUST 대표번호
              ivrSvc        = aryMsg[3];
              ivrText       = aryMsg[4];
              bl            = aryMsg[5];                       // 고객등급
            }
          }

          if (msg_evt != "RINGING") {
            this.CTI_TRANS_CONFER.gbn = msg_evt;
          }

          if (this.CTI_DB_STATE == "ready") {

            this.CTI_INFO.strCallMode       = "inbound";
            this.CTI_INFO.strAspCustTel     = aspCustPhone;

            this.UEI_INFO.strInboundNum     = phone;
            this.UEI_INFO.inboundInputType  = ivrSvc;
            this.UEI_INFO.inboundInputTypecd = ivrText;
            this.UEI_INFO.bl = bl;

            let bol = false;

            let i = 0;
            let aspCustTelLen = this.ASP_CUST_TEL.length;

            /** 고객사 키 및 명 추출  */
            for(; i < aspCustTelLen; i++) {

              let txt    = this.ASP_CUST_TEL[i].text;             // 코드 명
              let cd     = this.ASP_CUST_TEL[i].value;            // 코드 (대표번호)
              let etcCd1 = this.ASP_CUST_TEL[i].info1;            // 기타코드 1 (ASP CUST KEY)

              if (cd.trim() == aspCustPhone.trim()) {
                bol = true;
              }

              if(bol) {

                this.CTI_INFO.strAspCustKey = etcCd1.trim();
                this.CTI_TAKE_CALL.company = txt.trim();

                //this.getCmmCode();                                // 회사코드에 따른 공통코드 추출 -- 인바운드 작동 안함
                break;
              }
            }

            this.getCmmCode();

            this.CTI_BRD.current = "벨울림";
            this.CTI_BRD.icon    = "cti-btn-answer";
            this.CTI_DB_STATE    = "ringing";

            /** 대기해제 -> 대기 타이틀 변경 */
            this.CTI_BTN[2].text = "대기";

            this.initCallCti(this.CTI_DB_STATE);                  // 링
            this.settingCallMode(this.CTI_INFO.strCallMode, this.CTI_DB_STATE);
          }

          /** 고객등급에 따른 CSS 적용 */
          if (typeof bl != undefined && typeof bl != "undefined" && bl != "") {

             if (bl == "VIP") {
              blCss = "cs-vip";
             } else if (bl == "BL1") {
              blCss = "cs-bl01";
             } else if (bl == "BL2") {
              blCss = "cs-bl02";
             } else if (bl == "BL3") {
              blCss = "cs-bl03";
             }
          }

          /** 전화수신 모달 정보 세팅 */
          this.CTI_TAKE_CALL.phone = this.mixin_setPhoneNo(phone);
          this.CTI_TAKE_CALL.svc   = ivrSvc;
          this.CTI_TAKE_CALL.text  = ivrText;
          this.CTI_TAKE_CALL.blCss = blCss;           // cs-bl01,cs-bl02,cs-bl03,cs-vip

          this.ctiRinging();
        }

      });

      /**
       * 전화받기 이벤트
       */
      eventBus.$on("busCtiAnswer", (e) => {

        let aryMsg      = [];
        let aryMsgLen   = 0;

        let msg_evt     = "";
        let phone       = "";
        let recKey      = "";

        //case "ANSWER" :                 // 전화수신 통화시작 message : ANSWER|01071378458|20220621/1001-20220621-172625-IB-01071378458-1655799985.313
        //case "OUTBOUND" :               // 전화수신 통화시작 message : OUTBOUND|01075838458|20220622/1001-20220622-185327-OB-01075838458-1655891607.844

        console.log("#####busCtiAnswer############################################################");
        console.log("this.CTI_DB_STATE : " + this.CTI_DB_STATE);
        console.log("this.CTI_INFO.strCallMode : " + this.CTI_INFO.strCallMode);

        //if (this.CTI_INFO.strTransSucess == "N" && this.CTI_INFO.strState == "outer_phone_tran") {
        //  this.CTI_INFO.strState = "outbound_phone_tran";
        //} else if (this.CTI_INFO.strTransSucess == "N" && this.CTI_INFO.strState == "fanvil_outer_phone_tran") {
        //  this.CTI_INFO.strState = "fanvil_outbound_phone_tran";
        //}
        if (this.CTI_INFO.strTransSucess == "N" && this.CTI_INFO.strState == "phone_tran") {
          //
        }else {

          if ((this.CTI_DB_STATE == "ringing" && this.CTI_INFO.strCallMode == "inbound") || this.CTI_INFO.strCallMode == "outbound") {

            let transConferGbn = "";
            let ctiDbState = "answer";

            if (e.length > 0) {
              aryMsg      = e.split("|");
              aryMsgLen   = aryMsg.length;

              if (aryMsgLen > 0) {
                msg_evt = aryMsg[0];                            // 메시지 종류
                phone   = aryMsg[1];                            // 전화번호
                recKey  = aryMsg[2];                            // 녹취키

                console.log("this.UEI_INFO.strInboundNum : " + this.UEI_INFO.strInboundNum);
                console.log("phone : " + phone);

                if (this.CTI_INFO.strCallMode == "outbound") {
                  this.CTI_INFO.strRecKey = recKey;
                } else if ((this.UEI_INFO.strInboundNum.trim() == phone.trim()) && (this.CTI_DB_STATE == "ringing" && this.CTI_INFO.strCallMode == "inbound")) {
                  this.CTI_INFO.strRecKey = recKey;
                }
              }
            }

            /** 아웃바운드 시 발신번호 세팅 */
            if (this.CTI_INFO.strCallMode == "outbound") {

              this.CTI_INFO.strAspCustKey = this.CUS_ASP_CUST_KEY;
              this.UEI_INFO.strInboundNum = phone;

              this.getCmmCode();                                                                        // 회사코드에 따른 공통코드 추출
            }

            this.CTI_DB_STATE      = ctiDbState;
            this.CTI_BRD.current   = "통화중";
            this.CTI_BRD.icon      = "cti-btn-answer";
            this.CTI_INFO.strState = this.CTI_DB_STATE;

            /** 콜상담 상단 고객정보 표시 */
            this.CTI_CALL_STATUS = {
              incall          : (this.CTI_INFO.strCallMode == "outbound") ? false : true,
              img             : require("@/assets/logo.png"),
              imgAlt          : this.CTI_TAKE_CALL.company,                                             // 기업명
              name            : (this.CTI_INFO.strCallMode == "outbound") ? this.CUS_CUSTOMER_NM : "",  // 고객명 홍홍홍
              phoneNumber     : this.mixin_setPhoneNo(phone),                                           // 000-0000-0000
              phoneMaskNumber : this.mixin_mask_num(phone),                                             // 000-****-0000
              mask            : false,
            }

            /** 인바운드 고객 검색 세팅 */
            if (this.CTI_INFO.strCallMode != "outbound") {
              this.SCH_ASP_CUST_KEY      = this.CTI_INFO.strAspCustKey;                                 // 고객정보_고객검색_업체명
              this.SCH_CUSTOMER_PHONE_NO = this.mixin_setPhoneNo(phone);                                // 고객정보_고객검색_전화번호
            }

            /** 통화시간 / 고객조회 */
            this.settingCallMode(this.CTI_INFO.strCallMode, this.CTI_DB_STATE);

            /** CTI 버튼 재 조정 */
            this.initCallCti(this.CTI_DB_STATE);

            /** CTI 로그인 버튼 비활성화 */
            this.CTI_ANSWER_BOOLEAN = true;

            /** 콜상담저장_콜부분 */
            this.CNSL_CALL_TY  = this.CTI_INFO.strCallMode != "outbound" ? '01' : '02';
            this.CNSL_DISABLED = false;

            /** 호전환, 3자 */
            transConferGbn = typeof this.CTI_TRANS_CONFER.gbn == undefined || typeof this.CTI_TRANS_CONFER.gbn == "undefined" ? "" : this.CTI_TRANS_CONFER.gbn;

            if (transConferGbn == "TRANSFER" || transConferGbn == "CONFERENCE") {
              this.CTI_TRANS_CONFER.state = ctiDbState;
            }
          }
        }
      });

      /**
       * 판빌전화기 연동
       */
      eventBus.$on("busCtiInitCall", (evnt) => {

        if (evnt == "phone_call" || evnt == "phone_call2") {

          this.CTI_INFO.strCallMode = "outbound";

          this.CTI_BRD.current = "벨울림";
          this.CTI_BRD.icon    = "cti-btn-answer";
          this.CTI_DB_STATE    = "ringing";

          this.ctiHandleCls(false);                                                         // OB 시 후처리 버튼 활성화 제거
          this.initCallCti(this.CTI_DB_STATE);                                              // 링

        } else  if (evnt == "phone_mute" || evnt == "phone_hold") {

          let ctiBtnGbn = "";

          if (evnt == "phone_mute") {

            ctiBtnGbn = (this.CTI_DB_STATE == "mute") ? "answer" : "mute";
            this.CTI_BTN[0].text = (this.CTI_DB_STATE == "mute") ? "음소거" : "음소거해제";

          } else if (evnt == "phone_hold") {

            ctiBtnGbn = (this.CTI_DB_STATE == "hold") ? "answer" : "hold";
            this.CTI_BTN[1].text = (this.CTI_DB_STATE == "hold") ? "보류" : "보류해제";

          }

          this.btnClassRemove();

          if (ctiBtnGbn == "answer") {
            this.CTI_BRD.current = "통화중";
            this.CTI_BRD.icon    = "cti-btn-answer";
          }

          this.CTI_DB_STATE = ctiBtnGbn;

          /** CTI 버튼 배열 조정 */
          this.initCallCti(ctiBtnGbn);

        } else if (evnt == "fanvil_phone_tran") {                        // 판빌 호전환 (판빌 -> 내부/외부)

          this.CTI_INFO.strTransSucess = "N";                             // 호전환 완료 여부
          this.CTI_INFO.strPreCtiState = this.CTI_INFO.strState;          // CTI 이전상태값

          console.log("#####phone_tran#################################################################");
          console.log("this.CTI_INFO.strTransSucess : " + this.CTI_INFO.strTransSucess);
          console.log("this.CTI_INFO.strState : " + this.CTI_INFO.strState);
          console.log("this.CTI_INFO.strPreCtiState : " + this.CTI_INFO.strPreCtiState);
          console.log("this.CTI_TRANSFER_INFO.inLineNo : " + this.CTI_TRANSFER_INFO.inLineNo);

          if (this.CTI_TRANSFER_INFO.inLineNo.length < 9) {
            this.CTI_INFO.strState = "fanvil_inner_phone_tran";          // 내부 -> 내부 호전환
          } else {
            this.CTI_INFO.strState = "fanvil_outer_phone_tran";          // 내부 -> 외부 호전환
          }

          console.log("this.CTI_INFO.strState : " + this.CTI_INFO.strState);

          this.ctiPhoneTranBol(true);

        } else if (evnt == "fanvil_phone_trok") {

          this.CTI_INFO.strState      = this.CTI_INFO.strPreCtiState; // CTI 이전상태값
          this.CTI_INFO.strPreCtiState = "";                        // CTI 이전상태값
          this.CTI_INFO.strTransSucess = "Y";

          console.log("#####phone_trok#################################################################");
          console.log("this.CTI_INFO.strState : " + this.CTI_INFO.strState);
          console.log("this.CTI_INFO.strPreCtiState : " + this.CTI_INFO.strPreCtiState);
          console.log("this.CTI_INFO.strTransSucess : " + this.CTI_INFO.strTransSucess);

          this.hideDialog("M110101P01");
        }
      });

      /**
       * 호전환 리스트
       * @param {} e
       */
      eventBus.$on("busCtiTransferlst", (e) => {

        let transferInfo = {};

        transferInfo = {
          aspCustKey: this.CTI_INFO.strAspCustKey,      //"1040",
          transferLst : e
        };

        this.CTI_TRANSFER_INFO = transferInfo;

      });

      /**
       * 콜스킵기능_스킵/전환
       * @param {} e
       */
      eventBus.$on("busCtiSkipTrans", (e) => {

        let rtnData;

        if (e.length > 0) {
          e = e.replace('[', '');
          e = e.replace(']', '');

          rtnData = JSON.parse(e);                                    // JSON 데이터 파싱

          if (rtnData.result == "true") {

            this.hideDialog('M110101P05');                            // 전화수신 모달

            this.initCallCtiInof();				                            // CTI_INFO, UEI_INFO 초기화
            this.initCallCtiCall();				                            // CIT CALL 정보 (수/발신 정보)

            this.btnClassRemove();
            this.ctiBtnEvent('handle');                               // 후처리 이벤트

          } else {
            alert("콜스킵기능_스킵/전환 오류 (" + rtnData.desc + ")");
          }
        }
      });

      /**
       * 호전환 취소 (phone_busy인 경우)
       * @param {} e
       */
      eventBus.$on("busCtiPhoneBusy", (e) => {
        this.$socket.emit("sendmsg", "phone_hold", "");
      });

      /**
       * 호전환 취소 (phone_busy외 경우)
       * @param {} e
       */
      eventBus.$on("busCtiPhoneNo", (e) => {
        this.$socket.emit("sendmsg", e, "");
      });

      /**
       * 호전환 상대방 거절
       * @param {} e
       */
      eventBus.$on("busCtiTransBusy", () => {

        console.log("#####busCtiTransBusy########################################");
        console.log("this.CTI_INFO.strTransSucess : " + this.CTI_INFO.strTransSucess);
        console.log("this.CTI_INFO.strState : " + this.CTI_INFO.strState);
        console.log("this.CTI_TRANSFER_INFO.inLineNo : " + this.CTI_TRANSFER_INFO.inLineNo);
        console.log("this.CTI_TRANSFER_INFO.inLineNo.length : " + this.CTI_TRANSFER_INFO.inLineNo.length);

        //this.ctiHold();                                                             // this.$socket.emit("sendmsg", "phone_hold", "");

        if (this.CTI_INFO.strTransSucess == "N" && this.CTI_INFO.strState == "hangup" && this.CTI_TRANSFER_INFO.inLineNo.length > 8) { // 호전환 내부 -> 외부 (고객 종료 시)
        } else {
            setTimeout(() => {
            this.ctiChkFanvil('phone_trno');
          }, 1000);
        }

      });

      /**
       * 3자 상대방 거절
       * @param {} e
       */
      eventBus.$on("busCtiConfBusy", () => {
        this.ctiTrnoCfno();
      });

      /**
       * 3자 시도 내부->내부 (상대방 상담사 통화 후 종료)
       * @param {} e
       */
      eventBus.$on("busCtiConfHang", () => {

        setTimeout(() => {
          this.ctiPhoneCfno();
        }, 1000);

      });

      /**
       * 예약
       * @param {} e
       */
      eventBus.$on("busResv", (e) => {

        if (this.CTI_DB_STATE == "handle" || this.CTI_LOGIN_BOOLEAN == false) {

          this.intiCall("RV");                                                      // 초기화

          this.CALL_RESV_CALL.RESV_SEQ = e.RESV_SEQ;                                // 예약콜ID
          this.CALL_RESV_CALL.FISH_YN  = e.FISH_YN;                                 // 승인여부
          this.CALL_RESV_CALL.TRY_CNT  = e.TRY_CNT;                                 // 시도횟수

          this.SCH_ASP_CUST_KEY        = e.ASP_CUST_KEY;                            // 고객정보_고객검색_업체명
          this.SCH_CUSTOMER_PHONE_NO   = this.mixin_setPhoneNo(e.CUST_TEL_NO);      // 고객정보_고객검색_전화번호

          this.getCusInfo();                                                        // 고객 조회

        } else {
          alert("후처리 상태 외 예약 고객을 조회 할 수 없습니다.");
        }

      });

      /**
       * 콜백 리스트
       * @param {} e
       */
      eventBus.$on("busCallBack", (e) => {

        if (this.CTI_DB_STATE == "handle" || this.CTI_LOGIN_BOOLEAN == false) {

          this.intiCall("CB");                                                      // 초기화

          this.CALL_CALL_BACK.CLBK_NO = e.CLBK_NO;

          this.SCH_ASP_CUST_KEY= e.ASP_CUST_KEY;                                    // 고객정보_고객검색_업체명
          this.SCH_CUSTOMER_PHONE_NO = this.mixin_setPhoneNo(e.CLBK_TEL_NO);        // 고객정보_고객검색_전화번호

          this.getCusInfo();                                                        // 고객 조회

        } else {
          alert("후처리 상태 외 콜백 고객을 조회 할 수 없습니다.");
        }

      });

      /**
       * 캠페인 리스트
       * @param {} e
       */
      eventBus.$on("busCampain", (e) => {

        if (this.CTI_DB_STATE == "handle" || this.CTI_LOGIN_BOOLEAN == false) {

          this.intiCall("CP");                                                      // 초기화

          this.CALL_OUT_BOUND.CAM_ID  = e.CAM_ID;
          this.CALL_OUT_BOUND.CUST_NM = e.CUST_NM;

          this.SCH_ASP_CUST_KEY       = e.ASP_CUST_KEY;                             // 고객정보_고객검색_업체명
          this.SCH_CUSTOMER_PHONE_NO  = this.mixin_setPhoneNo(e.TEL_NO);            // 고객정보_고객검색_전화번호

          this.getCusInfo();                                                        // 고객 조회

        } else {
          alert("후처리 상태 외 캠페인 고객을 조회 할 수 없습니다.");
        }

      });

      /**
       * 이벤트 호출
       * Stomp Disconnect
       */
      eventBus.$on("busStompDisconnect", () => {
        this.disconnect();
      });

      /**
     * 상담원 대기 구독 메시지 인입
     */
    eventBus.$on("busReadyCallback", (message) => {
      this.readyCallback(message);
      this.getTalkList();
    });

    /**
     * 채팅 메시지 인입
     */
    eventBus.$on("busInoutCallback", (message) => {
      this.inoutCallback(message);
      this.getTalkList();
      this.getTalkInfo();
      this.scrlToBottom();
    });

    /**
     * 채팅 메시지 전송
     */
    eventBus.$on("userchatMessage", (message) => {
      this.$store.dispatch("messages/AC_USER_CHAT_MESSAGE", message);
      //chatStore.userchatMessage(message);
    });

    /****************************************************************************************************************************************************
     * EventBus created START
     ****************************************************************************************************************************************************
     ***************************************************************************************************************************************************/

  },

  data() {
    return {
      // 콜관련공통코드_콜상담유형_상담통합이력 IN_상담통합이력 OB_B/L 구분_상담통합 상담결과, CTI경과시간알림, CTI 경과시간구분, CTI 상태 알림 상태값, 고객사 대표번호
      cmmnCd: [
        "PLT025",
        "PLT003",
        "PLT004",
        "PLT006",
        "PLT024",
        "OU003",
        "OU005",
        "CRS022",
        "CRS024",
        "CRS047",
        "CRS048",
        "CRS049",
        "CUS001",
        "RE001",
        //"MTC001",
        //"MTC002",
        //"MTC003",
        //"MTC004",
        //"MTC005",
        //"MTC006",
        //"MTC007",
        //"MTC008",
        //"MTC009",
        //"MTC010",
        //"MTC011",
        //"MTC012",
        //"MTC013",
        "CO001",
        "CUS002",
      ],
      cmmnCdLst: [], // 콜관련공통코드_공통코드 조회 값

      arrMsg: "",
      msg_evt: "",
      telNo: "",
      campCode: "",
      today: "",
      year: "",
      month: "",
      day: "",
      dateString: "",
      hours: "",
      minutes: "",
      seconds: "",
      timeString: "",
      el: "",
      callnum: "",
      testEx: "",
      testEx2: "",
      valueEx: "",
      telNob: "",

      dialogM110101P01: false, //호전환 모달
      dialogM110101P02: false, //고객정보 조회 모달
      dialogM110101P03: false, //고객정보 전화걸기 모달
      dialogM110101P05: false, //임시 전화 수신 모달
      DialogM110102P01: false,

      dialogMP01: false,      //고객정보모달
      cusInfo: {},            //고객정보 파라미터

      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,

      chatTop: {
        channel: true,
        img: require("@/assets/logo.png"),
        imgAlt: "기업명",
        name: "챗상담홍",
        phoneNumber: "000-0000-0000",
      },

      chatRadioGroup: [
        {
          text: "CHAT",
        },
      ],
      chatRadioGroupSelected: "CHAT",

      CNSL_WK: ['CALL', 'CHAT'],                // 상담구분_콜,챗
      BL_DROPITEMS : [],                        // B/L
      CUS_GENDER_DROPITEMS : [],                // GENDER
      dropItems: [],

      chatHistoryTextsize: 100,
      dropItemTextsize: [
        {
          title: "100%",
          size: 100,
        },
        {
          title: "120%",
          size: 120,
        },
        {
          title: "140%",
          size: 140,
        },
      ],

      PROC_DROPITEMS : [],       // 상담결과

      dropItemsChatChannel: [
        { title: "전체" },
        { title: "카카오톡" },
        { title: "채널톡" },
        { title: "라인" },
      ],

      chatCalendar: false,
      chatCalendar2: false,
      timeStep: "00:00",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      chatmainChatEdit: true,
      chatmainCsPhone: [
        {
          title: "010-0000-0000",
        },
        {
          title: "010-1234-0000",
        },
      ],
      csDetailSearch: false,
      csDetailSearch2: false,
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],

      chatmainHistory2: [],

      // 상담현황
      chatWaitList: [],
      chatList: [],
      toggle_csCtBtn: 0,
      chatFilter: false,
      chatListFilter: "all",
      csStatList: true,
      csStatChat: false,
      // 채팅 상담창
      chatHistory: [],
      scroll: 0,
      scrollTarget: null,
      chatDone: false,
      chatTemp: false,
      chatTrans: false,
      chatCallback: false,
      chatFontsize: false,

      //220525 김태준 채팅 이벤트 추가
      //
      USER_STAT: [{ CD_NM: "채팅OFF", CD: "CHATOFF" }],
      USER_NEWSTAT: "CHATOFF",

      TALK_CHN: [{ CD_NM: "카카오톡", CD: "KAKAO" }],
      TALK_NEWCHN: "KAKAO",

      CNSL_TY: [{ CD: "", CD_NM: "전체" }],
      CNSL_NEWTY: "",

      TALK_STAT: [{ CD: "", CD_NM: "전체" }],
      TALK_NEWSTAT: "",

      // 상담현황
      // 채팅 상담창
      talkHistory: [],

      CUST: this.fullCust(),

      ChatListIng: "",
      ChatListDone: "",
      ChatListAfter: "",
      ChatListWait: "",

      CLK_CUST: "",
      CLK_CHAT: "",
      CLK_USER: "",
      CLK_SENDER: "",
      CLK_MSGSKIP: "",
      CLK_STAT: "",
      CLK_ICON: "",
      CLK_IMG: "",
      MARK_UP: "",
      SITE: "",
      CHAT_CUS_ID:"",

      CHT_RESULT: [],
      CHT_NEWRESULT: "",
      CHT_BACKRESULT: "4",

      USER_NM: "",
      USER_TEL_NO: "",
      USER_TEL_NO2: "",
      CLK_CUSTOMER_GENDER:"",
      CLK_CUSTOMER_AGE:"",
      CHK_BL: false,
      USER_MEMO: "",

      talkHistList: [],

      CNSL_DIV: [
        { title: "전체", value: "" },
        { title: "대분류", value: "1" },
        { title: "중분류", value: "2" },
        { title: "소분류", value: "3" },
      ],

      CNSL_NEWDIV: "",
      SEARCH_CONT: "",

      Imgdata: {},

      USER_ID: [],
      USER_NEWID: "",

      CNSL_TY1: [],
      CNSL_NEWTY1: "",
      CNSL_TY2: [],
      CNSL_NEWTY2: "",
      CNSL_TY3: [],
      CNSL_NEWTY3: "",
      CNSL_TY4: [],
      CNSL_NEWTY4: "",
      CHT_TIT: "",
      CHT_CON: "",

      MSG: "",

      EXEC_RST_CD: "",

      postcode: "",
      address: "",
      detailAddress: "",
      extraAddress: "",
      //
      //220525 김태준 채팅 이벤트 추가


      /****************************************************************************************************************************************************
       * 콜 변수 / 함수 정보 START
       ***************************************************************************************************************************************************/

      socket: "",
      ip_address: "",

      IPCC_SOCKET_URL: process.env.VUE_APP_IPCC_SOCKET_URL,     // IPCC 소켓 연결 주소
      SECURE_MODE : true,                                       // SSL option
      PING_INTERVAL: 1000 * 10,
      PING_TIMEOUT: 1000 * 60 * 60 * 3,                         // 스크린세이버 대응 : 3시간

      CTI_HOME_BOOLEAN  : false,                                // 재택여부
      CTI_LOGIN_BOOLEAN : false,                                // CTI 로그인 여부
      CTI_BOOLEAN : false,                                      // CTI 로그인 비활성화 여부
      CTI_ANSWER_BOOLEAN : false,

      CTI_OB_CHK : false,                                       // OB 전용_고객저장, 상담저장 예외처리
      CTI_OB_DISABLE : false,                                   // OB 전용 활성화 여부

      CTI_CALL_TIMER: '',                                       // CTI 타이머
      CTI_TIMER_ALAM : [],                                      // CTI 타이머 알람시간
      CTI_ALAM_STATE : [],                                      // CTI 타이머 알람 상태값
      CTI_ALAM_TEXT : [],                                       // CTI 상태 알림 상태값

      CTI_TRANSFER_INFO : {},                                   // 호전환 정보  CTI_TRANSFER_INFO

      ASP_CUST_TEL : [],                                        // 고객사 대표번호
      TAKE_CUST_TEL : [],                                       // 발신번호 선택
      TAKE_CUST_TEL_DROPITEMS : [],                             // 발신번호

      CTI_INFO : [                                              // CTI 상태
        {
          strState : "",				                                // 전화기 상태정보
          strCnslId : "",				                                // 상담이력 정보저장
          strCallMode : "",			                                // 인아웃바운드 구분
          strAspCustKey : "",                                   // ASP CUST KEY
          strAspCustTel : "",                                   // ASP CUST 대표번호
          strChannel : "",			                                // cti 채널키
          strRecKey : "",				                                // 녹취키
          strConference : "",	                                  // 3자통화 여부
          strConferenceCancle : "",	                            // 3자통화 고객취소여부
          strTransSucess : "",		                              // 2자전환 완료여부
          strStartTime :	"",			                              // 전화종료시간
          strStartDate :	"",			                              // 전화종료시간
          strEndTime :	"",			                                // 전화종료시간
          strEndDate :	"",			                                // 전화종료시간
          strReqSearchDate :	"",		                            // 전화종료녹취검색시간
          strPreCtiState :	"",		                              // 이전상태값
        }
      ],

      CTI_TRANS_CONFER : [                                      // 호전환, 3자
        {
          gbn : "",                                             // 호전환, 3자 여부
          state : ""                                            // ringing, answer 상태
        }
      ],

      UEI_INFO : [                                              // UEI 데이터 정보
        {
          strInboundNum : "",				                            // 인입번호
          inboundInputType : "",			                          // 인입경로
          inboundInputTypecd : "",		                          // 인입경로코드
          strNum2 : "",					                                // 2자전화번호
          strCallPath : "",				                              // 콜패스
          bl : ""                                               // 고객등급
        }
      ],

      CALL_PHN_CNSL_HISTORY : [],                               // 상담이력
      CALL_PHN_CNSL_DTL : [],                                   // 상담이력

      CALL_CNSL_TYP_CD : [],                                    // 상담유형코드
      CALL_CNSL_TYP_CD_2 : [],                                  // 상담유형코드2
      CALL_CNSL_TYP_CD_3 : [],                                  // 상담유형코드3

      CALL_TY_DROPITEMS : [],                                   // 상담유형


      PROC_TY_DROPITEMS : [],                                   // 캠페인 처리구분
      FISH_TY_DROPITEMS : [],                                   // 캠페인 처리결과
      EOT_TY_RLT_DROPITEMS : [],                                // 콜백 처리구분
      PROC_CODE_DROPITEMS : [],                                 // 콜백 처리결과
      FISH_YN_DROPITEMS : [],                                   // 예약 처리결과

      CHANNEL_DROPITEMS : [],                                   // 채녈유형 PLT002

      CNSL_LST: [                                               // 콜상담이력
        {
          id: 1,
          expand: false,
          icon: "svg-chatmain-calltype01",                      // 수신
          result: "완료",
          resultCode: "done",
          date: "2020-00-00",
          manager: "상담사",
          channel: "채널유형",
          bl: "bl",
          company: "업체명",
          csResult: "상담결과",
          csMemo: "관리메모 관리메모 관리메모 관리메모 ",
          category: [
            { id: 1, title: "선택된 유형1" },
            { id: 2, title: "선택된 유형2" },
            { id: 3, title: "선택된 유형3" },
            { id: 4, title: "선택된 유형4" },
          ],
          category01: { id: 1, title: "선택된 유형1" },
          category02: { id: 2, title: "선택된 유형2" },
          category03: { id: 3, title: "선택된 유형3" },
          category04: { id: 4, title: "선택된 유형4" },
          textarea:
            "상담내용 길다. 상담내용 길다. 상담내용 길다. 상담내용 길다. ",
          audio: require("@/assets/@sample.mp3"),
        },
        {
          id: 2,
          expand: false,
          icon: "svg-chatmain-calltype02", //발신
          result: "미완료",
          resultCode: "undone",
          date: "2020-00-00",
          manager: "상담사",
          category: [
            { id: 1, title: "선택된 유형1" },
            { id: 2, title: "선택된 유형2" },
            { id: 3, title: "선택된 유형3" },
            { id: 4, title: "선택된 유형4" },
          ],
          category01: { id: 1, title: "선택된 유형1" },
          category02: { id: 2, title: "선택된 유형2" },
          category03: { id: 3, title: "선택된 유형3" },
          category04: { id: 4, title: "선택된 유형4" },
          textarea:
            "상담내용 길다. 상담내용 길다. 상담내용 길다. 상담내용 길다. ",
          audio: require("@/assets/@sample.mp3"),
        },
        {
          id: 3,
          expand: false,
          icon: "svg-chatmain-calltype03", //챗
          result: "처리중",
          resultCode: "ing",
          date: "2020-00-00",
          manager: "상담사",
          category: [
            { id: 1, title: "선택된 유형1" },
            { id: 2, title: "선택된 유형2" },
            { id: 3, title: "선택된 유형3" },
            { id: 4, title: "선택된 유형4" },
          ],
          category01: { id: 1, title: "선택된 유형1" },
          category02: { id: 2, title: "선택된 유형2" },
          category03: { id: 3, title: "선택된 유형3" },
          category04: { id: 4, title: "선택된 유형4" },
          textarea:
            "상담내용 길다. 상담내용 길다. 상담내용 길다. 상담내용 길다. ",
          audio: require("@/assets/@sample.mp3"),
        },
        {
          id: 4,
          expand: false,
          icon: "svg-chatmain-calltype01",
          result: "완료",
          resultCode: "done",
          date: "2020-00-00",
          manager: "상담사",
          category: [
            { id: 1, title: "선택된 유형1" },
            { id: 2, title: "선택된 유형2" },
            { id: 3, title: "선택된 유형3" },
            { id: 4, title: "선택된 유형4" },
          ],
          category01: { id: 1, title: "선택된 유형1" },
          category02: { id: 2, title: "선택된 유형2" },
          category03: { id: 3, title: "선택된 유형3" },
          category04: { id: 4, title: "선택된 유형4" },
          textarea:
            "상담내용 길다. 상담내용 길다. 상담내용 길다. 상담내용 길다. ",
          audio: require("@/assets/@sample.mp3"),
        },
      ],

      CALL_CNSL_LST: [
        {
          id: 1,
          expand: false,
          icon: "svg-chatmain-calltype01",                      // 수신
          result: "완료",
          resultCode: "done",
          date: "2020-00-00",
          manager: "상담사",
          channel: "채널유형",
          bl: "bl",
          company: "업체명",
          csResult: "상담결과",
          csMemo: "관리메모 관리메모 관리메모 관리메모 ",
          category: [
            { id: 1, title: "선택된 유형1" },
            { id: 2, title: "선택된 유형2" },
            { id: 3, title: "선택된 유형3" },
            { id: 4, title: "선택된 유형4" },
          ],
          category01: { id: 1, title: "선택된 유형1" },
          category02: { id: 2, title: "선택된 유형2" },
          category03: { id: 3, title: "선택된 유형3" },
          category04: { id: 4, title: "선택된 유형4" },
          textarea:
            "상담내용 길다. 상담내용 길다. 상담내용 길다. 상담내용 길다. ",
          audio: require("@/assets/@sample.mp3"),
        },
        {
          id: 2,
          expand: false,
          icon: "svg-chatmain-calltype02", //발신
          result: "미완료",
          resultCode: "undone",
          date: "2020-00-00",
          manager: "상담사",
          category: [
            { id: 1, title: "선택된 유형1" },
            { id: 2, title: "선택된 유형2" },
            { id: 3, title: "선택된 유형3" },
            { id: 4, title: "선택된 유형4" },
          ],
          category01: { id: 1, title: "선택된 유형1" },
          category02: { id: 2, title: "선택된 유형2" },
          category03: { id: 3, title: "선택된 유형3" },
          category04: { id: 4, title: "선택된 유형4" },
          textarea:
            "상담내용 길다. 상담내용 길다. 상담내용 길다. 상담내용 길다. ",
          audio: require("@/assets/@sample.mp3"),
        },
        {
          id: 3,
          expand: false,
          icon: "svg-chatmain-calltype03", //챗
          result: "처리중",
          resultCode: "ing",
          date: "2020-00-00",
          manager: "상담사",
          category: [
            { id: 1, title: "선택된 유형1" },
            { id: 2, title: "선택된 유형2" },
            { id: 3, title: "선택된 유형3" },
            { id: 4, title: "선택된 유형4" },
          ],
          category01: { id: 1, title: "선택된 유형1" },
          category02: { id: 2, title: "선택된 유형2" },
          category03: { id: 3, title: "선택된 유형3" },
          category04: { id: 4, title: "선택된 유형4" },
          textarea:
            "상담내용 길다. 상담내용 길다. 상담내용 길다. 상담내용 길다. ",
          audio: require("@/assets/@sample.mp3"),
        },
        {
          id: 4,
          expand: false,
          icon: "svg-chatmain-calltype01",
          result: "완료",
          resultCode: "done",
          date: "2020-00-00",
          manager: "상담사",
          category: [
            { id: 1, title: "선택된 유형1" },
            { id: 2, title: "선택된 유형2" },
            { id: 3, title: "선택된 유형3" },
            { id: 4, title: "선택된 유형4" },
          ],
          category01: { id: 1, title: "선택된 유형1" },
          category02: { id: 2, title: "선택된 유형2" },
          category03: { id: 3, title: "선택된 유형3" },
          category04: { id: 4, title: "선택된 유형4" },
          textarea:
            "상담내용 길다. 상담내용 길다. 상담내용 길다. 상담내용 길다. ",
          audio: require("@/assets/@sample.mp3"),
        },
      ],

      /**
       * 아웃바운드 세팅 정보
       */
      CALL_OUT_BOUND : {
        QUEST_ID : ""	                  // 설문번호
        ,CUST_NO : ""	                  // 고객번호
        ,CUST_NM : ""	                  // 고객명
        ,CAM_NM  : ""	                  // 캠페인이름
        ,CAM_ID  : ""	                  // 캠페인ID
        ,CENT_TY : ""                   // 센터구분
      },

      /**
       * 콜백세팅정보
       */
      CALL_CALL_BACK: {
        CLBK_NO : ""                    //콜백 ID
      },

      /**
       * 예약콜 세팅정보
       */
      CALL_RESV_CALL : {
        RESV_SEQ : ""	                  // 예약콜ID
        ,FISH_YN : ""	                  // 승인여부
        ,TRY_CNT : ""	                  // 시도횟수
      },

      CTI_BRD: {
        isBtnActive: false,
        expand: false,
        menuActive: null,
        current: null,
        lapseTime: "00:00:00",
        icon: null,
        ctiTimeLapse: false,
        ctiTextLapse: false,
      },

      CTI_BRD_MOVE: {
        status: undefined,
        current: '이석',
        icon: 'cti-btn-icon08',
      },

      /**
       * CTI Button 배열
       */
      CTI_BTN: [
        {
          icon: "cti-btn-mute",
          text: "음소거",
          disalb: true,
          desc: "mute",
          mr: true,
          cls : ''                        // 사용자 정의 클래스
        },
        {
          icon: "cti-btn-hold",
          text: "보류",
          disalb: true,
          desc: "hold",
          mr: true,
          cls:''                          // 사용자 정의 클래스
        },
        {
          icon: "cti-btn-ready",
          text: "대기",
          disalb: true,
          desc: "ready",
          mr: true,
          cls:''                          // 사용자 정의 클래스
        },
        {
          icon: "cti-btn-hang",
          text: "끊기",
          disalb: true,
          desc: "hang",
          mr: false,
          cls:''                          // 사용자 정의 클래스
        },
        {
          icon: "cti-btn-call",
          text: "걸기",
          evt: true,
          evtId: "M110101P03",
          disalb: true,
          desc: "call",
          mr: true,
          cls:''                          // 사용자 정의 클래스
        },
        {
          icon: "cti-btn-tran",
          text: "호전환",
          evt: true,
          evtId: "M110101P01",
          disalb: true,
          desc: "tran",
          mr: true,
          cls:''                          // 사용자 정의 클래스
        },
        {
          icon: "cti-btn-handle",
          text: "후처리",
          disalb: true,
          desc: "handle",
          mr: true,
          cls:''                          // 사용자 정의 클래스
        },
        {
          icon: 'cti-btn-reason',
          text: '이석',
          evt: true,
          restEvt: true,
          restIcon: null,
          refIcon: true,
          refText: true,
          disalb: true,
          desc: "reason",
          cls:''                          // 사용자 정의 클래스
        }
      ],

      /**
       * CTI 버튼 활성화 여부
       * 통화중_icon01, 보류_icon02, 대기_icon03, 끊기_icon04, 걸기_icon07, 호전환_icon05, 후처리_icon06, 이석_icon08
       */
      CTI_BTN_DISABLED : {

        ctiOff: [                                   // CTI OFF
          {desc:"mute", bol:true},                  // 음소거
          {desc:"hold", bol:true},                  // 보류
          {desc:"ready", bol:true},                 // 대기
          {desc:"hang", bol:true},                  // 끊기
          {desc:"call", bol:true},                  // 걸기
          {desc:"tran", bol:true},                  // 호전환
          {desc:"handle", bol:true},                // 후처리
          {desc:"reason", bol:true}                 // 이석
        ],
        ctiOn: [                                    // CTI ON
          {desc:"mute", bol:true},                  // 음소거
          {desc:"hold", bol:true},                  // 보류
          {desc:"ready", bol:false},                // 대기
          {desc:"hang", bol:true},                  // 끊기
          {desc:"call", bol:false},                 // 걸기
          {desc:"tran", bol:true},                  // 호전환
          {desc:"handle", bol:false},               // 후처리
          {desc:"reason", bol:false}                // 이석
        ],
        ctiReady: [                                 // CTI READY
          {desc:"mute", bol:true},                  // 음소거
          {desc:"hold", bol:true},                  // 보류
          {desc:"ready", bol:false},                // 대기
          {desc:"hang", bol:true},                  // 끊기
          {desc:"call", bol:true},                  // 걸기
          {desc:"tran", bol:true},                  // 호전환
          {desc:"handle", bol:true},                // 후처리
          {desc:"reason", bol:true}                 // 이석
        ],
        ctiReason: [                                // CTI 이석
          {desc:"mute", bol:true},                  // 음소거
          {desc:"hold", bol:true},                  // 보류
          {desc:"ready", bol:true},                 // 대기
          {desc:"hang", bol:true},                  // 끊기
          {desc:"call", bol:true},                  // 걸기
          {desc:"tran", bol:true},                  // 호전환
          {desc:"handle", bol:false},               // 후처리
          {desc:"reason", bol:false}                // 이석
        ],
        ctiRinging: [                               // CTI IN/OUT 전화벨
          {desc:"mute", bol:true},                  // 음소거
          {desc:"hold", bol:true},                  // 보류
          {desc:"ready", bol:true},                 // 대기
          {desc:"hang", bol:false},                 // 끊기
          {desc:"call", bol:true},                  // 걸기
          {desc:"tran", bol:true},                  // 호전환
          {desc:"handle", bol:true},                // 후처리
          {desc:"reason", bol:true}                 // 이석
        ],
        ctiAnswer: [                                // CTI  ANSWER / OUTBOUND
          {desc:"mute", bol:false},                 // 음소거
          {desc:"hold", bol:false},                 // 보류
          {desc:"ready", bol:true},                 // 대기
          {desc:"hang", bol:false},                 // 끊기
          {desc:"call", bol:true},                  // 걸기
          {desc:"tran", bol:false},                 // 호전환
          {desc:"handle", bol:true},                // 후처리
          {desc:"reason", bol:true}                 // 이석
        ],
        ctiHangup: [                                // CTI IN/OUT 통화종료
          {desc:"mute", bol:true},                  // 음소거
          {desc:"hold", bol:true},                  // 보류
          {desc:"ready", bol:true},                 // 대기
          {desc:"hang", bol:true},                  // 끊기
          {desc:"call", bol:true},                  // 걸기
          {desc:"tran", bol:true},                  // 호전환
          {desc:"handle", bol:false},               // 후처리
          {desc:"reason", bol:true}                 // 이석
        ],
        ctiMute: [                                  // 음소거
          {desc:"mute", bol:false},                 // 음소거
          {desc:"hold", bol:true},                  // 보류
          {desc:"ready", bol:true},                 // 대기
          {desc:"hang", bol:true},                  // 끊기
          {desc:"call", bol:true},                  // 걸기
          {desc:"tran", bol:true},                  // 호전환
          {desc:"handle", bol:true},                // 후처리
          {desc:"reason", bol:true}                 // 이석
        ],
        ctiHold: [                                  // 보류
          {desc:"mute", bol:true},                  // 음소거
          {desc:"hold", bol:false},                 // 보류
          {desc:"ready", bol:true},                 // 대기
          {desc:"hang", bol:true},                  // 끊기
          {desc:"call", bol:true},                  // 걸기
          {desc:"tran", bol:true},                  // 호전환
          {desc:"handle", bol:true},                // 후처리
          {desc:"reason", bol:true}                 // 이석
        ]
      },

      /**
       * CTI 이석 버튼 배열  1:대기, 2:후처리, 3:휴식, 4:교육, 5:식사, 6:기타
       */
      CTI_REASON: [
        {
          className: "cti-btn-expand-icon04",
          text: "휴식",
          dbState: "3"
        },
        {
          className: "cti-btn-expand-icon02",
          text: "교육",
          dbState: "4"
        },
        {
          className: "cti-btn-expand-icon03",
          text: "식사",
          dbState: "5"
        },
        {
          className: "cti-btn-expand-icon01",
          text: "기타",
          dbState: "6"
        }
      ],

      CTI_DB_STATE : "",                              // 음소거_mute, 보류_hold, 대기(1)_ready, 끊기_hang, 걸기_call, 호전환_tran, 후처리(2)_handle, 이석_reason, 3_휴식, 4_교육, 5_식사, 6_기타
      CTI_PRE_CALLTIME : 0,                           // 통화시간
      TRANS_INLINE_NO : "",                           // 콜스킵기능_전환번호

      /**
       * 수신전화 모달
       */
      CTI_TAKE_CALL: {
        ci: require("@/assets/@call-ci.png"),
        company: "",                                  // 고객사명
        phone: "",                                    // 010-0000-0000
        svc: "",                                      // 업무구분  : IVR시나리오 코드
        text: "",                                     // 업무구분  : IVR시나리오 한글명
        blCss: "",                                    // 고객등급
      },

      /**
       * 발신전화 모달
       */
      CTI_SEND_CALL: {
        ci: require("@/assets/@call-ci.png"),
        first: "",                                    // 대표번호
        phone: "010-0000-0000",                       // 010-0000-0000
        text: "업무구분  : IVR시나리오",              // 업무구분  : IVR시나리오
      },

      CTI_SEND_CALL_PHONE  : "",                      // 발신전화 번호
      CTI_SEND_ASP_CUST_KEY : "",                     // 발신전화 업체코드
      CTI_TAKE_CUST_TEL : "",                         // 발신번호

      /**
       * 콜상담 상단 고객정보 표시
       */
      CTI_CALL_STATUS: {
        incall: true,
        img: require("@/assets/logo.png"),
        imgAlt: "",                                   // 기업명
        name: "",                                     // 고객명 홍홍홍
        phoneNumber: "",                              // 000-0000-0000
        phoneMaskNumber: "",                          // 000-****-0000
        mask: false,
      },

      CTI_TRANSFER_URL : process.env.VUE_APP_IPCC_TRANSFER_URL + "db_select.php",   // 호전환 "http://139.150.75.152/api/db_select.php", // IPCC 상담사 상태 호출 URL
      CTI_SKIP_URL : process.env.VUE_APP_IPCC_TRANSFER_URL + "db_skip_deny.php",    // 스킵 http://139.150.75.152/api/db_skip_deny.php?ext=상담원본인내선&telno=고객전화번호
      CTI_TRANS_URL : process.env.VUE_APP_IPCC_TRANSFER_URL + "db_skip_trans.php",  // 전환 http://139.150.75.152/api/db_skip_trans.php?ext=상담원본인내선&telno=고객전화번호&ext2=전환내선번호

      CNSL_DISABLED : true,                           // 콜상담저장_Disabled
      CNSL_CALL_TY_RADIOGRP : [],                     // 콜상담저장_콜유형

      CNSL_PROC_TY_DROPITEMS  : [{text:'전체',value:''}], // 콜상담저장_처리구분_켐페인(PROC_TY), 콜백/예약콜(EOT_TY_RLT)
      CNSL_PROC_RLT_DROPITEMS : [{text:'전체',value:''}], // 콜상담저장_처리결과_켐페인(처리구분 FISH_TY), 콜백(PROC_CODE)

      CNSL_PROC_TY : "",                              // 콜상담저장_처리구분_켐페인(PROC_TY), 콜백/예약콜(EOT_TY_RLT)
      CNSL_PROC_RLT : "",                             // 콜상담저장_처리결과_켐페인(처리구분 FISH_TY), 콜백(PROC_CODE)

      CNSL_CALL_TY : "",                              // 콜상담저장_콜유형 선택값
      CNSL_ATENT_CUSTOMER : "",                       // 콜상담저장_주의고객
      CNSL_CNSL_TYP_CD : "",                          // 콜상담저장_상담유형
      CNSL_CNSL_TYP_CD_2 : "",                        // 콜상담저장_상담유형2
      CNSL_CNSL_TYP_CD_3 : "",                        // 콜상담저장_상담유형3
      CNSL_RESVE_CHK : "",                            // 콜상담저장_상담예약 체크 여부
      CNSL_NUM_CHK : "",                              // 콜상담저장_차수추가체크여부
      CNSL_ASP_CUST_KEY : "",                         // 콜상담저장_업체명
      CNSL_PROC_CODE_LCLS : "",                       // 콜상담저장_상담결과
      CNSL_CALL_TY_DTL : "",                          // 콜상담저장_콜유형_OB 상세 유형
      CNSL_PROC : "",                                 // 콜상담저장_캠페인/콜백/예약콜 처리구분
      CNSL_FISH : "",                                 // 콜상담저장_캠페인/콜백 처리결과
      CNSL_CUSTOMER_MEMO : "",                        // 콜상담저장_관리메모
      CNSL_CNTN : "",                                 // 콜상담저장_상담내용

      TYPE_CUS_DROPITEMS : [
        { title : '기존고객' },
        { title : '신규고객' },
      ],

      SCH_ASP_CUST_KEY : "",                          // 고객정보_고객검색_업체명
      SCH_CUSTOMER_PHONE_NO : "",                     // 고객정보_고객검색_전화번호
      SCH_CUSTOMER_ID : "",                           // 고객정보_고객검색_고객ID
      SCH_CUSTOMER_NM : "",				                    // 고객정보_고객검색_고객명

      CUS_INFO_SCH   : "",                            // 조회 고객정보
      CUS_INFO_WRITE : "",                            // 변경 고객정보

      CUS_OB_DISABLED : true,                         // 업체명 활성화 여부

      CUS_INPUT_DISABLED : true,                      // 고객정보 수정/저장 버튼_Disabled
      CUS_DISABLED : true,                            // 고객정보_Disabled
      CUS_CUSTOMER_SIDE_DISABLED : false,             // 동행
      CUS_PHONE_DISABLED : true,                      // 고객정보_전화번호1 걸기/복사하기

      CUS_ASP_CUST_KEY : "",                          // 고객정보_업체코드
      CUS_CUSTOMER_ID : "",                           // 고객정보_고객 ID
      CUS_CUSTOMER_NM : "",	                          // 고객정보_고객명
      CUS_CUSTOMER_SIDE_NM : "",	                    // 고객정보_동행자
      CUS_TALK_USER_KEY : "",                         // 고객정보_Key

      CUS_PHONE_NO : "",                              // 고객정보_전화번호
      CUS_CUSTOMER_PHONE_NO : "",                     // 고객정보_전화번호 1
      CUS_CUSTOMER_PHONE_NO_ENC : "",                 // 고객정보_전화번호 1 암호화
      CUS_WRC_TEL  : "",                               // 고객정보_전화번호 2
      CUS_WRC_TEL_NO  : "",				                    // 고객정보_전화번호 2
      CUS_WRC_TEL_NO_ENC : "",                        // 고객정보_전화번호 2 암호화

      CUS_CUSTOMER_GENDER: "",			                  // 고객정보_성별
      CUS_CUSTOMER_AGE: "",			                      // 고객정보_연령
      CUS_CUSTOMER_LOCAL: "",			                    // 고객정보_지역

      CUS_ATENT_CD : "",                              // 고객정보_주의고객코드 (B/L)
      OLD_CUS_ATENT_CD : "",                          // 고객정보_주의고객코드 (B/L)

      CUS_CNSL_EOT_DATE : "",                         // 고객정보_최근 상담일시
      CUS_CNSL_CNTN : "",                             // 고객정보_상담정보
      CUS_CUSTOMER_USER_MEMO : "",                    // 고객정보_특이사항
      CUS_CUSTOMER_MEMO : "",                         // 고객정보_내용
      OLD_CUS_CUSTOMER_MEMO : "",                     // 고객정보_내용

      CNSL_CHANNEL_TY_SCH : "",                       // 고객정보_고객상담이력_검색_채녈유형
      CNSL_PROC_CODE_LCLS_SCH : "",                   // 고객정보_고객상담이력_검색_상담결과

      CNSL_EOT_DATE_SCH: [                            // 고객정보_고객상담이력_상세검색_조회기간
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      ],

      CNSL_CHANNEL_TY_DTLSCH : "",		                // 고객정보_고객상담이력_상세검색_채널유형
      CNSL_PROC_CODE_LCLS_DTLSCH : "",                // 고객정보_고객상담이력_상세검색_상담결과
      CNSL_REG_MAN_DTLSCH : "",                       // 고객정보_고객상담이력_상세검색_상담사
      CNSL_CALL_TY_DTLSCH : "",                       // 고객정보_고객상담이력_상세검색_유형 (OB)

      /**
       * 콜_상담저장/고객정보 일자,시간
       */
      CUS_CUSTOMER_CS_TM: "",

      cnslResveDate: '',
      cnslResveTimeStep: "",
      cnslBookDate: '',
      cnslBookTimeStep: "",
      consBookDate: '',
      consBookTimeStep: "",
      operBookDate: '',
      operBookTimeStep: "",
      operationDate: '',
      operationTimeStep: "",
      visBookDate: '',
      visBookTimeStep: "",

      CNSL_CHN_1: "",
      CNSL_CHN_2: "",

      CNSL_CONT: "",
      VIS_PATH: "",
      DISA_ITEM: "",
      CNSL_BOOK: "",
      CONS_BOOK: "",
      VIS_BOOK: "",
      VIS_MANAGE: "",
      OPER_BOOK: "",
      OPER_MANAGE: "",
      OPERATION: "",
      VIS_TERM: "",
      CONDITION: "",
      CATEGORY: "",
      ETC: "",
      OPERATION_COST: "",

      MANAGE:"",
      MANAGE_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_CHN_1_LIST: [{
        text:'전체',
        value:''
      }],
      CNSL_CHN_2_LIST: [{
        text:'전체',
        value:''
      }],
      CNSL_CONT_LIST: [{
        text:'전체',
        value:''
      }],
      VIS_PATH_LIST: [{
        text:'전체',
        value:''
      }],
      DISA_ITEM_LIST: [{
        text:'전체',
        value:''
      }],
      CNSL_BOOK_LIST: [{
        text:'전체',
        value:''
      }],
      CONS_BOOK_LIST: [{
        text:'전체',
        value:''
      }],
      VIS_BOOK_LIST: [{
        text:'전체',
        value:''
      }],
      VIS_MANAGE_LIST: [{
        text:'전체',
        value:''
      }],
      OPER_BOOK_LIST: [{
        text:'전체',
        value:''
      }],
      OPER_MANAGE_LIST: [{
        text:'전체',
        value:''
      }],
      OPERATION_LIST: [{
        text:'전체',
        value:''
      }],
      VIS_TERM_LIST: [{
        text:'전체',
        value:''
      }],
      CONDITION_LIST: [{
        text:'전체',
        value:''
      }],
      CATEGORY_LIST: [{
        text:'전체',
        value:''
      }],
      ETC_LIST: [{
        text:'전체',
        value:''
      }],
      OPERATION_COST_LIST: [{
        text:'전체',
        value:''
      }],

      /**
       * 달력 이벤트
       */
      CUS_CS_TIME_CAL: false, // 상담희망시간
      cnslResveMenu: false, // 예약콜
      consBookMenu: false,
      cnslBookMenu: false,
      visBookMenu: false,
      operBookMenu: false,
      operationMenu: false,

      CNSL_BLNG: "IN",

      // 콜관련 회사별 공통코드 조회
      cmmnCdByCus: [
        "MTC001",
        "MTC002",
        "MTC003",
        "MTC004",
        "MTC005",
        "MTC006",
        "MTC007",
        "MTC008",
        "MTC009",
        "MTC010",
        "MTC011",
        "MTC012",
        "MTC013",
      ],
      cmmnCdLstByCus: [], // 콜관련공통코드_공통코드 조회 값

      chatYN: true,      //채팅가능여부

      CNSL_RESULT_LIST:[],
      CNSL_RESULT:'00001',

      //퓨처누리 변수
      CNSL_LV_1:'',
      CNSL_LV_2:'',
      CNSL_LV_3:'',
      CNSL_LV_4:'',
      CNSL_LV_1_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_LV_2_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_LV_3_LIST:[{
        text:'전체',
        value:''
      }],
      CNSL_LV_4_LIST:[{
        text:'전체',
        value:''
      }],

      RDOnly:false, //1개회사 셀렉트박스 고정
      CHK_ASP_NUM:'N',

      cmmnCdFUNU: [
        "FUNU001",
        "FUNU002",
        "FUNU003",
        "FUNU004",
        "FUNU005",
        "FUNU006",
        "FUNU007",
        "FUNU008",
        "FUNU009",
        "FUNU010",
      ],
      cmmnCdLstFUNU: [], // 콜관련공통코드_공통코드 조회 값

      /****************************************************************************************************************************************************
       * 콜 변수 / 함수 정보 END
       ***************************************************************************************************************************************************/

      /****************************************************************************************************************************************************
       * 공통 변수 / 함수 정보 START
       ***************************************************************************************************************************************************/

      nowTime: moment(), // 현재시간

      commonCode: [
        "TALK004",
        "TALK006",
        "TALK030_NOCACHE",
        "PLT002",
        "PLT024",
        "TALK004",
        "TALK037",
        "PLT001",
        "TALK005",
      ], //공통코드 PLT002_상담통합이력콜유형, PLT024_상담통합 상담결과
      commonCodeLst: [],

      baseSocketUrl: process.env.VUE_APP_SOCKET_URL, //소켓 연결 주소

      /****************************************************************************************************************************************************
       * 공통 변수 / 함수 정보 END
       ****************************************************************************************************************************************************
       ***************************************************************************************************************************************************/

      /****************************************************************************************************************************************************
       * 챗 변수 / 함수 정보 START
       ***************************************************************************************************************************************************/

      chatCustInfo: "", // 챗 고객정보
      dropItemsUserStatCd: "", //챗 상담사 상태값

      dropItemChatCnslChannel: "", //챗_상담이력_채널유형
      dropItemChatCnslProc: "", //챗_상담이력_상담유형
      dropItemDtlChatCnslChannel: "", //챗_상담이력_상세_채널유형
      dropItemDtlChatCnslProc: "", //챗_상담이력_상세_상담결과(상담유형)

      // 챗_상담이력_Arthur.Kim
      chatCnsl: [],

      isBtnPreChat: false, //이전대화내역버튼

      cnslSaveFrame: false, //상담 결과창 표시 여부 - true로 할 시 채팅이 종료되지 않아도 계속 보인다.
      msg: "",
      text: "",
      showSkipOnAlert: false,
      sysmessageSkip: "",
      talkStatCd: "",
      execRstCd: "",
      isChating: false, ///////// 여기가 채팅중여부 bws
      isDisable: false,
      talkContactId: this.$store.state.messages.talkContactInfo.talk_contact_id,

      // 엑셀다운로드 field
      excel_fields: {
        UUID: "TALK_SENDER_KEY_NM",
        대화자: "CUSTOMER_NM",
        메세지타입: "TYPE_NM",
        채팅내용: "CONTENT",
        시간: "IT_PROCESSING",
      },
      excel_data: [],
      excel_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      is_temp: false, // 상담이력 임시저장 여부
      result_cd: "", // 상담이력 처리결과 값
      talk_history_form_title: "", // 상담이력 저장 화면 title
      dialogTransfer: false, // 상담전달 다이얼로그 view
      dialogImage: false, // 이미지선택 다이얼로그 view
      linefeed: false, // 라인피드
      excelFileName: "", // telewebUtil.getTimeStampMilis() + "_" + jsonReq.getHeaderString("FILENAME") + ".xlsx";
      callTypCd: "", // 채널유형
      emoticon: false, // 이모티콘레이어

        /****************************************************************************************************************************************************
         * 챗 변수 / 함수 정보 END
         ****************************************************************************************************************************************************
         ***************************************************************************************************************************************************/

        /**
         * 220603 김태준 채팅 변수 추가
         */

        ASP_CUST_KEY: this.$store.getters["userStore/GE_USER_ROLE"].company,

        CLK_WAIT:0,

        CANT_MODI:true,
        CANT_END:false,
        CANT_CUST:false,

        RCT_CHAT_CON:'',
        RCT_CHAT_DATE:'',

        page: 1,
        pageCount: 30,
        itemsPerPage: 5,
        totalVisible: 10,
        gridScriptHeader: [
          {
            text: "NO",
            align: "center",
            width: "30px",
            value: "INDEX",
          },
          {
            text: "분류",
            align: "center",
            width: "50px",
            value: "LVL_NO_NM",
          },
          {
            text: "스크립트명",
            align: "center",
            width: "100px",
            value: "SCRIPT_TIT",
          },
        ],
        gridScriptBody: [],

        SCP_DETTY_NM:'',
        SCP_DET_TIT:'',
        SCP_DET_CON:'',



        EMAIL:'',
        USER_BL: [
        {
          title: "B/L",
          icon: "svg-dot-green",
          value: "N",
        },
        {
          title: "B/L",
          icon: "svg-dot-red",
          value: "Y",
        },
        {
          title: "B/L",
          icon: "svg-dot-orange",
          value: "D",
        },
      ],
      USER_NEWBL: "",

      CHAT_MSG: "",

      SRH_ASP_CUST: "",
      SRH_PHONE_NUM: "",
      SRH_CUST_NM: "",
      SRH_CUST_ID: "",

      //채팅 상세조회
      DET_SRC_dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],

      DET_SRC_CHENNEL: "",
      DET_SRC_RESULT: "",
      DET_SRC_AGENT_ID: "",
      DET_SRC_CHAT_RESULT: "",

      /**
       * 220603 김태준 채팅 변수 추가
       */

      //추가삭제 MERGE



    };
  },
  methods: {
    /****************************************************************************************************************************************************
     * 공통 methods START
     ***************************************************************************************************************************************************/

      // 모달 공통
      showDialog(type,chat) {

        let bol = true;

        if (type == "M110101P01") {

          let params = {ext:this.INLNE_NO,mode:'transfer'};

          $.get(this.CTI_TRANSFER_URL, params, function(req, data){

            if(req == null) {
              alert("호전환 리스트 조회 중 오류가 발생했습니다.");
            }else{
              eventBus.busCtiTransferlst(req);
            }
          }, 'json');

        } else if (type == "M110101P03") {                    // 발신

          if (this.CTI_LOGIN_BOOLEAN != true) {

            bol = false;
            alert("CTI 로그인 후 사용 가능합니다");

          } else if (this.CTI_DB_STATE != "handle") {

            bol = false;
            alert("후처리 상태에서 발신 해 주세요");

          } else {

            let cusCustomerPhoneNo = "";

            if (this.CTI_OB_CHK == true) {
              cusCustomerPhoneNo = "0000";
              this.CTI_SEND_CALL_PHONE   = "";                // 발신전화 번호
            }

            if (this.CTI_OB_CHK == false) {
              cusCustomerPhoneNo = this.CUS_CUSTOMER_PHONE_NO.replace(/-/g, '');
            }

            if (cusCustomerPhoneNo == "" || cusCustomerPhoneNo.length < 4) {

              bol = false;
              alert("발신전화를 확인해 주세요");

            } else {

              this.CTI_SEND_CALL.phone   = this.mixin_setPhoneNo(this.CUS_CUSTOMER_PHONE_NO.replace(/-/g, ''));
              this.CTI_SEND_ASP_CUST_KEY = this.CUS_ASP_CUST_KEY;
              this.CTI_SEND_ASP_CUST_KEY = (typeof this.CUS_ASP_CUST_KEY == undefined || typeof this.CUS_ASP_CUST_KEY == "undefined" || this.CUS_ASP_CUST_KEY == "") ? this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD : this.CUS_ASP_CUST_KEY;
              this.CTI_SEND_CALL_PHONE   = this.CTI_SEND_CALL.phone;

              this.CTI_TAKE_CUST_TEL     = "";
              this.TAKE_CUST_TEL         = [];

              let i = 0;
              let x = 0;
              let takeCustTelLen = this.TAKE_CUST_TEL_DROPITEMS.length;
              let cusAspCustKey  = "";

              if (typeof this.CUS_ASP_CUST_KEY == undefined || typeof this.CUS_ASP_CUST_KEY == "undefined" || this.CUS_ASP_CUST_KEY == "") {
              } else {
                cusAspCustKey = this.CUS_ASP_CUST_KEY;
              }

              if (cusAspCustKey != "") {

                for(; i < takeCustTelLen; i++) {

                  let txt    = this.TAKE_CUST_TEL_DROPITEMS[i].text;             // 상세코드명
                  let cd     = this.TAKE_CUST_TEL_DROPITEMS[i].value;            // 상세코드
                  let etcCd1 = this.TAKE_CUST_TEL_DROPITEMS[i].info1;            // 기타코드 1

                  if (etcCd1 == cusAspCustKey) {
                    this.TAKE_CUST_TEL[x] = this.TAKE_CUST_TEL_DROPITEMS[i];
                    ++x;
                  }
                }

                this.CTI_TAKE_CUST_TEL = cusAspCustKey;
              }
            }
          }
        } else if (type == "MP01") {

          if(chat != 'chat'){
            if (this.SCH_ASP_CUST_KEY.length == 0) {
              bol = false;
              alert("업체코드를 선택하세요");
            }
          } else {
            if (this.SRH_ASP_CUST.length == 0) {
              bol = false;
              alert("업체코드를 선택하세요");
            }
          }

          if(type == 'MP01'){
            if(this.CHK_ASP_NUM == 'Y'){
              this.CUS_ASP_CUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
              this.RDOnly = true;
            } else {
              this.RDOnly = false;
            }
          }

        }

        if (bol) {
          this[`dialog${type}`] = true;
        }
      },

      /**
       * 모달창 숨기기
       */
      hideDialog(type) {
        if (type == "M110101P03" || type == "M110101P01") {
          this.btnClassRemove();

          if (type == "M110101P03") {
            this.citHandleAuto();             // CTI 후처리 상태값 표시
          }
        }

        if(type == 'MP01'){
          if(this.CHK_ASP_NUM == 'Y'){
            this.CUS_ASP_CUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
            this.RDOnly = true;
          } else {
            this.RDOnly = false;
          }
        }
        this[`dialog${type}`] = false;
      },

      /** 새로고침 방지  */
      setAvailableRefresh(e) {

        if(e.key === 116){

          if(typeof(event) == "object"){
            event.key = 0;
          }

          return false;

        } else if(e.key === 82 && e.ctrl) {
          return false;
        } else if(e.key === 8) {

          if(e.target.nodeName === "INPUT" || e.target.nodeName === "TEXTAREA"){

            if(typeof($(e.target).attr("readonly")) !== "undefined" || $(e.target).hasClass("twbDisabled") || $(e.target).is(":disabled")){
              return false;
            }else{
              return true;
            }

          } else {
            return false;
          }
        }
      },

      /** 경고메시지 */
    showAlertWarn(e) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: e,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },
    /** 완료 메세지 */
    showAlertComplete(e) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: e,
        iconClass: "svg-done-lg",
        type: "default",
      });
    },

    /** Null 체크  */
    isNull(e) {
      if (e == "" || e == null || e == undefined) return true;
      else return false;
    },

    /** ASP CUST KEY 추출 */
    getAspCustKey() {
      let aspCustKey = "";
      let i = 0;
      let len = this.$store.getters["userStore/GE_USER_ROLE"].company.length;

      for (; i < len; i++) {
        if (i == 0) {
          aspCustKey =
            this.$store.getters["userStore/GE_USER_ROLE"].company[i].CD;
        } else {
          aspCustKey =
            aspCustKey +
            "," +
            this.$store.getters["userStore/GE_USER_ROLE"].company[i].CD;
        }
      }

      return aspCustKey;
    },

    /**
     * 상담유형코드 조회
     * gbn : 구분 (call / chat)
     * aspCustKey : 인입 고객사 키
     * lvl : 상담유형코드 단계 (1 ~ 3)
     * cnslTypCd : 선택 상담유형 코드
     */
    getCmmnCnslTypCd(gbn, aspCustKey, lvl, cnslTypCd) {
      api
        .post(
          "api/setting/consulttype/manage/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: aspCustKey,
            SPST_CNSL_TYP_CD: cnslTypCd,
            USE_YN: "Y",
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "setting.consulttype.manage",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          if (lvl == "1") {
            if (gbn == this.CNSL_WK[0]) {
              this.CALL_CNSL_TYP_CD = [
                ...this.CALL_CNSL_TYP_CD,
                ...response.data.DATA,
              ];
            } else if (gbn == this.CNSL_WK[1]) {
              this.CNSL_TY1 = [...this.CNSL_TY1, ...response.data.DATA];
            }
          } else if (lvl == "2") {
            if (gbn == this.CNSL_WK[0]) {
              this.CALL_CNSL_TYP_CD_2 = [
                ...this.CALL_CNSL_TYP_CD_2,
                ...response.data.DATA,
              ];
            } else if (gbn == this.CNSL_WK[1]) {
              this.CNSL_TY2 = [...this.CNSL_TY2, ...response.data.DATA];
            }
          } else if (lvl == "3") {
            if (gbn == this.CNSL_WK[0]) {
              this.CALL_CNSL_TYP_CD_3 = [
                ...this.CALL_CNSL_TYP_CD_3,
                ...response.data.DATA,
              ];
            } else if (gbn == this.CNSL_WK[1]) {
              this.CNSL_TY3 = [...this.CNSL_TY3, ...response.data.DATA];
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

      /**
       * 시간 형식을 만들어주는 함수
       * return : strinmg (00:00)형식;
       */
      getHHMISS(callTime) {

        let rtnTime = "";
        let intHH = 0;				                        // 시
        let intMI = 0;				                        // 분
        let intSS = 0;				                        // 초

        if (callTime == 0 || callTime == "") {        // callTime 아무것도 없을시 예외처리
          return rtnTime = "00:00:00";
        } else {

          /** callTime 60보다 작으면 초 */
          if (callTime < 60){
            intSS =  callTime;
          } else {

            /** 60보다 크면 (초/60) = 분  */
            intMI = parseInt(callTime / 60);
            intSS = callTime % 60;

            /** 60보다 크면 (분/60) = 시 */
            if (intMI > 60) {
              intHH = parseInt(intMI / 60);
              intMI = intMI % 60;
            }
          }

          /** 10보다 작으면 앞에 0을 추가해서 형식을 맞추어준다. */
          if (intHH < 10) { intHH = "0" + intHH};
          if (intMI < 10) { intMI = "0" + intMI};
          if (intSS < 10) { intSS = "0" + intSS};

          /** 시:분:초 형식을 위해서  : 추가한다. */
          rtnTime = intHH + ":" + intMI + ":" + intSS;

          return rtnTime;
        }
      },

      /**
       * 현재날짜 시간 추출
       * e : YYYYMMDD 년월일, HH24MISS 시간분초
       * arg : 년월일,시간분초 생성 각 구분값
       */
      getSverData(e, arg) {

        let today       = new Date();
        let year        = today.getFullYear() + "";
        let month       = ("0" + (today.getMonth() + 1)).slice(-2) + "";
        let day         = ("0" + today.getDate()).slice(-2) + "";
        let dateString  = "";                                             //year + "-" + month + "-" + day;

        let hours       = ("0" + today.getHours()).slice(-2) + "";
        let minutes     = ("0" + today.getMinutes()).slice(-2) + "";
        let seconds     = ("0" + today.getSeconds()).slice(-2) + "";
        //let timeString  = hours + ":" + minutes + ":" + seconds;

        if (e == "YYYYMMDD") {
          dateString  = year + arg + month + arg + day + "";
        } else if (e == "HH24MISS") {
          dateString  = hours + arg + minutes + arg + seconds + "";
        }

        return dateString;
      },

      /**
       * XSS Encode
       */
      encode(strText) {

        if (strText === "" || strText == null) {
          return strText;
        }

        //strText = strText.toString();

        strText = strText.replaceAll("(", "&#40;");
        strText = strText.replaceAll(")", "&#41;");
        strText = strText.replaceAll("[", "&#91;");
        strText = strText.replaceAll("]", "&#93;");
        strText = strText.replaceAll("&", "&amp;");
        strText = strText.replaceAll('"', "&quot;");
        strText = strText.replaceAll("'", "&apos;");

        strText = strText.replaceAll("\r\n", "<br>");
        strText = strText.replaceAll("\r", "<br>");
        strText = strText.replaceAll("\n", "<p>");

        strText = strText.replaceAll("<", "&lt;");
        strText = strText.replaceAll(">", "&gt;");


        return strText;
    },

    /**
     * XSS Encode 엔터키
     */
    encodeEnter(strText) {

        if (strText === "" || strText == null) {
          return strText;
        }

        //strText = strText.toString();

        strText = strText.replaceAll("\r\n", "<br>");
        strText = strText.replaceAll("\r", "<br>");
        strText = strText.replaceAll("\n", "<br>");

        return strText;
    },

    /**
     * XSS Decode
     */
    decode(strText) {

      //값존재유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }
      strText = strText.toString();

      //문자열 길이가 4이상일 경우만 처리
      if (strText.length <= 3) {
        return strText;
      }

      strText = strText.replaceAll("&lt;", "<");
      strText = strText.replaceAll("&gt;", ">");
      strText = strText.replaceAll("&#40;", "(");
      strText = strText.replaceAll("&#41;", ")");
      strText = strText.replaceAll("&#91;", "[");
      strText = strText.replaceAll("&#93;", "]");
      strText = strText.replaceAll("&amp;", "&");
      strText = strText.replaceAll("&quot;", '"');
      strText = strText.replaceAll("&apos;", "'");
      strText = strText.replaceAll("<br>", "\r");
      strText = strText.replaceAll("<p>", "\n");

      return strText;
    },

    /****************************************************************************************************************************************************
     * 공통 methods END
     ***************************************************************************************************************************************************/

    /****************************************************************************************************************************************************
     * 콜 methods START
     ***************************************************************************************************************************************************/

      /**
       * 콜_상담이력_상세날짜
       */
      onchgCnslEotDateStDt(e) {
	      this.CNSL_EOT_DATE_SCH[0] = e;
	    },

      /**
       * 콜_상담이력_상세날짜
       */
	    onchgCnslEotDateEdDt(e) {
	      this.CNSL_EOT_DATE_SCH[1] = e;
	    },

      /** ASP CUST KEY에 따른 OB 전용 활성화 */
      setAspCustKeyCtiOb() {

        let aspCustKey = "";
        let i = 0;
        let len = this.$store.getters["userStore/GE_USER_ROLE"].company.length;

        for (; i < len; i++) {

          aspCustKey = this.$store.getters["userStore/GE_USER_ROLE"].company[i].CD;

          if (aspCustKey == "DIET" || aspCustKey == "META" ||  aspCustKey == "MOMO" || aspCustKey == "LITING" || aspCustKey == "PLAN") {
            this.CTI_OB_DISABLE = true;
            break;
          }
        }
      },

      /**
       * 고객저장
       */
      setCusInfo() {

        let atentNewCustomer    = "";                                                                                 // B/L여부

        let cusCustomerUserMemo   = "";                                                                               // 고객특이사항
        let cusCustomerMemo       = "";                                                                               // 내용
        let tmpCustomerMemo       = "";

        let cusCusomerCsTime      = "";                                                                               // 상담희망시간

        if (this.CUS_ATENT_CD.length > 0) {

          if (this.CUS_ATENT_CD != this.OLD_CUS_ATENT_CD) {
            atentNewCustomer = "Y";
          }
        }

        /** 고객특이사항 */
        if (this.CUS_CUSTOMER_USER_MEMO.length > 0) {
          cusCustomerUserMemo = this.CUS_CUSTOMER_USER_MEMO;
        }

        /** 내용 */
        if (this.CUS_CUSTOMER_MEMO.length > 0) {
          cusCustomerMemo = this.CUS_CUSTOMER_MEMO;
        }

        if (typeof this.CUS_CUSTOMER_CS_TM == undefined || typeof this.CUS_CUSTOMER_CS_TM == "undefined" || this.CUS_CUSTOMER_CS_TM == "" || this.CUS_CUSTOMER_CS_TM == "00:00") {
          cusCusomerCsTime = "";
        } else {
          cusCusomerCsTime = this.CUS_CUSTOMER_CS_TM;
        }

        cusCustomerUserMemo = this.encode(this.encodeEnter(cusCustomerUserMemo));                                     // 고객특이사항
        cusCustomerMemo     = this.encode(this.encodeEnter(cusCustomerMemo));                                         // 내용

        if (cusCustomerMemo != this.OLD_CUS_CUSTOMER_MEMO) {
          tmpCustomerMemo = cusCustomerMemo;
        }

        /**
         * 저장/수정 고객정보
         */
        this.CUS_INFO_WRITE = this.CUS_ASP_CUST_KEY                                                                   // 고객정보_업체코드
                              + this.CUS_CUSTOMER_ID                                                                  // 고객정보_고객 ID
                              + this.CUS_CUSTOMER_NM                                                                  // 고객정보_고객명
                              + this.CUS_CUSTOMER_SIDE_NM                                                             // 고객정보_동행자
                              + this.CUS_TALK_USER_KEY                                                                // 고객정보_Key TALK_사용자_키
                              + this.CUS_CUSTOMER_PHONE_NO.replace(/-/g, '')                                          // 고객정보_전화번호 1
                              + this.CUS_WRC_TEL_NO.replace(/-/g, '')				                                          // 고객정보_전화번호 2
                              + this.CUS_CUSTOMER_GENDER			                                                        // 고객정보_성별
                              + this.CUS_CUSTOMER_AGE			                                                            // 고객정보_연령
                              + this.CUS_CUSTOMER_LOCAL		                                                            // 고객정보_지역
                              + cusCusomerCsTime                                                                      // 고객정보_상담희망시간
                              + this.CUS_ATENT_CD                                                                     // 고객정보_주의고객코드 (B/L)
                              + cusCustomerUserMemo                                                                   // 고객정보_특이사항
                              + cusCustomerMemo;                                                                      // 고객정보_내용

        if (this.CUS_INFO_SCH == this.CUS_INFO_WRITE) {
        } else {

          api.post("api/phone/main/cstmr-info/insert", {                                                              // api url입력
              ASP_NEWCUST_KEY     : this.CUS_ASP_CUST_KEY,
              CUSTOMER_ID         : this.CUS_CUSTOMER_ID,                                                             // 고객ID
              CUSTOMER_PHONE_NO   : this.CUS_CUSTOMER_PHONE_NO.replace(/-/g, ''),                                     // 고객전화번호
              CUSTOMER_NM         : this.CUS_CUSTOMER_NM,                                                             // 고객명
              CUSTOMER_SIDE_NM    : this.CUS_CUSTOMER_SIDE_NM,                                                        // 동행
              WRC_TEL_NO          : this.CUS_WRC_TEL_NO.replace(/-/g, ''),                                            // 전화번호 2
              GENDER_CD           : this.CUS_CUSTOMER_GENDER,                                                         // 성별
              CUSTOMER_AGE        : this.CUS_CUSTOMER_AGE,                                                            // 연령
              CUSTOMER_LOCAL      : this.CUS_CUSTOMER_LOCAL,                                                          // 지역
              CUSTOMER_CS_TIME    : cusCusomerCsTime,                                                                 // 상담희망시간
              ATENT_CD            : this.CUS_ATENT_CD,                                                                // 주의고객
              ATENT_NEWCUSTOMER   : atentNewCustomer,                                                                 // 등급
              CUSTOMER_USER_MEMO  : cusCustomerUserMemo,                                                              // 고객특이사항 this.restoreXSS_Dec(this.restoreXSS_CKeditorDec(this.CUS_CUSTOMER_USER_MEMO))
              CUSTOMER_MEMO       : tmpCustomerMemo,                                                                  // 내용 this.restoreXSS_Dec(this.restoreXSS_CKeditorDec(customerUserMemo))
              PROC_ID             : this.$store.getters["userStore/GE_USER_ROLE"].userId,
              ASP_USER_ID         : this.$store.getters["userStore/GE_USER_ROLE"].userId,
              ASP_CUST_KEY        : this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD
            }, {
              head: {
                SERVICE: "phone.main.cstmr-info",
                METHOD: "insert",
                TYPE: "BIZ_SERVICE",
              },
            }
          ).then((response) => {

            /**
             * 로직 변경.
            if (this.CUS_CUSTOMER_ID == "" || this.CUS_CUSTOMER_ID == "undefined") {

              if (this.SCH_ASP_CUST_KEY == "") {
                this.SCH_ASP_CUST_KEY = this.CUS_ASP_CUST_KEY;
                this.SCH_CUSTOMER_PHONE_NO = this.CUS_CUSTOMER_PHONE_NO.replace(/-/g, '');
              }

              this.getCusInfo();
            }
            */
            this.SCH_ASP_CUST_KEY = this.CUS_ASP_CUST_KEY;
            this.SCH_CUSTOMER_PHONE_NO = this.mixin_setPhoneNo(this.CUS_CUSTOMER_PHONE_NO.replace(/-/g, ''));

            this.getCusInfo();

          }).catch((err) => {
            alert("고객정보 저장 오류 발생 (" + err + ")");
          });

        }
      },

      /**
       * 캠페인 통화시도 횟수 증가
       */
      setCampainTryCnt() {

         api.post("api/phone/main/cnt-obndcall/modify",{
          CSLT_ID         : this.$store.getters['userStore/GE_USER_ROLE'].userId,
          CUST_NO         : this.CALL_OUT_BOUND.CUST_NO,
          CAM_ID          : this.CALL_OUT_BOUND.CAM_ID,
          CHNG_MAN        : this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_USER_ID     : this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY    : this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
          ASP_NEWCUST_KEY : this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD
        }, {head: {
          "SERVICE" : "phone.main.cnt-obndcall",
          "METHOD" : "modify",
          "TYPE" : "BIZ_SERVICE",
        }}).then((response) => {
          //
        }).catch((err) => {
          alert("캠페인 통화시도 횟수 증가 오류 발생 (" + err + ")");
        })
      },

      /**
       * 콜백 통화시도 횟수 증가
       */
      setCallBackTryCnt() {

         api.post("api/phone/main/callback-manage-cnt/modify",{
          CLBK_NO         : this.CALL_CALL_BACK.CLBK_NO,
          CHNG_MAN        : this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_USER_ID     : this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY    : this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
          ASP_NEWCUST_KEY : this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD
        }, {head: {
          "SERVICE" : "phone.main.callback-manage-cnt",
          "METHOD" : "modify",
          "TYPE" : "BIZ_SERVICE",
        }}).then((response) => {
          //
        }).catch((err) => {
          alert("콜백 통화시도 횟수 증가 오류 발생 (" + err + ")");
        })
      },

      /**
       * 재통화 통화시도 횟수 증가
       */
      setResvCallTryCnt() {

         api.post("api/phone/main/resvcall-manage-cnt/modify",{
          RESV_SEQ        : this.CALL_RESV_CALL.RESV_SEQ,
          CHNG_MAN        : this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_USER_ID     : this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY    : this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
          ASP_NEWCUST_KEY : this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD
        }, {head: {
          "SERVICE" : "phone.main.resvcall-manage-cnt",
          "METHOD" : "modify",
          "TYPE" : "BIZ_SERVICE",
        }}).then((response) => {
          //
        }).catch((err) => {
          alert("콜백 통화시도 횟수 증가 오류 발생 (" + err + ")");
        })
      },

      /**
       * 고객조회
       */
      getCusInfo() {

        api.post("api/setting/customer/info-list/cstmrinfo-inqire/inqire",{
          ASP_NEWCUST_KEY   : this.SCH_ASP_CUST_KEY,                                                                        // this.CTI_INFO.strAspCustKey,
          CUSTOMER_PHONE_NO : this.SCH_CUSTOMER_PHONE_NO.replace(/-/g, ''),                                                 // this.SCH_CUSTOMER_PHONE_NO, this.UEI_INFO.strInboundNum, "01037013667",
          CUSTOMER_ID       : this.SCH_CUSTOMER_ID,
          CAM_ID            : this.CALL_OUT_BOUND.CAM_ID,
          ASP_USER_ID       : this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY      : this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        }, {head: {
          "SERVICE" : "setting.customer.info-list.cstmrinfo-inqire",
          "METHOD" : "inqire",
          "TYPE" : "BIZ_SERVICE",
        }}).then((response) => {

          let dataLen = 0;

          if(typeof response != undefined || typeof response != "undefined") {

            if(typeof response.data.DATA != undefined || typeof response.data.DATA != "undefined") {
              dataLen = response.data.DATA.length;
            }

            if (dataLen == 0) {

              if (typeof this.CTI_INFO.strAspCustKey != undefined && typeof this.CTI_INFO.strAspCustKey != "undefined" && this.CTI_INFO.strCallMode === 'inbound') {

                this.CUS_ASP_CUST_KEY          = this.CTI_INFO.strAspCustKey;                                                   // 고객정보_업체코드
                //this.CUS_ASP_CUST_NM           = this.CTI_TAKE_CALL.company;                                                  // 고객정보_업체명
                this.CUS_CUSTOMER_PHONE_NO     = this.mixin_setPhoneNo(this.UEI_INFO.strInboundNum);                            // 고객정보_전화번호 1
                this.CUS_CUSTOMER_PHONE_NO_ENC = this.mixin_mask_num(this.UEI_INFO.strInboundNum);                              // 고객정보_전화번호 1 암호화

              } else if (typeof this.CALL_CALL_BACK.CLBK_NO != "undefined" && this.CALL_CALL_BACK.CLBK_NO.length > 0) {

                this.CUS_CUSTOMER_PHONE_NO     = this.mixin_setPhoneNo(this.SCH_CUSTOMER_PHONE_NO.replace(/-/g, ''));           // 고객정보_전화번호 1
                this.CUS_CUSTOMER_PHONE_NO_ENC = this.mixin_mask_num(this.SCH_CUSTOMER_PHONE_NO.replace(/-/g, ''));             // 고객정보_전화번호 1 암호화
              }

              this.CUS_PHONE_NO  = this.CUS_CUSTOMER_PHONE_NO;

            } else if (dataLen == 1) {

              this.CUS_ASP_CUST_KEY = response.data.DATA[0].ASP_CUST_KEY;                                                       // 고객정보_업체코드
              this.CUS_CUSTOMER_ID = response.data.DATA[0].CUSTOMER_ID;                                                         // 고객정보_고객 ID
              //this.CUS_ASP_CUST_NM = response.data.DATA[0].COMPANY_NM;	                                                      // 고객정보_업체명
              this.CUS_CUSTOMER_NM = response.data.DATA[0].CUSTOMER_NM;	                                                        // 고객정보_고객명
              this.CUS_CUSTOMER_SIDE_NM = response.data.DATA[0].CUSTOMER_SIDE_NM;	                                              // 고객정보_동행자
              this.CUS_TALK_USER_KEY = response.data.DATA[0].TALK_USER_KEY;                                                     // 고객정보_Key TALK_사용자_키

              this.CUS_PHONE_NO = response.data.DATA[0].CUSTOMER_PHONE_NO.replace(/-/g, '');                                    // 고객정보_전화번호 1
              this.CUS_CUSTOMER_PHONE_NO = this.mixin_setPhoneNo(response.data.DATA[0].CUSTOMER_PHONE_NO.replace(/-/g, ''));    // 고객정보_전화번호 1
              this.CUS_CUSTOMER_PHONE_NO_ENC = this.mixin_mask_num(response.data.DATA[0].CUSTOMER_PHONE_NO.replace(/-/g, ''));  // 고객정보_전화번호 1 암호화
              this.CUS_WRC_TEL = response.data.DATA[0].WRC_TEL_NO.replace(/-/g, '');				                                    // 고객정보_전화번호 2
              this.CUS_WRC_TEL_NO  = this.mixin_setPhoneNo(response.data.DATA[0].WRC_TEL_NO.replace(/-/g, ''));				          // 고객정보_전화번호 2
              this.CUS_WRC_TEL_NO_ENC = this.mixin_mask_num(response.data.DATA[0].WRC_TEL_NO.replace(/-/g, ''));                // 고객정보_전화번호 2 암호화

              this.CUS_CUSTOMER_GENDER = response.data.DATA[0].GENDER_CD;			                                                  // 고객정보_성별
              this.CUS_CUSTOMER_AGE = response.data.DATA[0].CUSTOMER_AGE;			                                                  // 고객정보_연령
              this.CUS_CUSTOMER_LOCAL = response.data.DATA[0].CUSTOMER_LOCAL;			                                              // 고객정보_지역

              this.CUS_CUSTOMER_CS_TM = response.data.DATA[0].CUSTOMER_CS_TIME;

              this.CUS_ATENT_CD = response.data.DATA[0].ATENT_CD;                                                               // 고객정보_주의고객코드 (B/L)
              this.OLD_CUS_ATENT_CD = response.data.DATA[0].ATENT_CD;                                                           // 고객정보_주의고객코드 (B/L)
              this.CUS_CNSL_EOT_DATE = response.data.DATA[0].CNSL_DATE;                                                         // 고객정보_최근 상담일시
              this.CUS_CNSL_CNTN = "";                                                                                          // 고객정보_상담정보
              this.CUS_CUSTOMER_USER_MEMO = this.restoreXSS(response.data.DATA[0].CUSTOMER_USER_MEMO);                          // 고객정보_특이사항
              this.CUS_CUSTOMER_MEMO = this.restoreXSS(response.data.DATA[0].CUSTOMER_MEMO);                                    // 고객정보_내용
              this.OLD_CUS_CUSTOMER_MEMO = this.restoreXSS(response.data.DATA[0].CUSTOMER_MEMO);                                // 고객정보_내용

            } else if (dataLen > 1) {

              this.btnCust(this.CNSL_WK[0]);
              this.showDialog('MP01');

            }

            if (dataLen == 0 || dataLen == 1) {

              if (typeof this.CTI_INFO.strAspCustKey != undefined && typeof this.CTI_INFO.strAspCustKey != "undefined" && this.CTI_INFO.strCallMode === 'inbound') {
                this.CTI_CALL_STATUS.name = this.CUS_CUSTOMER_NM;                                                               // 고객상단_고객명 세팅
              }

              /** 고객조회정보 */
              this.CUS_INFO_SCH = this.CUS_ASP_CUST_KEY                                                                         // 고객정보_업체코드
                                  + this.CUS_CUSTOMER_ID                                                                        // 고객정보_고객 ID
                                  + this.CUS_CUSTOMER_NM                                                                        // 고객정보_고객명
                                  + this.CUS_CUSTOMER_SIDE_NM                                                                   // 고객정보_동행자
                                  + this.CUS_TALK_USER_KEY                                                                      // 고객정보_Key TALK_사용자_키
                                  + this.CUS_CUSTOMER_PHONE_NO.replace(/-/g, '')                                                // 고객정보_전화번호 1
                                  + this.CUS_WRC_TEL_NO.replace(/-/g, '')				                                                // 고객정보_전화번호 2
                                  + this.CUS_CUSTOMER_GENDER			                                                              // 고객정보_성별
                                  + this.CUS_CUSTOMER_AGE			                                                                  // 고객정보_연령
                                  + this.CUS_CUSTOMER_LOCAL		                                                                  // 고객정보_지역
                                  + this.CUS_CUSTOMER_CS_TM                                                                     // 고객정보_상담희망시간
                                  + this.CUS_ATENT_CD;                                                                          // 고객정보_내용
              if (dataLen == 1) {
                this.CUS_INFO_SCH =  this.CUS_INFO_SCH
                                     + response.data.DATA[0].CUSTOMER_USER_MEMO                                                 // 고객정보_특이사항
                                     + response.data.DATA[0].CUSTOMER_MEMO;                                                     // 고객정보_내용
              } else {
                this.CUS_INFO_SCH =  this.CUS_INFO_SCH
                                     + this.CUS_CUSTOMER_USER_MEMO                                                              // 고객정보_특이사항
                                     + this.CUS_CUSTOMER_MEMO;                                                                  // 고객정보_내용
              }

              this.CUS_INPUT_DISABLED = false,                                                                                  // 고객정보 수정/저장 버튼_Disabled
              this.CUS_PHONE_DISABLED = false,                                                                                  // 고객정보_전화번호1 걸기/복사하기

              this.CUS_INPUT_DISABLED = false;                                                                                  // 고객수정/저장 버튼 활성화
              this.btnCusDisabled();

              this.getPhnCnsl("","","","call");                                                                                 // 상담이력 조회
            }

          }
        }).catch((err) => {
          alert("고객조회 오류 발생 (" + err + ")");
        })
      },

      /**
       * 상담이력
       */
      getPhnCnsl(n, tf, arg, gbn) {

        let detSrc 	= '';
        let stDate 	= (gbn == "chat") ? this.DET_SRC_dates[0].replace(/-/g, '/') : this.CNSL_EOT_DATE_SCH[0].replace(/-/g, '/');
        let edDate  = (gbn == "chat") ? this.DET_SRC_dates[1].replace(/-/g, '/') : this.CNSL_EOT_DATE_SCH[1].replace(/-/g, '/');

        if (gbn == "call") {
          if (this.CUS_ASP_CUST_KEY == "" || this.CUS_ASP_CUST_KEY == "undefined"
             || this.CUS_CUSTOMER_PHONE_NO == "" || this.CUS_CUSTOMER_PHONE_NO == "undefined") {
            return false;
          }
        }

        detSrc = (arg == 'detail') ? 'Y' : "";

        apiChat.post("api/chat/main/cnslt-hist/inqire", {   //api url입력
          ASP_NEWCUST_KEY : (gbn == "chat") ? this.CLK_CUST : this.CUS_ASP_CUST_KEY,
          TALK_USER_KEY   : (gbn == "chat") ? this.CLK_USER : "",
          CSTMR_ID        : (gbn == "call") ? this.CUS_CUSTOMER_ID : this.CHAT_CUS_ID,
          CALL_TY         : (gbn == "call") ? ((arg == 'detail') ? this.CNSL_CHANNEL_TY_DTLSCH : this.CNSL_CHANNEL_TY_SCH) :  ((arg == 'detail') ? this.DET_SRC_CHENNEL : ""),
          CSTMR_TELNO     : (gbn == "call") ? this.CUS_CUSTOMER_PHONE_NO.replace(/-/g, '') : "",
          CNSL_DIV        :  "",
          CSTMR_TELNO     : "",
          ASP_USER_ID     : this.$store.getters["userStore/GE_USER_ROLE"].userId,
          ASP_CUST_KEY    : (gbn == "chat") ? this.CUST : this.fullCust(),
          USER_ID         : this.$store.getters["userStore/GE_USER_ROLE"].userId,
          action          : "api/chat/main/cnslt-hist/inqire",
          method          : "post",
          DET_SRC         : detSrc,
          ST_DATE         : stDate,
          END_DATE        : edDate,
          RESULT          : (gbn == "chat") ? this.DET_SRC_RESULT      : this.CNSL_CALL_TY_DTLSCH,
          AGENT_ID        : (gbn == "chat") ? this.DET_SRC_AGENT_ID    : this.CNSL_REG_MAN_DTLSCH,
          //CHENNEL         : (gbn == "chat") ? this.DET_SRC_CHENNEL     : this.DET_SRC_CHENNEL,
          CHAT_RESULT     : (gbn == "chat") ? this.DET_SRC_CHAT_RESULT : this.CNSL_CALL_TY_DTLSCH,
        }, { head: {}
        }).then((response) => {

          //console.log("상담이력<<<<>>>>", response.data.data);

          let i       = 0;
          let dataLen = response.data.data.length;
          let LIST    = (gbn == "chat") ? this.chatHistory : this.CALL_PHN_CNSL_HISTORY;

          if (gbn == "chat") {
            this.chatHistory = response.data.data;
          } else if (gbn == "call") {
            this.CALL_PHN_CNSL_HISTORY = response.data.data;
          }

          if(dataLen > 0){
            if (gbn == "chat") {
              this.RCT_CHAT_DATE = response.data.data[0].REG_DTTM;
              this.RCT_CHAT_CON = this.decode(response.data.data[0].HST_CONTEXT);
            } else if (gbn == "call") {
              this.CUS_CNSL_EOT_DATE = response.data.data[0].REG_DTTM;            // 최근상담일시
              this.CUS_CNSL_CNTN = this.decode(response.data.data[0].HST_CONTEXT);             // 최근상담내용
            }
          }

          for(; i < dataLen; i++){

            if (n == '' || n == null) {

              if (gbn == "chat") {
                this.chatHistory[i]["EXPAND"] = false;
              } else if (gbn == "call") {
                this.CALL_PHN_CNSL_HISTORY[i]["EXPAND"] = false;
              }

            } else {
              if (gbn == "chat") {
                this.chatHistory[i]["EXPAND"] = LIST[i].EXPAND;
                this.chatHistory[n-1]["EXPAND"] = tf;
              } else if (gbn == "call") {
                this.CALL_PHN_CNSL_HISTORY[i]["EXPAND"] = LIST[i].EXPAND;
                this.CALL_PHN_CNSL_HISTORY[n-1]["EXPAND"] = tf;
              }
            }

            if (gbn == "chat") {
              this.chatHistory[i]["ID"] = i+1;
            } else if (gbn == "call") {
              this.CALL_PHN_CNSL_HISTORY[i]["ID"] = i+1;
            }

            if (gbn == "chat") {
              if(response.data.data[i].HST_TYPE == 'kakao'){
                this.chatHistory[i]["ICON"] = 'svg-chat-icon-kakao';
              } else if(response.data.data[i].HST_TYPE == 'line'){
                this.chatHistory[i]["ICON"] = 'svg-chat-icon-line';
              } else if(response.data.data[i].HST_TYPE == 'wechat'){
                this.chatHistory[i]["ICON"] = 'svg-chat-icon-wechat';
              } else if(response.data.data[i].HST_TYPE == 'ttalk'){
                this.chatHistory[i]["ICON"] = 'svg-chat-icon-ttalk';
              } else if(response.data.data[i].HST_TYPE == 'inbound'){
                this.chatHistory[i]["ICON"] = 'svg-chatmain-calltype01';
              } else if(response.data.data[i].HST_TYPE == 'outbound'){
                this.chatHistory[i]["ICON"] = 'svg-chatmain-calltype02';
              }
            } else if (gbn == "call") {
              if(response.data.data[i].HST_TYPE == 'kakao'){
                this.CALL_PHN_CNSL_HISTORY[i]["ICON"] = 'svg-chat-icon-kakao';
              } else if(response.data.data[i].HST_TYPE == 'line'){
                this.CALL_PHN_CNSL_HISTORY[i]["ICON"] = 'svg-chat-icon-line';
              } else if(response.data.data[i].HST_TYPE == 'wechat'){
                this.CALL_PHN_CNSL_HISTORY[i]["ICON"] = 'svg-chat-icon-wechat';
              } else if(response.data.data[i].HST_TYPE == 'ttalk'){
                this.CALL_PHN_CNSL_HISTORY[i]["ICON"] = 'svg-chat-icon-ttalk';
              } else if(response.data.data[i].HST_TYPE == 'inbound'){
                this.CALL_PHN_CNSL_HISTORY[i]["ICON"] = 'svg-chatmain-calltype01';
              } else if(response.data.data[i].HST_TYPE == 'outbound'){
                this.CALL_PHN_CNSL_HISTORY[i]["ICON"] = 'svg-chatmain-calltype02';
              }
            }

            if (gbn == "chat") {
              if(this.chatHistory[i]["PROC_CODE_NM"] == '완결' || this.chatHistory[i]["PROC_CODE_NM"] == '전달'){
                this.chatHistory[i]["PROC_CODE"] = 'done';
              } else if(this.chatHistory[i]["PROC_CODE_NM"] == '미결'){
                this.chatHistory[i]["PROC_CODE"] = 'undone';
              } else if(this.chatHistory[i]["PROC_CODE_NM"] == '콜백'){
                this.chatHistory[i]["PROC_CODE"] = 'ing';
              }
            } else if (gbn == "call") {
              if(this.CALL_PHN_CNSL_HISTORY[i]["PROC_CODE_NM"] == '완결' || this.CALL_PHN_CNSL_HISTORY[i]["PROC_CODE_NM"] == '전달'){
                this.CALL_PHN_CNSL_HISTORY[i]["PROC_CODE"] = 'done';
              } else if(this.CALL_PHN_CNSL_HISTORY[i]["PROC_CODE_NM"] == '미결'){
                this.CALL_PHN_CNSL_HISTORY[i]["PROC_CODE"] = 'undone';
              } else if(this.CALL_PHN_CNSL_HISTORY[i]["PROC_CODE_NM"] == '콜백'){
                this.CALL_PHN_CNSL_HISTORY[i]["PROC_CODE"] = 'ing';
              }
            }
          }

          //console.log("chatHistory상담이력<<<<>>>>", (gbn == "chat") ? this.chatHistory : this.CALL_PHN_CNSL_HISTORY);

        }).catch((err) => {
          alert(err);
        });
      },

      /**
       * 상담이력 조회
       */
      getPhnCnslDtl(index, key, gbn) {

        apiChat.post(
            "api/chat/main/cnslt-hist/chat-inqire/" + key, {    //api url입력
              ASP_NEWCUST_KEY: (gbn == "chat") ? this.CLK_CUST : this.CUS_ASP_CUST_KEY,
              ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
              ASP_CUST_KEY: (gbn == "chat") ? this.CUST : this.fullCust(),
              USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
              action: "api/chat/main/cnslt-hist/chat-inqire/" + key,
              method: "post",
            }, { head: {}
            }
          ).then((response) => {

            //console.log("상담이력<<<<>>>>", response.data.data);
            //console.log("index", index);

            if (gbn == "chat")  {
              this.talkHistList[index] = response.data.data;
            } else if (gbn == "call") {
              this.CALL_PHN_CNSL_DTL[index] = response.data.data;
            }

            //console.log("이력메시지목록<<<<>>>>", (gbn == "chat") ? this.talkHistList : this.CALL_PHN_CNSL_DTL);

          }) .catch((err) => {
            alert(err);
          });
      },

      /**
       * 타이머 시작
       */
      ctiCallTimerStart(alam, textAlam) {

        let i = 0;
        let ctiTimerAlam    = 0;
        let ctiTimerAlamLen = 0;

        ctiTimerAlamLen = this.CTI_TIMER_ALAM.length;       // CTI경과시간알림

        if (this.CTI_CALL_TIMER) {
          this.ctiCallTimerEnd();
        }

        /** CTI경과시간알림 */
        if (ctiTimerAlamLen == 1) {
          ctiTimerAlam = this.CTI_TIMER_ALAM[ctiTimerAlamLen-1].value;
        }

        /** 이전 통화시간 이후 콜 경과 시간 표시 */
        if (this.CTI_DB_STATE == "" || this.CTI_DB_STATE == "answer") {

          i = Number(this.CTI_PRE_CALLTIME);
          this.CTI_BRD.lapseTime = this.getHHMISS(i);
        }

        this.CTI_CALL_TIMER =  setInterval(() => {

          i++;

          if (this.CTI_DB_STATE == "" || this.CTI_DB_STATE == "answer") {
            this.CTI_PRE_CALLTIME = i;
          }

          /** CTI 타이머 알람 상태값 */
          if (alam && ctiTimerAlam > 0 && i >= ctiTimerAlam) {
            this.CTI_BRD.ctiTimeLapse = true;
          }

          /** CTI 상태 알림 상태값 */
          if (textAlam && ctiTimerAlam > 0 && i >= ctiTimerAlam) {
            this.CTI_BRD.ctiTextLapse = true;
          }

          this.CTI_BRD.lapseTime = this.getHHMISS(i);
        }, 1000);

      },

      /**
       * 타이머 종료
       */
      ctiCallTimerEnd() {

        clearInterval(this.CTI_CALL_TIMER);

        let i = 0;
        this.CTI_BRD.lapseTime = this.getHHMISS(i);
      },

      /**
       * IPCC Socket 연결
       */
      ctiIpccConnect(inlneNo, ipAddr) {

        /** 소프트폰 중계 소켓 생성 */
        const socket = io.connect(this.IPCC_SOCKET_URL, {
          secure: this.SECURE_MODE,                                     // SSL option
          pingInterval: this.PING_INTERVAL,
          pingTimeout: this.PING_TIMEOUT,
        });

        Vue.prototype.$socket = socket;

        /** 노드 서버 접속 */
        socket.on("connect", function () {
          socket.emit("guestjoin", "cloudnode", inlneNo, ipAddr);
        });

        /** 중복로그인 알림 - 로그아웃 처리 */
        socket.on('sameUserAlert',function(userid, userip){

          try {
              /** 소켓 닫는다 */
              if(socket) socket.disconnect();
              alert('동일한 사용자아이디로 접속하여\n연결이 종료 되었습니다.(중복 로그인 불가) \n\n 접속자아이피 : ' + userip + ', 사용자아이디 : ' + userid);
          }catch(e){
            //
          }
        });

        /** API 명령어 결과 수신 */
        socket.on('recvmsg', function(username, data) {

          console.log('');
          console.log('#####recvmsg############################################');
          console.log('recvmsg => user : ' + username + ', data : ' + data);

          let rtnData;

          let rtnResult   = "";                                         // 이벤트 처리 결과
          let rtnDesc     = "";                                         // 이벤트 구분
          let rtnDbState  = "";                                         // CTI 상세 이벤트_1:대기, 2:후처리, 3:휴식, 4:교육, 5:식사, 6:기타

          if (inlneNo == username) {

            data = data.replace('[', '');
            data = data.replace(']', '');

            rtnData = JSON.parse(data);                                  // JSON 데이터 파싱

            rtnResult = rtnData.result;
            rtnDesc = rtnData.desc;

            eventBus.busInitCallCti(rtnData);
          }
        });

        socket.on("softphone", function (msg) {

          console.log('');
          console.log('#####softphone message############################################');
          console.log("message : " + msg);

          if (msg) {

            /* 구현부 */
            let arrMsg    = msg.split("|");
            let msg_evt   = arrMsg[0];                                    // 메시지 종류
            let msg_agent = arrMsg[1];

            //let telNo       = arrMsg[1];                                // 전화번호
            //let campCode    = arrMsg[2];                                // 캠페인코드
            //let today       = new Date();
            //let year        = today.getFullYear();
            //let month       = ("0" + (today.getMonth() + 1)).slice(-2);
            //let day         = ("0" + today.getDate()).slice(-2);
            //let dateString  = year + "-" + month + "-" + day;
            //let hours       = ("0" + today.getHours()).slice(-2);
            //let minutes     = ("0" + today.getMinutes()).slice(-2);
            //let seconds     = ("0" + today.getSeconds()).slice(-2);
            //let timeString  = hours + ":" + minutes + ":" + seconds;

            // ###############################################################################
            // API 팝업
            // 예시 : RINGING|01082055960|07045130953
            // ###############################################################################
            //if (msg_evt == "RINGING") {
            //  console.log("message : " + msg);
            //  console.log("telNo : " + telNo);
            //  tmpCallRing(telNo);
            //}

            switch (msg_evt) {

              case "RINGING" :                 //전화벨 message : RINGING|01071378458|07080151166|11|초진 문의 - 남성센터|
              case "TRANSFER" :
              case "CONFERENCE" :
                eventBus.busCtiRinging(msg);
                break;

              case "ANSWER" :                 // 전화수신 통화시작 message : ANSWER|01071378458|20220621/1001-20220621-172625-IB-01071378458-1655799985.313
              case "OUTBOUND" :               // 전화수신 통화시작 message : OUTBOUND|01075838458|20220622/1001-20220622-185327-OB-01075838458-1655891607.844
                eventBus.busCtiAnswer(msg);
                break;

              case "HANGUP" :                 // 통화종료 message : HANGUP
              case "HANGINT" :

                if (msg_agent == "AGENT") {
                  eventBus.busCtiHangAgent();
                } else {
                  eventBus.busCtiHangup(msg);
                }

                break;

              case "TRANSBUSY" :              // 호전환 내부->내부 (상대방 거절)
                eventBus.busCtiTransBusy();
                break;

              case "CONFBUSY" :               // 3자 내부->내부 (상대방 거절)
                eventBus.busCtiConfBusy();
                break;
              case "CONFHANG" :               // 3자 시도 내부->내부 (상대방 상담사 통화 후 종료)
                eventBus.busCtiConfHang();
                break;
            }
            // ###############################################################################

          }
        });
      },


      /**
       * CTI 로그인
       */
      ctiLogin() {
        this.$socket.emit("sendmsg", "db_login", (this.CTI_HOME_BOOLEAN == true ? "1" : ""));
      },

      /**
       * CTI 로그아웃
       */
      ctiLogout() {
        this.ctiHandleCls(false);                 //후처리 버튼 사용자 선택여부
        this.$socket.emit("sendmsg", "db_logout", "");
      },

      /**
       * 콜 대기
       */
      ctiRead() {
        this.$socket.emit("sendmsg", "db_state", "1");
      },

      /**
       * CTI 후처리 버튼 선택 처리
       */
      ctiHandle() {
        this.$socket.emit("sendmsg", "db_state", "2");
      },

      /**
       * CTI 후처리 상태값 표시
       */
      citHandleAuto() {

        this.ctiHandleCls(true);                 //후처리 버튼 사용자 선택여부

        this.CTI_BRD.current = '후처리';
        this.CTI_BRD.icon = 'cti-btn-handle';
      },

      /**
       * 후처리 버튼 선택 표시 여부
       */
      ctiHandleCls(e) {
        this.CTI_BTN[6].cls = e;
      },

      /**
       * CTI 이석처리
       */
      ctiReason(e) {
        this.$socket.emit("sendmsg", "db_state", e);
      },

      /**
       * CTI Ringing 이벤트
       */
      ctiRinging() {
        this.showDialog("M110101P05");
      },

      /**
       * 음소거
       */
      ctiMute() {
        this.$socket.emit("sendmsg", "phone_mute", "");
      },

      /**
       * 콜 보류
       */
      ctiHold() {
        this.$socket.emit("sendmsg", "phone_hold", "");
      },

      /**
       * 음소거, 콜보류 이벤트 처리
       */
      ctiMuteHold(e) {
        eventBus.busCtiInitCall(e);
      },

      /**
       * 판빌전화기 상태 체크
       */
      ctiChkFanvil(e) {

        let chkFanvilUrl = this.CTI_TRANSFER_URL + "?mode=chk_trans&ext=" + this.INLNE_NO;

        console.log();
        console.log("#####ctiPhoneTrno##############################################");
        console.log("chkFanvilUrl : " + chkFanvilUrl);
        console.log("this.INLNE_NO : " + this.INLNE_NO);

        $.ajax({
          url: chkFanvilUrl,
          dataType: "json",
          type: "GET",
          contentType: "application/json",
          crossDomain: true,
          success: function(data) {

            //console.log(data);
            //console.log("chk_fanvil : " + data[0].cmd_tr);

            if(data[0].cmd_tr == 'phone_busy') {
              eventBus.busCtiPhoneBusy('phone_hold');                                 // 호전환/3자 취소 socket.emit('sendmsg', 'phone_hold', '');
            } else if(data[0].cmd_tr=='conf_busy') {
            } else {
              eventBus.busCtiPhoneNo(e);                                              // 호전환/3자 취소 //socket.emit('sendmsg', e, '');
            }
          },
          error:function(request,status,error){
            alert("에러 : " + error);
          }
        });

      },

      /**
       * 호전환시도
       */
      ctiPhoneTran() {

        let inLineNoLen = 0;

        inLineNoLen = typeof this.CTI_TRANSFER_INFO.inLineNo == undefined || typeof this.CTI_TRANSFER_INFO.inLineNo == "undefined" ? inLineNoLen : this.CTI_TRANSFER_INFO.inLineNo.length;

        if (inLineNoLen == 4 || inLineNoLen > 10) {
          this.$socket.emit('sendmsg', 'phone_tran', this.CTI_TRANSFER_INFO.inLineNo);
        }
      },

      /**
       * 호전환 시도 / 취소
       */
      ctiPhoneTranBol(e) {
        eventBus.busCtiPhoneTran(e);
      },

      /**
       * 호전환 취소
       */
      ctiPhoneTrno() {
        this.ctiChkFanvil('phone_trno');
      },

      /**
       * 호전환 완료
       */
      ctiPhoneTrok() {
        this.$socket.emit('sendmsg', 'phone_trok', '');
      },

      /**
       * 3자 시도
       */
      ctiPhoneConf() {

        let inLineNoLen = 0;

        inLineNoLen = typeof this.CTI_TRANSFER_INFO.inLineNo == undefined || typeof this.CTI_TRANSFER_INFO.inLineNo == "undefined" ? inLineNoLen : this.CTI_TRANSFER_INFO.inLineNo.length;

        if (inLineNoLen == 4 ) {
          this.$socket.emit('sendmsg', 'phone_conf', this.CTI_TRANSFER_INFO.inLineNo);
        }

      },

      /**
       * 3자 시도 / 취소
       */
      ctiPhoneConfBol(e) {
        eventBus.busCtiPhoneConf(e);
      },

      /**
       * 3자 취소
       */
      ctiPhoneCfno() {
        this.ctiChkFanvil('phone_cfno');
      },

      /**
       * 3자 완료
       */
      ctiPhoneCfok() {
        this.$socket.emit('sendmsg', 'phone_cfok', '');
      },

      /**
       * 호전환 시도, 3자 시도
       */
      ctiTranCftel(e) {

        this.CTI_INFO.strTransSucess = "N";                                 // 호전환 완료 여부
        this.CTI_INFO.strPreCtiState = this.CTI_INFO.strState;              // CTI 이전상태값

        this.CTI_INFO.strState = e;                                         // 내부 -> 내부/외부 호전환 phone_tran, 내부 -> 내부  3자 phone_conf, phone_cftel

        if (e == "phone_tran") {                                            // 호전환 시도
          this.ctiPhoneTranBol(true);
        } else if (e == "phone_cftel") {                                    // 3자 시도

          this.CTI_INFO.strConference       = "Y";                          // 3자 여부
          this.CTI_INFO.strConferenceCancle = "";                           // 3자 고객 종료 여부

          this.ctiPhoneConfBol(true);
        }
      },

      /**
       * 호전환 취소, 3자 취소
       */
      ctiTrnoCfno() {

        if (this.CTI_INFO.strState == "phone_tran") {
          this.ctiPhoneTranBol(false);                                      // 호전환창 버튼 재 배열
        } else if (this.CTI_INFO.strState == "phone_cftel") {
          this.CTI_INFO.strConference = "";                                 // 3자 여부
          this.ctiPhoneConfBol(false);                                      // 3자 버튼 재 배열
        }

        this.CTI_INFO.strTransSucess = "";                                  // 호전환 완료 여부 초기화
        this.CTI_INFO.strState      = this.CTI_INFO.strPreCtiState;         // CTI 이전상태값
        this.CTI_INFO.strPreCtiState = "";                                  // CTI 이전상태값 초기화

        console.log("#####phone_hold(setCtiTrnoCfno)#################################################################");
        console.log("this.CTI_INFO.strTransSucess : " + this.CTI_INFO.strTransSucess);
        console.log("this.CTI_INFO.strState : " + this.CTI_INFO.strState);
        console.log("this.CTI_INFO.strPreCtiState : " + this.CTI_INFO.strPreCtiState);

      },

      /**
       * 호전환 완료, 3자 완료
       */
      ctiTrokCfok(e) {

        this.CTI_INFO.strState      = this.CTI_INFO.strPreCtiState;         // CTI 이전상태값
        this.CTI_INFO.strPreCtiState = "";                                  // CTI 이전상태값
        this.CTI_INFO.strTransSucess = "Y";

        console.log("#####setCtiTrokCfok#################################################################");
        console.log("this.CTI_INFO.strState : " + this.CTI_INFO.strState);
        console.log("this.CTI_INFO.strPreCtiState : " + this.CTI_INFO.strPreCtiState);
        console.log("this.CTI_INFO.strTransSucess : " + this.CTI_INFO.strTransSucess);

        this.hideDialog("M110101P01");

        if (e == "phone_cfok") {                                            // 3자 완료

          this.CTI_BRD.current   = "3자 완료";
          this.CTI_BRD.icon      = this.CTI_BTN[5].icon;                    //"cti-btn-tran";

          this.btnClassRemove();
          this.ctiBtnTrans(true);
        }
      },

      /**
       * 호전환 버튼 활성화 여부 (3자완료 비활성화 / 상대방 상담사 종료 시 활성화)
       */
      ctiBtnTrans(e) {
      	this.CTI_BTN[5].disalb = e;
      },

      /**
       * 콜 종료
       */
      ctiHangup() {

        /** 3자완료 회의주체자 끊기 이벤트 */
        if (this.CTI_INFO.strConference == "Y") {

          let conferencdCancle = (typeof this.CTI_INFO.strConferenceCancle == undefined || typeof this.CTI_INFO.strConferenceCancle == "undefined") ? "" : this.CTI_INFO.strConferenceCancle;

          if (conferencdCancle == "") {                                     // 3자 완료 고객 종료
             this.CTI_INFO.strConferenceCancle = "N";
          }
        }

        this.$socket.emit("sendmsg", "phone_hang", "");
      },

      /**
       * cti 버튼 클래스 삭제
       */
      btnClassRemove() {

        const activeBtn = document.querySelector(
          ".layout-chatmain--btn .v-btn--active"
        );

        activeBtn !== null ? activeBtn.classList.remove("v-btn--active") : "";
      },

      /**
       * 재택여부
       */
      btnCtiHome() {},

      /**
       * CTI Login Buttn Event
       */
      btnCtiLogin() {

        this.CTI_BRD.current = '';                // CTI 상태값 텍스트 초기화
        this.CTI_BRD.icon    = null;              // CTI 상태값 아이콘 초기화

        if (this.CTI_LOGIN_BOOLEAN == true) {
          this.ctiLogin();                        // 로그인
        } else {
          this.CTI_HOME_BOOLEAN = false;          // 재택여부
          this.ctiLogout();                       // 로그아웃
        }
      },

      /**
       * 링상태_스킵
       */
      btnCtiSkin() {

        let rtnData;
        let ctiSkipUrl = this.CTI_SKIP_URL + "?ext=" + this.INLNE_NO + "&telno=" + this.UEI_INFO.strInboundNum;

        $.ajax({
          url: ctiSkipUrl,
          dataType: "html",
          type: "GET",
          contentType: "application/json",
          crossDomain: true,
          success: function(data) {
            eventBus.busCtiSkipTrans(data);                               // 콜스킵기능_스킵/전환
          }, error:function(request, status, error){
            console.log("code:" + request.status + "\n"+"message:" + request.responseText + "\n" + "error:" + error);
          }
        });
      },

      /**
       * 링상태_전환
       */
      btnCtiTrans() {

        let rtnData;
        let ctiTransUrl = this.CTI_TRANS_URL + "?ext=" + this.INLNE_NO + "&telno=" + this.UEI_INFO.strInboundNum + "&ext2=" + this.TRANS_INLINE_NO;

        if (this.TRANS_INLINE_NO !="" && this.TRANS_INLINE_NO.length > 3) {

          $.ajax({
            url: ctiTransUrl,
            dataType: "html",
            type: "GET",
            contentType: "application/json",
            crossDomain: true,
            success: function(data) {
              eventBus.busCtiSkipTrans(data);                               // 콜스킵기능_스킵/전환
            }, error:function(request, status, error){
              console.log("code:" + request.status + "\n"+"message:" + request.responseText + "\n" + "error:" + error);
            }
          });

        } else {
          alert("콜스킵기능_전환 번호를 입력하세요");
        }
      },

      /**
       * CTI 클릭 버튼 활성화 처리
       */
      btnCtiActive(e) {

        const _t = e.currentTarget;

        let ary       = [];
        let iconTxt   = "";
        let aryLen    = 0;

        this.ctiHandleCls(false);                 //후처리 버튼 사용자 선택여부
        this.btnClassRemove();

        _t.classList.add("v-btn--active");

        this.CTI_BRD.current  = _t.querySelector(".layout-chatmain--btn-text").textContent;
        this.CTI_BRD.icon     = _t.querySelector(".v-icon").className.split(" ").splice(-1, 1);

        iconTxt = this.CTI_BRD.icon + "";
        ary     = iconTxt.split("-");
        aryLen  = ary.length

        /** ctiBtnEvent 호출 */
        if (aryLen == 3) {
          this.ctiBtnEvent(ary[aryLen-1]);
        }
      },

      /**
       * CTI 클릭 시 모달창 활성화 처리
       */
      btnCtiActiveExpand(e) {

        const _t = e.currentTarget;

        this.btnClassRemove();

        _t.classList.add("v-btn--active");
        this.CTI_BRD.expand = !this.CTI_BRD.expand;

        /** CTI 후처리 상태값 표시 */
        if (this.CTI_DB_STATE != "reason" && this.CTI_BRD.expand == false) {
          this.btnClassRemove();
          this.citHandleAuto();
        }
      },

      /**
       * 이석 사유코드 클릭 이벤트
       */
      btnCtiReason(i) {                           //ctiReasonUpdate

        const valClass = this.CTI_REASON[i].className;
		    const valText = this.CTI_REASON[i].text;

        this.CTI_BRD.expand = false;
        this.ctiMenuActive  = i;

        this.CTI_BRD.current = valText;
        this.CTI_BRD.icon    = valClass;

        /** 이석처리 */
        this.ctiReason(this.CTI_REASON[i].dbState);
      },

      /**
       * 전화 수신
       */
      btnCtiAnser() {
        this.hideDialog("M110101P05");
        this.$socket.emit("sendmsg", "phone_acce", "");
      },

      /**
       * 전화걸기 callTest
       */
      btnCtiMakeCall() {

        let sendCall = (this.CTI_OB_CHK == false) ? this.CTI_SEND_CALL.phone.replace(/-/g, '').trim() : this.CTI_SEND_CALL_PHONE.replace(/-/g, '').trim();

        if (this.CTI_OB_CHK == false) {
          if (sendCall == "undefined" || sendCall == "00000000000") {
            sendCall = this.CUS_CUSTOMER_PHONE_NO.replace(/-/g, '').trim();
          }
        }

        if (sendCall.length < 8) {
          alert("발신번호를 입력하세요");
        } else {

          this.initCallCtiInof();                       	// CTI_INFO, UEI_INFO 초기화
          this.initCallCtiCall();                       	// CIT CALL 정보 (수/발신 정보)
          this.initCallCtiBrd();                        	// CIT CALL 정보 고객정보 상단 정보

          this.initCallCnsl();                            // 상담이력 초기화
          this.CNSL_CALL_TY = "02";                       // 콜유형

          this.hideDialog("M110101P03");

          let ctiTakeCustTel = "";
          let cusAspCustKey  = "";

          if (typeof this.CTI_TAKE_CUST_TEL == undefined || typeof this.CTI_TAKE_CUST_TEL == "undefined" || this.CTI_TAKE_CUST_TEL.trim() == "") {
          } else {
            ctiTakeCustTel = this.CTI_TAKE_CUST_TEL.trim();
          }

          if (typeof this.CUS_ASP_CUST_KEY == undefined || typeof this.CUS_ASP_CUST_KEY == "undefined" || this.CUS_ASP_CUST_KEY.trim() == "") {
          } else {
            cusAspCustKey = this.CUS_ASP_CUST_KEY;
          }

          if (ctiTakeCustTel == "" || ctiTakeCustTel == cusAspCustKey) {
            this.$socket.emit("sendmsg", "phone_call", sendCall);
          } else {
            this.$socket.emit("sendmsg", "phone_call2", sendCall + "|" + ctiTakeCustTel);
          }
        }

      },

      /**
       * OB 전용 체크
       * 대기버튼 비활성화
       */
      btnCtiObChk() {
        this.CTI_BTN[2].disalb = this.CTI_OB_CHK;
      },

      /**
       * 고객정보 검색
       */
      btnCusSrch() {

        let ctiDbState = "";

        ctiDbState = typeof this.CTI_DB_STATE == undefined || typeof this.CTI_DB_STATE == "undefined" || this.CTI_DB_STATE == "" ? "" : this.CTI_DB_STATE;

        if (ctiDbState == "hangup")  {
          alert("콜 상담저장 후 고객조회 하세요");
        } else {

          this.initCallCus();                           	              // 콜상담_고객정보 초기화

          this.initCallCnslSch();                       	              // 콜상담_고객상담이력_검색초기화
          this.initCallCnslDtlSch();                    	              // 콜상담_고객상담이력_상세검색초기화
          this.initCallCnslLst();                       	              // 콜상담_고객상담이력

          this.initCnslTypCdCmmn(this.CNSL_WK[0], '1');                 // 상담유형코드1,2,3 초기화
          this.initCallCnsl();                          	              // 콜상담_콜상담저장 초기화

          /** 아웃바운드 관련 */
          if (this.CTI_INFO.strCallMode == 'outbound') {

            let camId   = "";                                           // 캠페인 ID
            let clbkNo  = "";                                           // 콜백 번호
            let resvSeq = "";                                           // 재통화(예약) 번호

            camId   = typeof this.CALL_OUT_BOUND.CAM_ID == undefined || typeof this.CALL_OUT_BOUND.CAM_ID == "undefined" || this.CALL_OUT_BOUND.CAM_ID == "" ? "" : this.CALL_OUT_BOUND.CAM_ID;
            clbkNo  = typeof this.CALL_CALL_BACK.CLBK_NO == undefined || typeof this.CALL_CALL_BACK.CLBK_NO == "undefined" || this.CALL_CALL_BACK.CLBK_NO == "" ? "" : this.CALL_CALL_BACK.CLBK_NO;
            resvSeq = typeof this.CALL_RESV_CALL.RESV_SEQ == undefined || typeof this.CALL_RESV_CALL.RESV_SEQ == "undefined" || this.CALL_RESV_CALL.RESV_SEQ == "" ? "" : this.CALL_RESV_CALL.RESV_SEQ;

            /** 아웃바운드 초기화 */
            if (camId == "" && camId.length == 0) {
              this.initCallOutBound();
            }

            /** 콜백 초기화 */
            if (clbkNo == "" && clbkNo.length == 0) {
              this.initCallCallBack();
            }

            /** 예약콜 초기화 */
            if (resvSeq == "" && resvSeq.length == 0) {
              this.initCallResvCall();
            }

          } else {
            this.initCallOb();                                          // OB_캠페인,콜백,예약콜 전역 변수 일괄 초기화
          }

          this.showDialog('MP01');                                      // 고객조회 팝업
        }
      },

      /**
       * 고객정보 초기화
       */
      btnCusInit() {

        let ctiDbState = "";

        ctiDbState = typeof this.CTI_DB_STATE == undefined || typeof this.CTI_DB_STATE == "undefined" || this.CTI_DB_STATE == "" ? "" : this.CTI_DB_STATE;

        if (ctiDbState == "hangup")  {
          alert("콜 상담저장 후 초기화 하세요");
        } else {

          this.initCallCusSch();                                        // 콜상담_고객정보 검색 초기화
          this.initCallCus();                           	              // 콜상담_고객정보 초기화

          this.initCallCnslSch();                       	              // 콜상담_고객상담이력_검색초기화
          this.initCallCnslDtlSch();                    	              // 콜상담_고객상담이력_상세검색초기화
          this.initCallCnslLst();                       	              // 콜상담_고객상담이력

          /** 아웃바운드 관련 */
          if (this.CTI_INFO.strCallMode == 'outbound') {

            let camId   = "";                                           // 캠페인 ID
            let clbkNo  = "";                                           // 콜백 번호
            let resvSeq = "";                                           // 재통화(예약) 번호

            camId   = typeof this.CALL_OUT_BOUND.CAM_ID == undefined || typeof this.CALL_OUT_BOUND.CAM_ID == "undefined" || this.CALL_OUT_BOUND.CAM_ID == "" ? "" : this.CALL_OUT_BOUND.CAM_ID;
            clbkNo  = typeof this.CALL_CALL_BACK.CLBK_NO == undefined || typeof this.CALL_CALL_BACK.CLBK_NO == "undefined" || this.CALL_CALL_BACK.CLBK_NO == "" ? "" : this.CALL_CALL_BACK.CLBK_NO;
            resvSeq = typeof this.CALL_RESV_CALL.RESV_SEQ == undefined || typeof this.CALL_RESV_CALL.RESV_SEQ == "undefined" || this.CALL_RESV_CALL.RESV_SEQ == "" ? "" : this.CALL_RESV_CALL.RESV_SEQ;

            /** 아웃바운드 초기화 */
            if (camId == "" && camId.length == 0) {
              this.initCallOutBound();
            }

            /** 콜백 초기화 */
            if (clbkNo == "" && clbkNo.length == 0) {
              this.initCallCallBack();
            }

            /** 예약콜 초기화 */
            if (resvSeq == "" && resvSeq.length == 0) {
              this.initCallResvCall();
            }

          } else {
            this.initCallOb();                                          // OB_캠페인,콜백,예약콜 전역 변수 일괄 초기화
          }
        }

        if(this.CHK_ASP_NUM == 'Y'){
          this.SCH_ASP_CUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
          this.CUS_ASP_CUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
          this.RDOnly = true;
        } else {
          this.RDOnly = false;
        }
      },

      /**
       * 고객정보 저장/수정
       */
      btnCusInfo() {

        if (this.CUS_DISABLED == false) {

          if (this.CUS_CUSTOMER_ID == "") {
            this.CUS_OB_DISABLED = false;
          }

          this.btnCusDisabled();

        } if (this.CUS_DISABLED == true) {

          if (this.CUS_ASP_CUST_KEY.trim() == "") {

            alert("업체를 선택하세요");
            this.CUS_DISABLED = false;

          } else if (typeof this.CUS_PHONE_NO == "undefined" || this.CUS_PHONE_NO.trim() == "" || this.CUS_PHONE_NO.replace(/-/g, "/").trim().length < 8) {

            this.CUS_DISABLED = false;
            alert("전화번호1는 최소 8자리 이상 입력하세요");

          } else {

            let check = /^[0-9]+$/;
            let bol = true;

            let cusPhoneNo = this.CUS_PHONE_NO.replace(/-/g, '').trim();
            let cusWrcTel  = this.CUS_WRC_TEL.replace(/-/g, '').trim();

            if (!check.test(cusPhoneNo)) {

              bol = false;
              this.CUS_DISABLED = false;
              alert("전화번호1은 숫자만 입력 가능합니다.");

            } else {

              if (cusWrcTel.length >= 8) {

                if (!check.test(cusWrcTel)) {

                  bol = false;
                  this.CUS_DISABLED = false;
                  alert("전화번호2은 숫자만 입력 가능합니다.");

                } else {

                  this.CUS_WRC_TEL_NO      = this.mixin_setPhoneNo(cusWrcTel);   // 고객정보_전화번호 2
                  this.CUS_WRC_TEL_NO_ENC  = this.mixin_mask_num(cusWrcTel);     // 고객정보_전화번호 2 암호화
                }
              } else if (cusWrcTel.length > 0 && cusWrcTel.length < 8) {
                bol = false;
                this.CUS_DISABLED = false;
                alert("전화번호2은 최소 8자리 이상 입력하세요");
              }
            }

            if (bol) {
              this.CUS_OB_DISABLED = true;                                      // 업체명, 전화번호1 비활성화 처리
              this.btnCusDisabled();
              this.setCusInfo();                                                // 고객정보 저장
            }
          }
        }
      },

      /**
       * 콜 고객 수정모드
       */
      btnCusDisabled() {

        if (this.CUS_DISABLED == false) {

          this.CUS_PHONE_NO = this.mixin_setPhoneNo(this.CUS_CUSTOMER_PHONE_NO.replace(/-/g, '').trim());
          this.CUS_WRC_TEL  = this.mixin_setPhoneNo(this.CUS_WRC_TEL_NO.replace(/-/g, '').trim());

        } else if (this.CUS_DISABLED == true) {

          //console.log("####btnCusDisabled#################################");
          //console.log("this.CUS_DISABLED : " + this.CUS_DISABLED);
          //console.log("this.CUS_PHONE_NO : " + this.CUS_PHONE_NO);
          //console.log("this.CUS_CUSTOMER_PHONE_NO : " + this.CUS_CUSTOMER_PHONE_NO);
          //console.log("this.CUS_CUSTOMER_PHONE_NO_ENC : " + this.CUS_CUSTOMER_PHONE_NO_ENC);


          if (this.CUS_PHONE_NO.replace(/-/g, '').trim() == this.CUS_CUSTOMER_PHONE_NO.replace(/-/g, '').trim()) {
          } else {
            this.CUS_CUSTOMER_PHONE_NO     = this.mixin_setPhoneNo(this.CUS_PHONE_NO.replace(/-/g, '').trim());           // 고객정보_전화번호 1
            this.CUS_CUSTOMER_PHONE_NO_ENC = this.mixin_mask_num(this.CUS_PHONE_NO.replace(/-/g, '').trim());             // 고객정보_전화번호 1 암호화
          }

          if (this.CUS_WRC_TEL.replace(/-/g, '').trim() == this.CUS_WRC_TEL_NO.replace(/-/g, '').trim()) {
          } else {

            if (this.CUS_WRC_TEL.replace(/-/g, '').trim().length == 0) {
              this.CUS_WRC_TEL_NO     = "";                                                                       // 고객정보_전화번호 2
              this.CUS_WRC_TEL_NO_ENC = "";                                                                       // 고객정보_전화번호 2 암호화
            } else {
              this.CUS_WRC_TEL_NO     = this.mixin_setPhoneNo(this.CUS_WRC_TEL_NO.replace(/-/g, '').trim());             // 고객정보_전화번호 2
              this.CUS_WRC_TEL_NO_ENC = this.mixin_mask_num(this.CUS_WRC_TEL_NO.replace(/-/g, '').trim());               // 고객정보_전화번호 2 암호화
            }
          }

          this.CUS_PHONE_NO = this.CUS_CUSTOMER_PHONE_NO_ENC;
          this.CUS_WRC_TEL  = this.CUS_WRC_TEL_NO_ENC;
        }
      },

      /**
      * 상담이력 조회
      */
      btnTogglePhnCnsl(item, gbn) {

        if (gbn == "chat") {
          this.getPhnCnsl(item.ID, !this.chatHistory[item.ID - 1].EXPAND, '', gbn);
          this.getPhnCnslDtl(item.ID - 1, item.JOBBY_CNSL_HST_ID, gbn);
        } else if (gbn == "call") {
          this.getPhnCnsl(item.ID, !this.CALL_PHN_CNSL_HISTORY[item.ID - 1].EXPAND, '', gbn);  // getPhnCnsl(n, tf, chat, gbn)
          this.getPhnCnslDtl(item.ID - 1, item.JOBBY_CNSL_HST_ID, gbn);
        }

      },

      /**
       * 버튼 이벤트 따른 CTI 이벤트 호출
       */
      ctiBtnEvent(valueEx) {

        /** 타이머 종료 */
        this.ctiCallTimerEnd();

        switch (valueEx) {

          case "mute":                            // 음소거버튼 1
            this.ctiMute();
            break;

          case "hold":                            // 보류버튼 2
            this.ctiHold();
            break;

          case "ready":                           // 대기버튼 3

            if (this.CTI_DB_STATE != 'ready') {
              this.ctiRead();                     // 대기
            } else {
              this.btnClassRemove()               // 버튼 클릭 Class 삭제
              this.ctiBtnEvent('handle');         // 대기해제 이벤트
            }

            break;

          case "hang":                            // 끊기버튼 4
            this.ctiHangup();
            break;

          case "tran":                            // 호전환 버튼 5
            break;

          case "handle":                          // 후처리버튼 6

            if (this.CTI_DB_STATE != "handle") {

              /** CTI 로그인/대기해제/이석해제 자동 이벤트 처리 */
              if (this.CTI_BRD.icon != 'cti-btn-handle' || this.CTI_DB_STATE == "reason") {
                this.citHandleAuto();             // CTI 후처리 상태값 표시
              }

              this.ctiHandle();                   // 후처리
            }

            break;

          case "call":                            // 걸기버튼 7
            break;

          case "reason":                          // 이석버튼 8
            break;

          default:
            break;
        }
      },

      /**
       * 전화번호1 복사
       */
      btnCustPhoneCpy(){
        navigator.clipboard.writeText(this.CUS_CUSTOMER_PHONE_NO);
        this.showToastInfo("전화번호를 복사했습니다.")
      },

      /**
     * 콜상담저장_상담유형코드
     */
    getCnslTypCd(e) {
      let cnslCnslTypCd = "";

      this.initCnslTypCdCmmn(this.CNSL_WK[0], e); // 콜상담저장_상담유형코드1,2,3 초기화

      if (e == "2") {
        cnslCnslTypCd = this.CNSL_CNSL_TYP_CD;
      } else if (e == "3") {
        cnslCnslTypCd = this.CNSL_CNSL_TYP_CD_2;
      }

      this.getCmmnCnslTypCd(
        this.CNSL_WK[0],
        this.CNSL_ASP_CUST_KEY,
        e,
        cnslCnslTypCd
      );
    },

      /**
     * 콜상담저장_상담유형코드1,2,3 초기화
     */
    initCnslTypCdCmmn(gbn, e) {
      /** 상담유형 1 */
      if (e == "1") {
        if (gbn == this.CNSL_WK[0]) {
          this.CALL_CNSL_TYP_CD = [];
        } else if (gbn == this.CNSL_WK[1]) {
          this.CNSL_TY1 = [{ CD: "", CD_NM: "전체" }];
        }
      }

      /** 상담유형 2 */
      if (e == "1" || e == "2") {
        if (gbn == this.CNSL_WK[0]) {
          this.CALL_CNSL_TYP_CD_2 = [];
        } else if (gbn == this.CNSL_WK[1]) {
          this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
        }
      }

      /** 상담유형 3 */
      if (e == "1" || e == "2" || e == "3") {
        if (gbn == this.CNSL_WK[0]) {
          this.CALL_CNSL_TYP_CD_3 = [];
        } else if (gbn == this.CNSL_WK[1]) {
          this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
        }
      }
    },

      /**
       * 인바운드 화면세팅, 아웃바운드 화면세팅
       * e(string) inbound:인바운드, outbound:아웃바운드
       */
      settingCallMode (e, arg) {

        //console.log("#####settingCallMode####################################################################");
        //console.log("e : " + e);
        //console.log("arg : " + arg);

        /** 상담시작시간 설정 */
        if ((e == "inbound" && arg == "ringing") || (e == "outbound" && arg == "answer")) {
          this.CTI_INFO.strStartDate =  this.getSverData("YYYYMMDD", "");                                   // 통화시작년월일
          this.CTI_INFO.strStartTime = this.getSverData("HH24MISS", "");                                    // 통화시작시분초
        }

        if(e == "inbound" && arg == "answer") {
          this.getCusInfo();                                                                                // 인바운드 고객조회
        } else if(e == "outbound" && arg == "answer") {

          //console.log("#####settingCallMode outbound####################################################################");
          //console.log("this.CALL_OUT_BOUND.CUST_NO : " + this.CALL_OUT_BOUND.CUST_NO);
          //console.log("this.CALL_CALL_BACK.CLBK_NO : " + this.CALL_CALL_BACK.CLBK_NO);
          //console.log("this.CALL_RESV_CALL.RESV_SEQ : " + this.CALL_RESV_CALL.RESV_SEQ);

          if (this.CALL_OUT_BOUND.CAM_ID != "" && this.CALL_OUT_BOUND.CAM_ID != "undefined") {              /** 캠페인(아웃바운드) */

            //this.CNSL_PROC_TY_DROPITEMS  = this.PROC_TY_DROPITEMS;                                        / 콜상담저장_처리구분_켐페인(PROC_TY), 콜백/예약콜(EOT_TY_RLT)
            //this.CNSL_PROC_RLT_DROPITEMS = this.FISH_TY_DROPITEMS;                                        // 콜상담저장_처리결과_켐페인(처리구분 FISH_TY), 콜백(PROC_CODE)

            this.setCampainTryCnt();                     		                                                // 아웃바운드 통화시도 횟수 증가

          } else if (this.CALL_CALL_BACK.CLBK_NO != "" && this.CALL_CALL_BACK.CLBK_NO != "undefined") {     /** 콜백 */

            //this.CNSL_PROC_TY_DROPITEMS  = this.EOT_TY_RLT_DROPITEMS;                                     // 콜상담저장_처리구분_켐페인(PROC_TY), 콜백/예약콜(EOT_TY_RLT)
            //this.CNSL_PROC_RLT_DROPITEMS = this.PROC_CODE_DROPITEMS;                                      // 콜상담저장_처리결과_켐페인(처리구분 FISH_TY), 콜백(PROC_CODE)

            this.setCallBackTryCnt();         		                                                          // 콜백 시도 횟수증가

          } else if (this.CALL_RESV_CALL.RESV_SEQ != "" && this.CALL_RESV_CALL.RESV_SEQ != "undefined") {   /** 예약콜/재통화 */

            //this.CNSL_PROC_TY_DROPITEMS  = this.FISH_YN_DROPITEMS;                                        // 콜상담저장_처리구분_켐페인(PROC_TY), 콜백/예약콜(EOT_TY_RLT)
            //this.CNSL_PROC_RLT_DROPITEMS = [{text:'전체',value:''}];                                      // 콜상담저장_처리결과_켐페인(처리구분 FISH_TY), 콜백(PROC_CODE)

            this.setResvCallTryCnt();           		                                                        // 예약콜 시도 횟수증가
          }
        }
      },

      /**
       * 공통코드_cmmnCd: ['PLT002', 'PLT003', 'PLT004','PLT006']_콜관련공통코드_상담통합이력 콜유형_상담통합이력 IN_상담통합이력 OB_B/L 구분
       */
      initCallCmmn() {

        let dropItemsLst = [];
        let dropItemsLen = 0;
        let i = 0;

        this.PROC_TY_DROPITEMS = this.mixin_common_code_get(this.cmmnCdLst, 'OU003');             // 캠페인 처리구분
        this.FISH_TY_DROPITEMS = this.mixin_common_code_get(this.cmmnCdLst, 'OU003');             // 캠페인 처리결과
        this.EOT_TY_RLT_DROPITEMS = this.mixin_common_code_get(this.cmmnCdLst, 'OU003');          // 콜백 처리구분
        this.PROC_CODE_DROPITEMS = this.mixin_common_code_get(this.cmmnCdLst, 'OU005');           // 콜백 처리결과
        this.FISH_YN_DROPITEMS = this.mixin_common_code_get(this.cmmnCdLst, 'CRS022');            // 예약 처리결과

        this.CNSL_CALL_TY_RADIOGRP = this.mixin_common_code_get(this.cmmnCdLst, 'PLT025');        // 콜상담저장_콜유형

        this.PROC_DROPITEMS = this.mixin_common_code_get(this.cmmnCdLst, 'PLT024','전체');        // 콜상담저장_상담통합 상담결과
        this.CALL_TY_DROPITEMS = this.mixin_common_code_get(this.cmmnCdLst, 'PLT004');            // 콜상담저장_상담통합이력 OB_02_OB/03_콜백/05_예약콜/98_이관/99_캠페인

        this.CTI_TIMER_ALAM = this.mixin_common_code_get(this.cmmnCdLst, 'CRS047');               // CTI 타이머 알람시간
        this.CTI_ALAM_STATE = this.mixin_common_code_get(this.cmmnCdLst, 'CRS048');               // CTI 타이머 알람 상태값
        this.CTI_ALAM_TEXT  = this.mixin_common_code_get(this.cmmnCdLst, 'CRS049');               // CTI 상태 알림 상태값

        this.ASP_CUST_TEL  = this.mixin_common_code_get(this.cmmnCdLst, 'CUS001');                // 고객사 대표번호
        this.TAKE_CUST_TEL_DROPITEMS  = this.mixin_common_code_get(this.cmmnCdLst, 'CUS002');     // 발신번호 선택

        this.CUS_GENDER_DROPITEMS  = this.mixin_common_code_get(this.cmmnCdLst, 'CRS024');        // 성별

        this.CHANNEL_DROPITEMS = this.mixin_common_code_get(this.commonCodeLst, 'PLT002','전체'); // 콜상담_고객상담이력_채녈유형

        dropItemsLst = this.mixin_common_code_get(this.cmmnCdLst, 'PLT006');                      // 콜상담저장_B/L 구분

        dropItemsLen = dropItemsLst.length;

        /** 콜상담저장_B/L 구분 */
        if (dropItemsLen > 0) {

          for(; i < dropItemsLen; i++) {

            let val = dropItemsLst[i].value;
            let icon1 = "svg-dot-";

            if (val == "V") {
              icon1 = icon1 + "blue";
            } else if (val == "G") {
              icon1 = icon1 + "green";
            } else if (val == "R") {
              icon1 = icon1 + "red";
            } else if (val == "O") {
              icon1 = icon1 + "orange";
            }

            if (val == "V") {
              this.BL_DROPITEMS.push({title: "VIP",icon: icon1,gbn: val});
            } else {
              this.BL_DROPITEMS.push({title: "B/L",icon: icon1,gbn: val});
            }
          }
        }
      },

      /**
       * 콜관련 전체 초기화
       */
      intiCall(e) {

        if (e == 'ALL') {
          this.initCallCti('off');              		// CTI 버튼 초기화
        }

        this.initCallCtiInof();                       	// CTI_INFO, UEI_INFO 초기화
        this.initCallCtiCall();                       	// CIT CALL 정보 (수/발신 정보)
        this.initCallCtiBrd();                        	// CIT CALL 정보 고객정보 상단 정보

        this.initCnslTypCdCmmn(this.CNSL_WK[0], '1')  	// 상담유형코드1,2,3 초기화
        this.initCallOb();                            	// OB_캠페인,콜백,예약콜 전역 변수 일괄 초기화

        this.initCallCnsl();                          	// 콜상담_콜상담저장 초기화
        this.initCallCusSch();                        	// 콜상담_고객정보 검색 초기화
        this.initCallCus();                           	// 콜상담_고객정보 초기화

        this.initCallCnslSch();                       	// 콜상담_고객상담이력_검색초기화
        this.initCallCnslDtlSch();                    	// 콜상담_고객상담이력_상세검색초기화
        this.initCallCnslLst();                       	// 콜상담_고객상담이력


        if(this.CHK_ASP_NUM == 'Y'){
          this.SCH_ASP_CUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
          this.CUS_ASP_CUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
          this.RDOnly = true;
        } else {
          this.RDOnly = false;
        }
      },

      /**
       * CTI_INFO, UEI_INFO 초기화
       */
      initCallCtiInof() {

		this.CTI_PRE_CALLTIME = 0;                      // 콜타임 시간 초기화

        this.CTI_INFO = [                       		    //CTI 상태
            {
              strState : "",				                    // 전화기 상태정보
              strCnslId : "",				                    // 상담이력 정보저장
              strCallMode : "",			           		      // 인아웃바운드 구분
              strChannel : "",			            	      // cti 채널키
              strAspCustKey : "",               		    // ASP CUST KEY
              strAspCustTel : "",               		    // ASP CUST 대표번호
              strRecKey : "",				                    // 녹취키
              strConference : "",                       // 3자통화
              strConferenceCancle : "",	        		    // 3자통화 고객취소여부
              strTransSucess : "",		          		    // 2자전환 완료여부
              strStartTime :	"",			          	      // 전화종료시간
              strStartDate :	"",			          	      // 전화종료시간
              strEndTime :	"",			            	      // 전화종료시간
              strEndDate :	"",			            	      // 전화종료시간
              strReqSearchDate :	"",		        	      // 전화종료녹취검색시간
              strPreCtiState :	"",		          		    // 이전상태값
            }
        ],

        this.CTI_TRANS_CONFER = [                       // 호전환, 3자
          {
            gbn : "",                                   // 호전환, 3자 여부
            state : ""                                  // ringing, answer 상태
          }
        ],

        this.UEI_INFO = [                       		    // UEI 데이터 정보
          {
            strInboundNum : "",				          	      // 인입번호
            inboundInputType : "",			        	      // 인입경로
            inboundInputTypecd : "",		        	      // 인입경로코드
            strNum2 : "",					                      // 2자전화번호
            strCallPath : "",				                    // 콜패스
            bl : ""                             		    // 고객등급
          }
        ]
      },

      /**
       * CIT CALL 정보 (수/발신 정보)
       */
      initCallCtiCall() {

        this.TRANS_INLINE_NO = "";                      // 콜스킵기능_전환번호

        /**
         * 수신전화 모달
         */
        this.CTI_TAKE_CALL = {
          ci: require("@/assets/@call-ci.png"),
          company: "",                                  // 고객사명
          phone: "",                                    // 010-0000-0000
          text: "",                                     // 업무구분  : IVR시나리오
          blCss: "",                                    // 고객등급
        };

        /**
         * 발신전화 모달
         */
        this.CTI_SEND_CALL = {
          ci: require("@/assets/@call-ci.png"),
          first: "",                                    // 대표번호
          phone: "000-0000-0000",                       // 010-0000-0000
          text: "업무구분  : ",                         // 업무구분  : IVR시나리오
        };

        this.CTI_SEND_ASP_CUST_KEY = "";                // 발신전화 업체코드
      },

      /**
       * CIT CALL 정보 고객정보 상단 정보
       */
      initCallCtiBrd() {

        /**
         * 콜상담 상단 고객정보 표시
         */
        this.CTI_CALL_STATUS = {
          incall: true,
          img: require("@/assets/logo.png"),
          imgAlt: "",                                   // 기업명
          name: "",                                     // 고객명 홍홍홍
          phoneNumber: "",                              // 000-0000-0000
          phoneMaskNumber: "",                          // 000-****-0000
          mask: false,
        };
      },

      /**
       * 콜상담_CTI 버튼 초기화
       */
      initCallCti(e) {

        let i = 0;
        let ctiBtnLen = 0;

        let ctiBtnDisableLst = [];                      // CTI BTN 리스트
        let ctiBtnDisableLen = 0;

        let bol = false;

        if (this.CTI_OB_CHK == false && this.CTI_DB_STATE == "hangup" && this.CNSL_CALL_TY == "02" && this.CTI_OB_DISABLE == true) {

            if (!confirm("상담이력을 저장하시겠습니까?")) {
               bol = true;
            }
        }

        if (e == 'off') {
          ctiBtnDisableLst = this.CTI_BTN_DISABLED.ctiOff;
        } else if ( e == 'on' || e == 'handle') {
          ctiBtnDisableLst = this.CTI_BTN_DISABLED.ctiOn;
        } else if ( e == 'ready') {
          ctiBtnDisableLst = this.CTI_BTN_DISABLED.ctiReady;
        } else if ( e == 'ringing') {
          ctiBtnDisableLst = this.CTI_BTN_DISABLED.ctiRinging;
        } else if ( e == 'answer') {
          ctiBtnDisableLst = this.CTI_BTN_DISABLED.ctiAnswer;
        } else if ( e == 'mute') {
          ctiBtnDisableLst = this.CTI_BTN_DISABLED.ctiMute;
        } else if ( e == 'hold') {
          ctiBtnDisableLst = this.CTI_BTN_DISABLED.ctiHold;
        } else if ( e == 'hangup') {

          //ctiBtnDisableLst = this.CTI_BTN_DISABLED.ctiHangup;
          /** OB 전용_고객저장,상담저장 예외 */
          if (this.CTI_OB_CHK == true || bol) {

            this.intiCall(this.CTI_DB_STATE);           // CTI/UEI/상담저장/고객정보/고객상담이력 초기화

            this.CTI_ANSWER_BOOLEAN = false;						// CTI 로그인 버튼 활성화
            this.CTI_DB_STATE = "handle";               // 후처리 상태값 변경
            this.CTI_BRD.current = "후처리";

            ctiBtnDisableLst = this.CTI_BTN_DISABLED.ctiOn;

          } else {
            ctiBtnDisableLst = this.CTI_BTN_DISABLED.ctiHangup;
          }

        } else if ( e == 'reason') {
          ctiBtnDisableLst = this.CTI_BTN_DISABLED.ctiReason;
        }

        ctiBtnLen = this.CTI_BTN.length;
        ctiBtnDisableLen = ctiBtnDisableLst.length;

        /** CTI BTN 재 배열  */
        if (ctiBtnDisableLen > 0) {

          for(; i < ctiBtnDisableLen; i++) {

            let btn = ctiBtnDisableLst[i].desc;
            let bol = ctiBtnDisableLst[i].bol;

            if (ctiBtnLen > 0) {

              let x = 0;

              for(; x < ctiBtnLen; x++) {

                let desc = this.CTI_BTN[x].desc;

                if (desc == btn) {
                  this.CTI_BTN[x].disalb = bol;
                  break;
                }
              }
            }
          }

          /** OB 전용 대기버튼 비활성화 */
          if (e == 'handle' || this.CTI_DB_STATE == "handle") {
            this.btnCtiObChk();
          }
        }

        /** 3자 완료시 음소거, 보류 해제 호전환 버튼 비 활성화 */
        if (e == 'answer' && this.CTI_INFO.strConference == "Y") {
          this.ctiBtnTrans(true);
        }

        /** CTI 상태/시간 알림 초기화  */
          if (this.CTI_BRD.ctiTextLapse == true) { this.CTI_BRD.ctiTextLapse = false; }
          if (this.CTI_BRD.ctiTimeLapse == true) { this.CTI_BRD.ctiTimeLapse = false; }

          if (e == 'off') {
            this.ctiCallTimerEnd();                                       // 콜타이머 초기화
          } else {                                                        // CTI 경과시간 알람 상태값

            let i = 0;
            let x = 0;

            let ctiAlamStateLen = 0;                                      // CTI 타이머 알람 상태값
            let ctiAlamTextLen  = 0;                                      // CTI 상태 알림 상태값

            let bol = false;
            let textBol = false;

            ctiAlamStateLen = this.CTI_ALAM_STATE.length;
            ctiAlamTextLen = this.CTI_ALAM_TEXT.length;

            /** CTI 타이머 알람 상태 */
            if (ctiAlamStateLen > 0) {

              for(; i < ctiAlamStateLen; i++) {

                let tmpCtiState = this.CTI_ALAM_STATE[i].value;

                if (e == tmpCtiState) {
                  bol = true;
                  break;
                }
              }
            }

            /** CTI 상태 알림 상태값 */
            if (ctiAlamTextLen > 0) {

              for(; x < ctiAlamTextLen; x++) {

                let tmpCtiText = this.CTI_ALAM_TEXT[x].value;

                if (e == tmpCtiText) {
                  textBol = true;
                  break;
                }

              }
            }

            this.ctiCallTimerStart(bol, textBol);
          }
      },

      /**
       * OB_캠페인,콜백,예약콜 전역 변수 일괄 초기화
       */
      initCallOb() {

        this.initCallOutBound();                // 아웃바운드 세팅 정보
        this.initCallCallBack();                // initCallCallBack
        this.initCallResvCall();                // initCallResvCall
      },

      /**
       * 아웃바운드 세팅 초기화
       */
      initCallOutBound() {

        this.CALL_OUT_BOUND = {
          QUEST_ID : "",	                                // 설문번호
          CUST_NO : "",	                                  // 고객번호
          CUST_NM : "",	                                  // 고객명
          CAM_NM  : "",	                                  // 캠페인이름
          CAM_ID  : "",	                                  // 캠페인ID
          CENT_TY : ""                                    // 센터구분
        }
      },

      /**
       * 콜백세팅정보
       */
      initCallCallBack() {

        this.CALL_CALL_BACK ={
          CLBK_NO : ""                                      //콜백 ID
        }
      },

      /**
       * 예약콜 세팅정보
       */
      initCallResvCall() {

        this.objResvCall = {
          RESV_SEQ : "",	                                  // 예약콜ID
          FISH_YN : "",	                                    // 승인여부
          TRY_CNT : ""	                                    // 시도횟수
        }
      },

      /**
     * 콜상담_콜상담 저장 초기화
     */
    initCallCnsl() {

      this.CNSL_DISABLED = true;                            // 콜상담저장_Disabled

      this.CNSL_PROC_TY_DROPITEMS = [{text:'전체',value:''}];   // 콜상담저장_처리구분_켐페인(PROC_TY), 콜백/예약콜(EOT_TY_RLT)
      this.CNSL_PROC_RLT_DROPITEMS = [{text:'전체',value:''}];  // 콜상담저장_처리결과_켐페인(처리구분 FISH_TY), 콜백(PROC_CODE)

      this.CNSL_PROC_TY = "";                               // 콜상담저장_처리구분_켐페인(PROC_TY), 콜백/예약콜(EOT_TY_RLT)
      this.CNSL_PROC_RLT = "";                              // 콜상담저장_처리결과_켐페인(처리구분 FISH_TY), 콜백(PROC_CODE)

      this.CNSL_CALL_TY = "";                               // 콜상담저장_콜유형 선택값
      this.CNSL_ATENT_CUSTOMER = "";                        // 콜상담저장_주의고객
      this.CNSL_CNSL_TYP_CD = "";                           // 콜상담저장_상담유형
      this.CNSL_CNSL_TYP_CD_2 = "";                         // 콜상담저장_상담유형2
      this.CNSL_CNSL_TYP_CD_3 = "";                         // 콜상담저장_상담유형3
      this.CNSL_RESVE_CHK = "";                             // 콜상담저장_상담예약 체크 여부
      this.CNSL_ASP_CUST_KEY = "";                          // 콜상담저장_업체명
      this.CNSL_PROC_CODE_LCLS = "";                        // 콜상담저장_상담결과
      this.CNSL_CALL_TY_DTL = "";                           // 콜상담저장_콜유형_OB 상세 유형
      this.CNSL_PROC_OB = "";                               // 콜상담저장_캠페인/콜백/예약콜 처리구분
      this.CNSL_FISH_OB = "";                               // 콜상담저장_캠페인/콜백 처리결과
      this.CNSL_CUSTOMER_MEMO = "";                         // 콜상담저장_관리메모
      this.CNSL_CNTN = "";                                  // 콜상담저장_상담내용

      /** 콜 상담 저장 */
      this.CNSL_CALL_TY = "01";

      //콜 상담이력관련 날짜 초기화
      this.CUS_CUSTOMER_CS_TM = "";

      this.cnslResveDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      this.cnslResveTimeStep = "00:00";
      this.cnslBookDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.cnslBookTimeStep = "00:00";
      this.consBookDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.consBookTimeStep = "00:00";
      this.operBookDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.operBookTimeStep = "00:00";
      this.operationDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.operationTimeStep = "00:00";
      this.visBookDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.visBookTimeStep = "00:00";

      this.CNSL_CHN_1 = "";           // 상담채널
      this.CNSL_CHN_2 = "";           // 채널기타

      this.CNSL_CONT = "";            // 상담내용
      this.VIS_PATH = "";             // 내원경로
      this.DISA_ITEM = "";            // 진료항목
      this.CNSL_BOOK = "";            // 문의예약
      this.CONS_BOOK = "";            // 상담예약
      this.VIS_BOOK = "";             // 상담내원
      this.VIS_MANAGE = "";           // 상담내원담당
      this.OPER_BOOK = "";            // 수술예약
      this.OPER_MANAGE = "";          // 수술예약담당
      this.OPERATION = "";            // 수술
      this.VIS_TERM = "";             // 간격일수
      this.CONDITION = "";            // 상태
      this.CATEGORY = "";             // 카테고리
      this.ETC = "";                  // 특이사항,비고
      this.OPERATION_COST = "";       // 수술비용
    },

      /**
       * 콜상담_고객정보 검색 초기화
       */
      initCallCusSch() {

        if (this.CTI_ANSWER_BOOLEAN === false) {
          this.SCH_ASP_CUST_KEY = "";                         // 고객정보_고객검색_업체명
        }

        this.SCH_CUSTOMER_PHONE_NO = "";                      // 고객정보_고객검색_전화번호
        this.SCH_CUSTOMER_ID = "",                            // 고객정보_고객검색_고객ID
        this.SCH_CUSTOMER_NM = "";		                        // 고객정보_고객검색_고객명
      },

      /**
       * 콜상담_고객정보 초기화
       */
      initCallCus() {

        this.CUS_INFO_SCH   = "";                             // 조회 고객정보
        this.CUS_INFO_WRITE = "";                             // 변경 고객정보

        this.CUS_INPUT_DISABLED = true,                       // 고객정보 수정/저장 버튼_Disabled
        this.CUS_DISABLED = true,                             // 고객정보_Disabled
        this.CUS_CUSTOMER_SIDE_DISABLED = false,              // 동행
        this.CUS_PHONE_DISABLED = true,                       // 고객정보_전화번호1 걸기/복사하기
        this.CUS_OB_DISABLED = true,                          // OB 최초 고객 등록_업체코드, 전화번호 1 활성화

        this.CUS_ASP_CUST_KEY = "";                           // 고객정보_업체코드
        this.CUS_CUSTOMER_ID = "";                            // 고객정보_고객 ID
        this.CUS_CUSTOMER_NM = "";	                          // 고객정보_고객명
        this.CUS_CUSTOMER_SIDE_NM = "";	                      // 고객정보_동행자
        this.CUS_TALK_USER_KEY = "";                          // 고객정보_Key

        this.CUS_PHONE_NO = "";                               // 고객정보_전화번호 1
        this.CUS_CUSTOMER_PHONE_NO = "";                      // 고객정보_전화번호 1
        this.CUS_CUSTOMER_PHONE_NO_ENC = "";                  // 고객정보_전화번호 1 암호화
        this.CUS_WRC_TEL  = "";                               // 고객정보_전화번호 2
        this.CUS_WRC_TEL_NO  = "";				                    // 고객정보_전화번호 2
        this.CUS_WRC_TEL_NO_ENC = "";                         // 고객정보_전화번호 2 암호화

        this.CUS_CUSTOMER_GENDER = "";			                  // 고객정보_성별
        this.CUS_CUSTOMER_AGE = "";			                      // 고객정보_연령
        this.CUS_CUSTOMER_LOCAL = "";			                    // 고객정보_지역

        this.CUS_CUSTOMER_CS_TM = "",

        this.CUS_ATENT_CD = "";                               // 고객정보_주의고객코드 (B/L)
        this.OLDCUS_ATENT_CD = "";                            // 고객정보_주의고객코드 (B/L)
        this.CUS_CNSL_EOT_DATE = "";                          // 고객정보_최근 상담일시
        this.CUS_CNSL_CNTN = "";                              // 고객정보_상담정보
        this.CUS_CUSTOMER_USER_MEMO = "";                     // 고객정보_특이사항
        this.CUS_CUSTOMER_MEMO = "";                          // 고객정보_내용
        this.OLD_CUS_CUSTOMER_MEMO = "";                      // 고객정보_내용
      },

      /**
       * 콜상담_고객상담이력_검색초기화
       */
      initCallCnslSch() {

        this.CNSL_CHANNEL_TY_SCH = "";                       // 고객정보_고객상담이력_검색_채녈유형
        this.CNSL_PROC_CODE_LCLS_SCH = "";                   // 고객정보_고객상담이력_검색_상담결과
      },

      /**
       * 콜상담_고객상담이력_상세검색초기화
       */
      initCallCnslDtlSch() {

        this.CNSL_EOT_DATE_SCH = [                            // 고객정보_고객상담이력_상세검색_조회기간
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      ],
        this.CNSL_CHANNEL_TY_DTLSCH = ""; 	                // 고객정보_고객상담이력_상세검색_채널유형
        this.CNSL_PROC_CODE_LCLS_DTLSCH = "";               // 고객정보_고객상담이력_상세검색_상담결과
        this.CNSL_REG_MAN_DTLSCH = "";                      // 고객정보_고객상담이력_상세검색_상담사
        this.CNSL_CALL_TY_DTLSCH = "";                      // 고객정보_고객상담이력_상세검색_유형 (OB)
      },

      /**
       * 고객상담이력 초기화
       */
      initCallCnslLst() {

        this.CNSL_LST = [];
        this.CALL_PHN_CNSL_HISTORY = [];
        this.CALL_PHN_CNSL_DTL = []
      },

    /****************************************************************************************************************************************************
     * 콜 methods END
     ***************************************************************************************************************************************************/


    //일시 모달
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}/${month}/${day}`;
    },
    startDate(e) {
      //this.dates[0] = e;
      this.DET_SRC_dates[0] = e;
    },
    endDate(e) {
      //this.dates[1] = e;
      this.DET_SRC_dates[1] = e;
    },
    allowedStep: (m) => m % 10 === 0,
    chatmainCsCall(e) {
      //console.log("전화번호1", e);
    },

    // 채팅 상단 후처리, 책갈피
    showAlertWrite() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '후처리로 <span class="clr-sucess">전환</span> 하시겠습니까?',
        iconClass: "svg-chk-lg",
        type: "confirm",
        callYes: this.callYes,
        callNo: this.callNo,
      });
    },
    showAlertBookmark() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '책갈피를 <span class="clr-sucess">등록</span> 하시겠습니까?',
        iconClass: "svg-chk-lg",
        type: "confirm",
        callYes: this.callYes,
        callNo: this.callNo,
      });
    },
    callYes() {
      //console.log("call yes");
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
      this.markUp();
    },
    callNo() {
      //console.log("call No");
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },
    // 상담현황
    FnChatListAll() {
      this.chatListFilter = "all";
    },
    FnChatListIng() {
      this.chatListFilter = "ing";
    },
    FnChatListDone() {
      this.chatListFilter = "done";
    },
    FnChatListAfter() {
      this.chatListFilter = "after";
    },
    FnChatListBookmark() {
      this.chatListFilter = "bookmark";
    },
    FnChatListCallback() {
      this.chatListFilter = "callback";
    },
    // 채팅상담 맨위로
    onScroll(e) {
      this.scroll = e.target.scrollTop;
    },
    scrlToTop() {
      this.scrollTarget = document.querySelector(
        ".layout-chatmain--chat-history-box"
      );

      if (this.scrollTarget) {
        this.scrollTarget.scrollTop = 0;
      }
    },
    scrlToBottom() {
      this.scrollTarget = document.querySelector(
        ".layout-chatmain--chat-history-box"
      );

      if (this.scrollTarget) {
        console.log("this.scrollTarget.scrollHeight", this.scrollTarget.scrollHeight);
        this.scrollTarget.scrollTop = this.scrollTarget.scrollHeight;
      }
    },
    FnChatHistoryTextsize(e) {
      this.chatHistoryTextsize = e;
      this.chatFontsize = false;
    },

    //220525 김태준 채팅 이벤트 추가
    //

    //상담원 전체 고객사 키
    fullCust() {
      let CUST = "";
      for (
        let i = 0;
        i < this.$store.getters["userStore/GE_USER_ROLE"].company.length;
        i++
      ) {
        if (i == 0) {
          CUST = this.$store.getters["userStore/GE_USER_ROLE"].company[i].CD;
        } else {
          CUST =
            CUST +
            "," +
            this.$store.getters["userStore/GE_USER_ROLE"].company[i].CD;
        }
      }

      return CUST;
    },

    /**
     * 상담원 상태 변경
     */
    changeUserStat(showMsg, userStatCd) {
      if (userStatCd == null || userStatCd == "") {
        userStatCd = this.dropItemsUserStatCd;
      }

      //20210106
      let simsessionId = this.$store.state.messages.simsessionId;

      apiChat
        .post(
          "api/chat/main/cnslr-sttus/regist",
          {
            USER_STATUS_CD: userStatCd,
            USER_ID: this.$store.getters["userStore/GE_USER_ID"],
            ASP_NEWCUST_KEY: this.getAspCustKey(),
          },
          {
            head: {
              SERVICE: "api.chat.main.cnslr-sttus",
              METHOD: "regist",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((ret) => {
          /* 처리후 메시지 보여줄지 여부 */
          if (showMsg) {
            let count = ret.data.header.COUNT;
            let errorFlag = ret.data.header.ERROR_FLAG;

            if (count != null && errorFlag == false) {
              if (count >= 0) {
                this.showAlertComplete("상태가 변경 되었습니다.");
                //console.log(
                //  "this.dropItemsUserStatCd",
                //  this.dropItemsUserStatCd
                //);
                if (this.dropItemsUserStatCd == "CHATON") {
                  this.setTalkEnv(); // timeout 정보
                  this.connect(); // ready websocket 연결
                }
              } else {
                alert("상태가 실패 되었습니다.");
              }
            }
          }
        });
    },

    //상담 목록 조회
    getTalkList() {
      apiChat
        .post(
          "api/chat/main/cnslt/inqire", //api url입력
          {
            TYPE: "ready;talking;callback;post",
            SORT_BY: "TALK_DIST_DT",
            DECENDING: "ASC",
            TALK_USER_KEY: "",
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY: this.CUST,
          },
          { head: {} }
        )
        .then((response) => {

          //console.log("결과>>>>>>>>>>>", response.data.data);

          this.chatWaitList = [];
          this.chatList = [];

          let i = 0;
          let dataLen = response.data.data.length;

          for (; i < dataLen; i++) {

            if (response.data.data[i].TALK_STAT_CD == "11") {

              let WAIT_LENGTH = this.chatWaitList.length;
              this.chatWaitList[WAIT_LENGTH] = response.data.data[i];
              this.chatWaitList[WAIT_LENGTH]["type"] = "wait";

              this.chatWaitList[WAIT_LENGTH]["index"] = i + 1;

              if (this.chatWaitList[WAIT_LENGTH]["CALL_TYP_CD"] == "KAKAOBZC") {
                this.chatWaitList[WAIT_LENGTH]["CHN_ICON"] = "chat";
              }

            } else {

              let CHAT_LENGTH = this.chatList.length;
              this.chatList[CHAT_LENGTH] = response.data.data[i];

              this.chatList[CHAT_LENGTH]["index"] = i + 1;

              if (this.chatList[CHAT_LENGTH]["CALL_TYP_CD"] == "KAKAOBZC") {
                this.chatList[CHAT_LENGTH]["CHN_ICON"] = "chat";
              }

              if (this.chatList[CHAT_LENGTH].TALK_STAT_CD == "12") {
                this.chatList[CHAT_LENGTH]["type"] = "ing";
              } else if (this.chatList[CHAT_LENGTH].TALK_STAT_CD == "21") {
                this.chatList[CHAT_LENGTH]["type"] = "done";
              } else if (this.chatList[CHAT_LENGTH].TALK_STAT_CD == "20") {
                this.chatList[CHAT_LENGTH]["type"] = "after";
              }

              if (this.chatList[CHAT_LENGTH].MARK_UP == "Y") {
                this.chatList[CHAT_LENGTH]["bookmark"] = "bookmark";
              }

              if (this.chatList[CHAT_LENGTH].EXEC_RST_CD == "4") {
                this.chatList[CHAT_LENGTH]["callback"] = "callback";
              }
            }
          }

          this.$store
            .dispatch("messages/AC_INIT_TALK_LIST", response.data.data)
            .then(() => {
              this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", {
                selected_stat: this.selected_stat,
                userId: this.$store.getters["userStore/GE_USER_ID"],
                aspCustKey: this.CUST,
              });
            });

          if (this.CLK_WAIT == 1) {
            //.log("if this.chatList", this.chatList);
            this.clickChat(0);
            this.CLK_WAIT = 0;
          }
          //console.log("this.chatWaitList>>>><<<", this.chatWaitList);
          //console.log("this.chatList>>>><<<", this.chatList);
        })
        .catch((err) => {
          console.log("결과<<<<<", err);
          alert(err);
        });
    },

    /**
     * 상담 클릭 시 이벤트
     */
    clickChatWait(i) {
      this.CLK_WAIT = 1;

      this.CLK_CUST = this.chatWaitList[i].ASP_CUST_KEY;
      this.CLK_CHAT = this.chatWaitList[i].TALK_CONTACT_ID;
      this.CLK_USER = this.chatWaitList[i].TALK_USER_KEY;
      this.CLK_SENDER = this.chatWaitList[i].ASP_SENDER_KEY;
      this.CLK_STAT = this.chatWaitList[i].TALK_STAT_CD;
      this.CLK_ICON = this.chatWaitList[i].ICON;
      this.CLK_IMG = this.chatWaitList[i].IMG;
      this.CLK_MSGSKIP = this.chatWaitList[i].SYSMSG_SKIP_YN;
      this.MARK_UP = this.chatWaitList[i].MARK_UP;
      //this.SITE = this.chatWaitList[i].CUSTOMER_LOCAL;

      this.CNSL_TY1 = [{ CD: "", CD_NM: "전체" }];
      this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
      this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
      this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
      this.getCnslTY("1", "*");

      this.getTalkMsg();
      this.getTalkInfo();
      this.getUserInfo();
      this.getTalkHist("", "");
      this.scrlToBottom();
    },

    /**
     *
     */
    clickChat(i) {
      this.CANT_CUST = true;
      if (this.chatList[i].TALK_STAT_CD != "" && this.chatList[i].TALK_STAT_CD != undefined ){
        if (this.chatList[i].TALK_STAT_CD == "20") {
          this.CANT_MODI = true;
          this.chatDone = true;
          this.CANT_END = false;
        } else if(this.chatList[i].TALK_STAT_CD == "21") {
          this.CANT_MODI = true;
          this.chatDone = false;
          this.CANT_END = true;
        } else {
          this.CANT_MODI = false;
          this.chatDone = false;
          this.CANT_END = false;
        }
      }
      this.CLK_CUST = this.chatList[i].ASP_CUST_KEY;
      this.SRH_ASP_CUST = this.chatList[i].ASP_CUST_KEY;
      this.CLK_CHAT = this.chatList[i].TALK_CONTACT_ID;
      this.CLK_USER = this.chatList[i].TALK_USER_KEY;
      this.CLK_SENDER = this.chatList[i].ASP_SENDER_KEY;
      this.CLK_STAT = this.chatList[i].TALK_STAT_CD;
      this.CLK_ICON = this.chatList[i].ICON;
      this.CLK_IMG = this.chatList[i].IMG;
      this.CLK_MSGSKIP = this.chatList[i].SYSMSG_SKIP_YN;
      this.MARK_UP = this.chatList[i].MARK_UP;
      //this.SITE = this.chatWaitList[i].CUSTOMER_LOCAL;

      this.CNSL_TY1 = [{ CD: "", CD_NM: "전체" }];
      this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
      this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
      this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
      this.getCnslTY("1", "*");

      this.getTalkMsg();
      this.getTalkInfo();
      this.getUserInfo();
      this.getPhnCnsl('','','','chat')
      this.getTalkList();
      this.scrlToBottom();
    },

    //상담 정보 조회
    getTalkMsg() {
      apiChat
        .post(
          "api/chat/main/cnslt-info/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.CLK_CUST,
            TALK_CONTACT_ID: this.CLK_CHAT == "" ? "" : this.CLK_CHAT,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY: this.CUST,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/chat/main/cnslt-info/inqire",
            method: "post",
          },
          { head: {} }
        )
        .then((response) => {

          //console.log("채팅방 정보<<<<<", response.data.data);

          if (response.data.data.length != 0) {
            this.CNSL_NEWTY = response.data.data[0].CNSL_TYP_CD;
            this.CNSL_NEWTY2 = response.data.data[0].CNSL_TYP_CD_2;
            this.CNSL_NEWTY3 = response.data.data[0].CNSL_TYP_CD_3;
            this.CNSL_NEWTY4 = response.data.data[0].CNSL_TYP_CD_4;
          } else {
            this.getTalkList();
          }
        })
        .catch((err) => {
          alert(err);
        });
    },

    /**
     * 상담 상세 정보
     */
    getTalkInfo() {
      apiChat
        .post(
          "api/chat/main/cnslt-cn/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.CLK_CUST,
            TALK_CONTACT_ID: this.CLK_CHAT == "" ? "" : this.CLK_CHAT,
            TALK_USER_KEY: this.CLK_USER,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY: this.CUST,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/chat/main/cnslt-cn/inqire",
            method: "post",
          },
          { head: {} }
        )
        .then((response) => {

          //console.log("채팅정보>>>>>>>>", response.data.data);

          this.talkHistory = response.data.data;

          let i = 0;
          let lstLen = this.chatList.length;

          for (; i < lstLen; i++) {
            //this.talkHistory[i]["index"] = i + 1;
          }


        })
        .catch((err) => {
          alert(err);
        });
    },

    //상담 이력 조회
    getTalkHist(n, tf, chat) {
      let DET_SRC = "";
      let ST_DATE_FORM = this.DET_SRC_dates[0].replace(/-/g, "/");
      let END_DATE_FORM = this.DET_SRC_dates[1].replace(/-/g, "/");
      if (chat == "detail") {
        DET_SRC = "Y";
      } else {
        DET_SRC = "";
      }

      apiChat
        .post(
          "api/chat/main/cnslt-hist/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.CLK_CUST,
            TALK_USER_KEY: this.CLK_USER,
            CNSL_DIV: "",
            CSTMR_ID: this.CHAT_CUS_ID,
            CSTMR_TELNO: "",
            CALL_TY: "",
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY: this.CUST,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/chat/main/cnslt-hist/inqire",
            method: "post",
            DET_SRC: DET_SRC,
            ST_DATE: ST_DATE_FORM,
            END_DATE: END_DATE_FORM,
            RESULT: this.DET_SRC_RESULT,
            AGENT_ID: this.DET_SRC_AGENT_ID,
            CHENNEL: this.DET_SRC_CHENNEL,
            CHAT_RESULT: this.DET_SRC_CHAT_RESULT,
          },
          { head: {} }
        )
        .then((response) => {
          //console.log("상담이력<<<<>>>>", response.data.data);
          let LIST = this.chatHistory;
          this.chatHistory = response.data.data;
          if (response.data.data.length != 0) {
            this.RCT_CHAT_CON = this.decode(response.data.data[0].HST_CONTEXT);
            this.RCT_CHAT_DATE = response.data.data[0].REG_DTTM;
          }

          let i = 0;
          let dataLen = response.data.data.lengt;

          for (; i < dataLen; i++) {

            if (n == "" || n == null) {
              this.chatHistory[i]["EXPAND"] = false;
            } else {
              this.chatHistory[i]["EXPAND"] = LIST[i].EXPAND;
              this.chatHistory[n - 1]["EXPAND"] = tf;
            }
            this.chatHistory[i]["ID"] = i + 1;
            if (response.data.data[i].HST_TYPE == "kakao") {
              this.chatHistory[i]["ICON"] = "svg-chat-icon-kakao";
            } else if (response.data.data[i].HST_TYPE == "line") {
              this.chatHistory[i]["ICON"] = "svg-chat-icon-line";
            } else if (response.data.data[i].HST_TYPE == "wechat") {
              this.chatHistory[i]["ICON"] = "svg-chat-icon-wechat";
            } else if (response.data.data[i].HST_TYPE == "ttalk") {
              this.chatHistory[i]["ICON"] = "svg-chat-icon-ttalk";
            }

            if (
              this.chatHistory[i]["PROC_CODE_NM"] == "완결" ||
              this.chatHistory[i]["PROC_CODE_NM"] == "전달"
            ) {
              this.chatHistory[i]["PROC_CODE"] = "done";
            } else if (this.chatHistory[i]["PROC_CODE_NM"] == "미결") {
              this.chatHistory[i]["PROC_CODE"] = "undone";
            } else if (this.chatHistory[i]["PROC_CODE_NM"] == "콜백") {
              this.chatHistory[i]["PROC_CODE"] = "ing";
            }
          }
          //console.log("chatHistory상담이력<<<<>>>>", this.chatHistory);
        })
        .catch((err) => {
          alert(err);
        });
    },

    //고객정보 조회
    getUserInfo() {
      apiChat
        .post(
          "api/chat/main/cstmr-info/inqire/" + this.CLK_USER, //api url입력
          {
            ASP_NEWCUST_KEY: this.CLK_CUST,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY: this.CUST,
            CUSTOMER_ID: this.SRH_CUST_ID,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/chat/main/cstmr-info/inqire/" + this.CLK_USER,
            method: "post",
          },
          { head: {} }
        )
        .then((response) => {
          //console.log("사용자 정보>>>><<<<", response.data.data);
          this.USER_NM = response.data.data[0].CUSTOMER_NM;
          this.USER_MEMO = response.data.data[0].MEMO;
          this.USER_TEL_NO = response.data.data[0].CUSTOMER_PHONE_NO;
          this.address = response.data.data[0].ADDRESS;
          this.detailAddress = response.data.data[0].DETAILADDRESS;
          //this.extraAddress = response.data.data[0].EXTRAADDRESS;  //추후 DB추가
          //this.extraAddress = response.data.data[0].POST_CODE;     //추후 DB추가
          this.EMAIL = response.data.data[0].EMAIL;
          this.USER_NEWBL = response.data.data[0].ATENT_CUSTOMER;
          this.CHAT_CUS_ID = response.data.data[0].CUSTOMER_ID;
          if (response.data.data[0].ATENT_CUSTOMER == "Y") this.CHK_BL = true;
        })
        .catch((err) => {
          alert(err);
        });
    },

    //상담이력 조회
    getTalkHistList(index, key) {
      apiChat
        .post(
          "api/chat/main/cnslt-hist/chat-inqire/" + key, //api url입력
          {
            ASP_NEWCUST_KEY: this.CLK_CUST,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY: this.CUST,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/chat/main/cnslt-hist/chat-inqire/" + key,
            method: "post",
          },
          { head: {} }
        )
        .then((response) => {
          //console.log("상담이력<<<<>>>>", response.data.data);
          //console.log("index", index);
          this.talkHistList[index] = response.data.data;
          //console.log("이력메시지목록<<<<>>>>", this.talkHistList);
        })
        .catch((err) => {
          alert(err);
        });
    },

    //고객정보 저장
    regiUserInfo() {
      //if(or == 'chat'){
      let BL = "";
      if (this.CHK_BL == true) {
        BL = "Y";
      } else if (this.CHK_BL == false) {
        BL = "N";
      }
      let atentNewCustomer = '';
      if (this.CUS_ATENT_CD.length > 0) {
        if (this.CUS_ATENT_CD != this.OLD_CUS_ATENT_CD) {
          atentNewCustomer = "Y";
        }
      }
      apiChat
        .post(
          //"api/phone/main/cstmr-info/insert" + this.CLK_USER, //api url입력
          "api/phone/main/cstmr-info/insert", //api url입력
          {
            ASP_NEWCUST_KEY: this.CLK_CUST,
            ASP_SENDER_KEY: this.CLK_SENDER,
            ATENT_CUSTOMER: this.USER_NEWBL,
            CUSTOMER_ID: this.CLK_USER,
            CUSTOMER_NM: this.USER_NM,
            CUSTOMER_PHONE_NO: this.USER_TEL_NO.replace(/\-/g, ""),
            WRC_TEL_NO          : this.USER_TEL_NO2.replace(/-/g, ''),                                            // 전화번호 2
            GENDER_CD           : this.CLK_CUSTOMER_GENDER,                                                         // 성별
            CUSTOMER_AGE        : this.CLK_CUSTOMER_AGE,                                                            // 연령
            ATENT_CD            : this.USER_NEWBL,                                                                // 주의고객
            ATENT_NEWCUSTOMER   : atentNewCustomer,                                                                 // 등급
            PROC_ID             : this.$store.getters["userStore/GE_USER_ROLE"].userId,
            CUSTOMER_LOCAL      : this.SITE,                                                          // 지역
            MEMO: this.USER_MEMO,
            TALK_USER_KEY: this.CLK_USER,
            EMAIL: this.EMAIL,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_USER_NM: this.$store.getters["userStore/GE_USER_ROLE"].userNm,
            ASP_CUST_KEY: this.CUST,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/chat/main/cstmr-info/regist/" + this.CLK_USER,
            method: "post",

          },
          { head: {} }
        )
        .then((response) => {
          //console.log("사용자 정보>>>><<<<", response.data.data);
          this.success();
          this.chatmainChatEdit = true;
        })
        .catch((err) => {
          alert(err);
        });
      //}
    },

    //고객정보 저장 확인
    showAlertReg() {
      if (this.chatmainChatEdit == false) {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: "고객정보를 저장하시겠습니까?",
          iconClass: "svg-chk-lg",
          type: "confirm",
          callYes: this.callYes1,
          callNo: this.callNo1,
        });
      } else if (this.chatmainChatEdit == true) {
        this.chatmainChatEdit = false;
      }
    },

    //고객정보 저장 [확인] 클릭
    callYes1() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
      this.regiUserInfo();
    },

    //고객정보저장 [취소]클릭
    callNo1(or) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
      if (or == "chat") {
        this.chatmainChatEdit = true;
      }
    },

    /**
     * 성공메시지
     */
    success() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: "정상처리 되었습니다.",
        iconClass: "svg-done-lg",
        type: "default",
      });
    },

    showDialogImg(menu) {
      let dataObj = {
        CLK_CUST: this.CLK_CUST,
        CLK_CHAT: this.CLK_CHAT,
        CLK_USER: this.CLK_USER,
        CLK_SENDER: this.CLK_SENDER,
        CLK_MSGSKIP: this.CLK_MSGSKIP,
        CLK_STAT: this.CLK_STAT,
        CLK_ICON: this.CLK_ICON,
        CLK_IMG: this.CLK_IMG,
      };
      this.Imgdata = dataObj;
      //console.log(this.Imgdata);
      this[`Dialog${menu}`] = true;
    },

    /**
     * 시스템 메시지 발송
     */
    SystemMsgSkip() {
      let MSGSKIP_YN = "";
      if (this.CLK_MSGSKIP == "Y") {
        MSGSKIP_YN = "N";
      } else if (this.CLK_MSGSKIP == "N") {
        MSGSKIP_YN = "Y";
      }
      apiChat
        .post(
          "api/chat/main/un-rspns-mssage-sndng-setup/process", //api url입력
          {
            TALK_CONTACT_ID: this.CLK_CHAT,
            SYSMSG_SKIP_YN: MSGSKIP_YN,
            ASP_NEWCUST_KEY: this.CLK_CUST,
            ASP_CUST_KEY: this.CLK_CUST,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/chat/main/un-rspns-mssage-sndng-setup/process",
            method: "post",
          },
          { head: {} }
        )
        .then((response) => {
          //console.log("시스템메시지 발송<<<<>>>>", response.data.data);
          if (MSGSKIP_YN == "N") {
            this.successSysMsg("시스템 메시지 발송이 중지되었습니다.");
            this.CLK_MSGSKIP = "N";
          } else if (MSGSKIP_YN == "Y") {
            this.successSysMsg("시스템 메시지가 발송됩니다.");
            this.CLK_MSGSKIP = "Y";
          }
        })
        .catch((err) => {
          alert(err);
        });
    },

    /**
     * 시스템 메시지 발송 성공
     */
    successSysMsg(successMsg) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: successMsg,
        iconClass: "svg-done-lg",
        type: "default",
      });
    },

    //종료링크 보내기
    sendEndLink() {
      let strMsg = "고객님께 종료 버튼을 전송하였습니다.";

      let MSG_INFO = {
        msg: strMsg,
        message_type: "LI",
        SNDRCV_CD: "SND",
        type: "chatClose",
      };
      let CHAT_INFO = {
        talk_contact_id: this.CLK_CHAT,
        talk_sender_key: this.CLK_SENDER,
        call_typ_cd: "KAKAOBZC",
        aspNewCustKey: this.CLK_CUST,
        talk_user_key: this.CLK_USER,
        SEND_USER_NM: this.USER_NM,
        token: this.$store.state.messages.token,
        userId: this.$store.getters["userStore/GE_USER_ROLE"].userId,
      };
      let chatmsg = this.$store.getters["messages/GE_MSG_OBJ"]({
        user_key: CHAT_INFO.talk_user_key,
        sndrcv_cd: "snd",
        type: MSG_INFO.message_type,
        content: MSG_INFO.msg,
        talk_contact_id: CHAT_INFO.talk_contact_id,
        IMAGE_TALK_PATH:
          MSG_INFO.IMAGE_TALK_PATH != "" ? MSG_INFO.IMAGE_TALK_PATH : "",
        FILE_GROUP_KEY:
          MSG_INFO.FILE_GROUP_KEY != "" ? MSG_INFO.FILE_GROUP_KEY : "",
        FILE_KEY: MSG_INFO.FILE_KEY != "" ? MSG_INFO.FILE_KEY : "",
        FILE_BLOB: MSG_INFO.FILE_BLOB != "" ? MSG_INFO.FILE_BLOB : "",
        FILE_EXTS: MSG_INFO.FILE_EXTS != "" ? MSG_INFO.FILE_EXTS : "",
        //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
        SEND_USER_NM: CHAT_INFO.SEND_USER_NM,
        SEND_USER_SHORT_NM: "나",
        consultEnd: MSG_INFO.consultEnd,
        ASP_NEWCUST_KEY: CHAT_INFO.aspNewCustKey,
      });

      let msgObj = this.$store.getters["messages/GE_CONTACT_MSG"]({
        talk_contact_id: CHAT_INFO.talk_contact_id,
      });

      stompUtil.sendMessage(MSG_INFO, CHAT_INFO, chatmsg, msgObj);
    },

    //전달 시 채팅상담 가능 상담사 목록 조회
    getUserSelect() {
      apiChat
        .post(
          "api/chat/common-vue/chaton-agents/list", //api url입력
          {
            ASP_NEWCUST_KEY: this.CLK_CUST,
            KEYWORD: "",
            ASP_CUST_KEY: this.CLK_CUST,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/chat/common-vue/chaton-agents/list",
            method: "post",
          },
          { head: {} }
        )
        .then((response) => {
          //console.log("사용자 선택박스 생성<<<<>>>>", response.data.data);
          this.USER_ID = response.data.data[0];
        })
        .catch((err) => {
          alert(err);
        });
    },

    //전달 확인
    confirmTrans() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: this.USER_NEWID + "님께 채팅을 전달하시겠습니까?",
        iconClass: "svg-chk-lg",
        type: "confirm",
        callYes: this.chatTransToUser,
        callNo: this.callNo1,
      });
    },

    //채팅 전달
    chatTransToUser() {
      apiChat
        .post(
          "api/stomp/chat/agent/forwarding", //api url입력
          {
            CNSL_TYP_CD: this.CNSL_NEWTY1,
            CNSL_TYP_CD_2: this.CNSL_NEWTY2,
            CNSL_TYP_CD_3: this.CNSL_NEWTY3,
            CNSL_TYP_CD_4: this.CNSL_NEWTY4,
            EXEC_RST_CD: "3",
            TALK_ANS: this.CHT_TIT,
            TALK_QST: this.CHT_CON,
            TALK_CONTACT_ID: this.CLK_CHAT,
            agent_id: this.USER_NEWID,
            ASP_NEWCUST_KEY: this.CLK_CUST,
            ASP_CUST_KEY: this.CLK_CUST,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/stomp/chat/agent/forwarding",
            method: "post",
          },
          { head: {} }
        )
        .then((response) => {
          this.successSysMsg("채팅을 전달하였습니다.");
        })
        .catch((err) => {
          alert(err);
        });
    },

    //상담유형1
    chnCnsl1() {
      this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
      this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
      this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
      this.getCnslTY("2", this.CNSL_NEWTY1);
    },
    //상담유형2
    chnCnsl2() {
      this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
      this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
      this.getCnslTY("3", this.CNSL_NEWTY1);
    },
    //상담유형3
    chnCnsl3() {
      this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
      this.getCnslTY("4", this.CNSL_NEWTY1);
    },

    //사용자 상담유형 콤보박스 생성
    getCnslTY(num, cnsl) {
      if (num == "1") {
        this.CNSL_TY1 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY1 = "";
        this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY2 = "";
        this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY3 = "";
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY4 = "";
        let TY_CD = "*";
      } else if (num == "2") {
        this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY2 = "";
        this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY3 = "";
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY4 = "";
        let TY_CD = cnsl;
      } else if (num == "3") {
        this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY3 = "";
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY4 = "";
        let TY_CD = cnsl;
      } else if (num == "4") {
        this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
        this.CNSL_NEWTY4 = "";
        let TY_CD = cnsl;
      }
      api
        .post(
          "api/setting/consulttype/manage/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.CLK_CUST,
            SPST_CNSL_TYP_CD: cnsl,
            USE_YN: "Y",
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY:
              this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
          },
          {
            head: {
              SERVICE: "setting.consulttype.manage",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          //console.log(response.data.DATA);
          if (num == "1") {
            this.CNSL_TY1 = [...this.CNSL_TY1, ...response.data.DATA];
          } else if (num == "2") {
            if (this.CNSL_NEWTY1 == "") {
              this.CNSL_TY2 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY2 = "";
              this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY3 = "";
              this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY4 = "";
            } else {
              this.CNSL_TY2 = [...this.CNSL_TY2, ...response.data.DATA];
            }
          } else if (num == "3") {
            if (this.CNSL_NEWTY2 == "") {
              this.CNSL_TY3 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY3 = "";
              this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY4 = "";
            } else {
              this.CNSL_TY3 = [...this.CNSL_TY3, ...response.data.DATA];
            }
          } else if (num == "4") {
            if (this.CNSL_NEWTY3 == "") {
              this.CNSL_TY4 = [{ CD: "", CD_NM: "전체" }];
              this.CNSL_NEWTY4 = "";
            } else {
              this.CNSL_TY4 = [...this.CNSL_TY4, ...response.data.DATA];
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    },

    //상담유형 초기화
    //취소 선택시 폼 초기화 ( 상담제목/내용 초기화 추가 )
    initCnsl() {
      this.CNSL_NEWTY1 = "";
      this.CNSL_NEWTY2 = "";
      this.CNSL_NEWTY3 = "";
      this.CNSL_NEWTY4 = "";

      this.CHT_NEWRESULT = "";
      this.CHT_TIT = "";
      this.CHT_CON = "";
    },

    //메시지 전송
    sendMsg() {
      let msgcopy = this.CHAT_MSG;
      if (msgcopy.replace(/(?:\r\n|\r|\n)/g, "").length === 0) {
        return false;
      }

      let MSG_INFO = {
        msg: msgcopy,
        message_type: "TX",
        SNDRCV_CD: "SND",
        type: "message",
      };
      let CHAT_INFO = {
        talk_contact_id: this.CLK_CHAT,
        talk_sender_key: this.CLK_SENDER,
        call_typ_cd: "KAKAOBZC",
        aspNewCustKey: this.CLK_CUST,
        talk_user_key: this.CLK_USER,
        SEND_USER_NM: this.USER_NM,
        token: this.$store.state.messages.token,
        userId: this.$store.getters["userStore/GE_USER_ROLE"].userId,
      };

      let chatmsg = this.$store.getters["messages/GE_MSG_OBJ"]({
        user_key: CHAT_INFO.talk_user_key,
        sndrcv_cd: "snd",
        type: MSG_INFO.message_type,
        content: MSG_INFO.msg,
        talk_contact_id: CHAT_INFO.talk_contact_id,
        IMAGE_TALK_PATH:
          MSG_INFO.IMAGE_TALK_PATH != "" ? MSG_INFO.IMAGE_TALK_PATH : "",
        FILE_GROUP_KEY:
          MSG_INFO.FILE_GROUP_KEY != "" ? MSG_INFO.FILE_GROUP_KEY : "",
        FILE_KEY: MSG_INFO.FILE_KEY != "" ? MSG_INFO.FILE_KEY : "",
        FILE_BLOB: MSG_INFO.FILE_BLOB != "" ? MSG_INFO.FILE_BLOB : "",
        FILE_EXTS: MSG_INFO.FILE_EXTS != "" ? MSG_INFO.FILE_EXTS : "",
        //, SEND_USER_NM : Vue.prototype.store.state.messages.userNm
        SEND_USER_NM: CHAT_INFO.SEND_USER_NM,
        SEND_USER_SHORT_NM: "나",
        consultEnd: MSG_INFO.consultEnd,
        ASP_NEWCUST_KEY: CHAT_INFO.aspNewCustKey,
      });

      let msgObj = this.$store.getters["messages/GE_CONTACT_MSG"]({
        talk_contact_id: CHAT_INFO.talk_contact_id,
      });

      stompUtil.sendMessage(MSG_INFO, CHAT_INFO, chatmsg, msgObj);
      this.CHAT_MSG = "";

      this.getTalkList();
      this.getTalkInfo();
      this.scrlToBottom();
    },

    //상담이력 저장(임시저장,후처리,콜백,)
    saveChat(num) {
      let TIME = this.computedDateFormatted2;
      let TIME_ARR = TIME.split("-");
      let TIME_ARR_ARR = TIME_ARR[2].split(" ");
      let TIME_ARR_ARR_ARR = TIME_ARR_ARR[1].split(":");

      apiChat
        .post(
          "api/chat/main/cnslt-hist/regist", //api url입력
          {
            CNSL_TYP_CD: this.CNSL_NEWTY1,
            CNSL_TYP_CD_2: this.CNSL_NEWTY2,
            CNSL_TYP_CD_3: this.CNSL_NEWTY3,
            CNSL_TYP_CD_4: this.CNSL_NEWTY4,
            EXEC_RST_CD: num,
            TALK_ANS: this.CHT_TIT,
            TALK_QST: this.CHT_CON,
            TALK_CONTACT_ID: this.CLK_CHAT,
            TALK_STAT_CD: 12,
            CALLBACK_YMD: TIME_ARR[0] + TIME_ARR[1] + TIME_ARR_ARR[0],
            CALLBACK_HH: TIME_ARR_ARR_ARR[0],
            CALLBACK_MM: TIME_ARR_ARR_ARR[1],
            CNSL_DIV: "",
            CSTMR_TELNO: this.USER_TEL_NO,
            CSTMR_ID: this.CLK_USER,
            ASP_NEWCUST_KEY: this.CLK_CUST,
            ASP_CUST_KEY: this.CLK_CUST,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/chat/main/cnslt-hist/regist",
            method: "post",
          },
          { head: {} }
        )
        .then((response) => {
          if (this.CLK_STAT == "12" && num != "4") {
            apiChat
              .post(
                "api/chat/main/end-mssage/inqire", //api url입력
                {
                  ASP_NEWCUST_KEY: this.CLK_CUST,
                  system_id: "20180403040958193MSG34895",
                  ASP_CUST_KEY: this.CLK_CUST,
                  USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
                  ASP_USER_ID:
                    this.$store.getters["userStore/GE_USER_ROLE"].userId,
                  action: "api/chat/main/end-mssage/inqire",
                  method: "post",
                },
                { head: {} }
              )
              .then((response) => {
                let MSG = {
                  type: "system",
                  message_type: "TX",
                  SNDRCV_CD: "SND",
                  type: "message",
                  consultEnd: "Y",
                  msg: response.data.data,
                };
                stompUtil.sendMessage(MSG);
              })
              .catch((err) => {
                alert(err);
              });
          } else {
            if (num == "4") {
              this.successSysMsg("콜백이 저장되었습니다.");

              this.getTalkList();

              this.CHT_NEWRESULT = "";
              this.CNSL_NEWTY1 = "";
              this.CNSL_NEWTY2 = "";
              this.CNSL_NEWTY3 = "";
              this.CNSL_NEWTY4 = "";
              this.CHT_NEWRESULT = "";
              this.CHT_TIT = "";
              this.CHT_CON = "";
              this.date = new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
              )
                .toISOString()
                .substr(0, 10);
              this.timeStep = "00:00";
            } else {
              this.successSysMsg("상담이력이 저장되었습니다.");
              this.chatDone = false;
            }

            this.getTalkList();

            this.talkHistory = [];

            this.CLK_CUST = "";
            this.CLK_CHAT = "";
            this.CLK_USER = "";
            this.CLK_SENDER = "";
            this.CLK_MSGSKIP = "";
            this.CLK_STAT = "";

            this.CHT_NEWRESULT = "";

            this.USER_NM = "";
            this.USER_TEL_NO = "";
            this.CHK_BL = false;
            this.USER_MEMO = "";

            this.chatHistory = [];

            this.CNSL_NEWDIV = "";
            this.SEARCH_CONT = "";

            this.USER_NEWID = "";

            this.CNSL_NEWTY1 = "";
            this.CNSL_NEWTY2 = "";
            this.CNSL_NEWTY3 = "";
            this.CNSL_NEWTY4 = "";
            this.CHT_NEWRESULT = "";
            this.CHT_TIT = "";
            this.CHT_CON = "";

            this.MSG = "";
            this.CANT_CUST = false;
          }
        })
        .catch((err) => {
          alert(err);
        });
    },

    //임시저장
    saveChatTemp() {
      apiChat
        .post(
          "api/chat/main/cnslt-hist-tmpr/regist", //api url입력
          {
            CNSL_TYP_CD: this.CNSL_NEWTY1,
            CNSL_TYP_CD_2: this.CNSL_NEWTY2,
            CNSL_TYP_CD_3: this.CNSL_NEWTY3,
            CNSL_TYP_CD_4: this.CNSL_NEWTY4,
            EXEC_RST_CD: "",
            TALK_ANS: this.CHT_TIT,
            TALK_QST: this.CHT_CON,
            TALK_CONTACT_ID: this.CLK_CHAT,
            ASP_NEWCUST_KEY: this.CLK_CUST,
            ASP_CUST_KEY: this.CLK_CUST,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            action: "api/chat/main/cnslt-hist-tmpr/regist",
            method: "post",
          },
          { head: {} }
        )
        .then((response) => {
          this.successSysMsg("상담이력이 임시저장되었습니다.");
          this.chatTemp = false;
        })
        .catch((err) => {
          alert(err);
        });
    },

    //상담저장 확인
    confirmSave(num, saveMsg) {
      //console.log("@@!!!!", moment(this.CNSL_RESVE_DT).format("HH"));
      this.EXEC_RST_CD = num;
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: saveMsg,
        iconClass: "svg-chk-lg",
        type: "confirm",
        callYes: this.callSaveYes,
        callNo: this.callNo1,
      });
    },

    // 상담 저장 [확인] 클릭
    callSaveYes() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
      if (this.EXEC_RST_CD == "3" || this.EXEC_RST_CD == "4") {
        this.saveChat(this.EXEC_RST_CD);
      } else if (this.EXEC_RST_CD == "CALL") {
        this.btnCnslSave();
      } else {
        this.saveChatTemp();
      }
    },

    //주소검색 팝업
    execDaumPostcode() {
      new window.daum.Postcode({
        oncomplete: (data) => {
          if (this.extraAddress !== "") {
            this.extraAddress = "";
          }
          if (data.userSelectedType === "R") {
            // 사용자가 도로명 주소를 선택했을 경우
            this.address = data.roadAddress;
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            this.address = data.jibunAddress;
          }

          // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
          if (data.userSelectedType === "R") {
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              this.extraAddress += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== "" && data.apartment === "Y") {
              this.extraAddress +=
                this.extraAddress !== ""
                  ? `, ${data.buildingName}`
                  : data.buildingName;
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if (this.extraAddress !== "") {
              this.extraAddress = `(${this.extraAddress})`;
            }
          } else {
            this.extraAddress = "";
          }
          // 우편번호를 입력한다.
          this.postcode = data.zonecode;
        },
      }).open();
    },

    //고객정보 초기화
    initUser() {
      this.USER_ID = "";
      this.USER_NM = "";
      this.USER_NICK = "";
    },

    //고객정보 파라미터 삽입
    btnCust(e) {
      let cust = {};

      if (e == this.CNSL_WK[0]) {
        cust = {
          ASP_CUST_KEY: this.SCH_ASP_CUST_KEY, // 콜상담_고객정보검색_업체코드
          PHONE_NUM: this.SCH_CUSTOMER_PHONE_NO.replaceAll("-", ""), // 콜상담_고객정보검색_전화번호
          CUST_NM: this.SCH_CUSTOMER_NM, // 콜상담_고객정보검색_고객명
          CNSL_WK: this.CNSL_WK[0], // 모달창 구분
        };
      } else if (e == this.CNSL_WK[1]) {
        cust = {
          ASP_CUST_KEY: this.SRH_ASP_CUST,
          CUST_NM: this.SRH_CUST_NM,
          PHONE_NUM: this.SRH_PHONE_NUM,
          CNSL_WK: this.CNSL_WK[1],
        };
      }

      this.cusInfo = cust;
    },

    setCustData() {
      if (this.cusInfo.CNSL_WK == this.CNSL_WK[0]) {

        this.SCH_CUSTOMER_ID = "";                                                          // 콜상담_고객정보검색_고객ID 초기화
        this.initCallCus();                                                                 // 콜상담_고객정보 초기화

        this.SCH_ASP_CUST_KEY = this.cusInfo.ASP_CUST_KEY;                                  // 고객정보_고객검색_업체명
        if(this.SCH_ASP_CUST_KEY == ''){
          this.SCH_ASP_CUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
        }
        this.SCH_CUSTOMER_PHONE_NO = this.mixin_setPhoneNo(this.cusInfo.CUSTOMER_PHONE_NO); // 고객정보_고객검색_전화번호
        this.SCH_CUSTOMER_ID = this.cusInfo.CUST_ID;                                        // 고객정보_고객검색_고객ID
        this.SCH_CUSTOMER_NM = this.cusInfo.CUST_NM;                                        // 고객정보_고객검색_고객명

        /** 고객조회 */
        this.getCusInfo();

      } else if (this.cusInfo.CNSL_WK == this.CNSL_WK[1]) {
        this.SRH_ASP_CUST = this.cusInfo.ASP_CUST_KEY;
        this.CLK_CUST = this.cusInfo.ASP_CUST_KEY;
        this.SRH_CUST_NM = this.cusInfo.CUST_NM;
        this.SRH_PHONE_NUM = this.cusInfo.CUSTOMER_PHONE_NO;

        this.SRH_CUST_ID = this.cusInfo.CUST_ID;
        this.CLK_USER = this.cusInfo.CUST_ID;

        this.getUserInfo();
        this.getTalkHist("", "");
      }
    },

    showToastInfo(MSG) {
      this.$store.commit("toastStore/POP", {
        act: true,
        msg: MSG,
        class: "toast-info",
        hasToastIcon: true,
        icon: "svg-info",
      });
    },

    //
    //220525 김태준 채팅이벤트 추가

    //
    //220530 웹소켓 이벤트 추가
    /**
     * websocket connect
     * @param connectCallback   연결 성공 콜백
     * @param errorCallback     연결 실패 콜백 ( 재연결 시도 )
     */
    connect() {
      let geUserId = this.$store.getters["userStore/GE_USER_ID"];

      //const subScribeUrl = this.baseSocketUrl + "queue/ready/" + geUserId;                //"http://local.hkpalette.com:8443/queue/ready/"
      let subScribeUrl = "/queue/ready/" + geUserId;

      let chatMessage = {
        chatType: "READY",
        userId: geUserId,
        userName: geUserId,
        userNickname: geUserId,
      };

      stompUtil.token = this.$store.state.messages.token; //SOCKET 연결 시 사용자 SOCKET Token 세팅
      stompUtil.userId = geUserId;
      stompUtil.aspNewCustKey = this.CUST;

      stompUtil.connect(
        () => {
          // 연결성공( 재연결 포함 )

          //this.connected = true;

          stompUtil.subscribe(subScribeUrl, chatMessage); // ready uri sub

          let subList = this.$store.getters["messages/GE_SUBSCRIPTION_LIST"];
          //console.log("subList!!!!", subList);
          stompUtil.subscribeAfterReconnect(subList); // 재연결 후 기존 sub 목록을다시 sub 해줌.최초는 대상이 없으므로 무관

          eventBus.getTalkers(); // 웹소켓 연결 후 상담목록 조회

          ///// 선택된 상담이 잇다면 재조회 (재연결시...)
          ////if( this.store.getters.getContactInfo().talk_contact_id != null && .getters.getContactInfo().talk_contact_id != '' ) {
          /////  eventBus.refreshChatContent(this.store.getters.getContactInfo().talk_contact_id);
          ////}
        },
        (error) => {
          //연결실패

          if (error != null) {
            //console.log(error);
            stompUtil.setConnected(false);

            // 재연결 시도
            setTimeout(this.connect, 3000);
          }
        }
      );
    },

    /**
     * 웹소켓 연결종료
     * Stomp / Socket-Io
     */
    disconnect() {
      if (stompUtil.isConnected()) {
        stompUtil.disconnect();
      }

      /** IPCC Socket 로그아웃 */
      if (this.CTI_LOGIN_BOOLEAN == true) {
        this.ctiLogout(); // 로그아웃
        this.$socket.disconnect();
      }
    },

    /**
     * ready callback 함수
     */
    readyCallback(message) {
      //console.log("M110101 readyCallback message.body >>>> " + message.body);

      if (typeof message.body !== "undefined") {
        let recvJson = JSON.parse(message.body);
        let data = JSON.parse(recvJson.telewebJsonString);
        let uri = message.headers.destination;

        //console.log(
        //  "M110101 readyCallback recvJson.chatType >>>> " + recvJson.chatType
        //);

        if (
          recvJson.chatType == "READY" &&
          recvJson.chatEvent == "AGENT_READY"
        ) {
          let sessionId = recvJson.sessionId;
          this.$store.state.messages.simsessionId = sessionId;
        }

        //console.log(
        //  "M110101 readyCallback data.DATA.length >>>> " + data.DATA.length
        //);

        if (data.DATA != null && data.DATA.length > 0) {
          let i = 0;
          let dataLen = data.DATA.length;

          for (; i < dataLen; i++) {
            let talkUserKey = data.DATA[i].TALK_USER_KEY;
            let userKey = data.DATA[i].user_key;

            //console.log(
            //  "M110101 readyCallback talkUserKey >>>> " + talkUserKey
            //);

            if (
              dataLen > 0 &&
              ((talkUserKey != null && talkUserKey) ||
                (userKey != null && userKey))
            ) {
              // 상담종료 메시지 보여줌. unsubscription ( 필요없음 redis 삭제 되므로.)
              if (recvJson.chatEvent == "EXPIRED_SESSION_CUST") {
                data.DATA[i].uri = uri;

                this.$store
                  .dispatch("messages/AC_SPICE_TALKER", data.DATA[i])
                  .then(() => {
                    this.$store.dispatch(
                      "messages/AC_CHANGE_TALK_PROCESS_INFO",
                      {
                        selected_stat: "",
                        userId: this.$store.getters["userStore/GE_USER_ID"],
                        aspCustKey: this.getAspCustKey(),
                      }
                    );
                  }); // 목록 삭제 (구독해제 포함)

                //Vue.prototype.store.dispatch('spliceTalker', data.DATA[i]).then(() => {
                //  Vue.prototype.store.dispatch("changeTalkProcessInfo", {selected_stat : ''});
                //});     // 목록 삭제 (구독해제 포함)
              } else {
                //console.log("M110101 readyCallback talkUserKey else >>>> ");

                // data.DATA[i].TALK_STAT_NM = "대기중";
                data.DATA[i].TALK_STAT_NM = this.mixin_get_talk_statnm(
                  data.DATA[i].TALK_STAT_CD
                );
                data.DATA[i].USER_ID = recvJson.userId;
                data.DATA[i].AUTO_GREETING_YN =
                  data.DATA[i].TALK_STAT_CD == "11" ||
                  data.DATA[i].TALK_STAT_CD == "13"
                    ? "N"
                    : "Y";

                this.$store
                  .dispatch("messages/AC_UN_SHIFT_TALKER", data.DATA[i])
                  .then(() => {
                    this.$store.dispatch(
                      "messages/AC_CHANGE_TALK_PROCESS_INFO",
                      {
                        selected_stat: "",
                        userId: this.$store.getters["userStore/GE_USER_ID"],
                        aspCustKey: this.getAspCustKey(),
                      }
                    );
                  });

                //Vue.prototype.store.dispatch('unshiftTalker', data.DATA[i]).then(() => {
                //  Vue.prototype.store.dispatch("changeTalkProcessInfo", {selected_stat : ''});
                //});

                this.getTalkers(); //신규 메시지를 받으면 새로고침
              }
            }
          }
        }
      }
    },

    /**
     *  inout callback 함수
     */
    inoutCallback(message) {
      //console.log(
      //  "@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 13"
      //);

      if (typeof message.body !== "undefined") {
        let recvJson = JSON.parse(message.body);
        let data = JSON.parse(recvJson.telewebJsonString);
        let uri = message.headers.destination;

        if (
          data.DATA.length > 0 &&
          data.DATA[0].user_key != null &&
          data.DATA[0].user_key != ""
        ) {
          data.DATA[0].uri = uri;

          if (data.DATA[0].SNDRCV_CD == null || data.DATA[0].SNDRCV_CD == "") {
            data.DATA[0].sndrcv_cd = "rcv";
          }

          // 자신이 보낸 메시지는 스킵 처리 한다.( 구독자 모두에게 다시 전송 되므로..)
          if (
            data.DATA[0].SNDRCV_CD == "SND" &&
            data.DATA[0].USER_ID == this.$store.getters["messages/GE_USER_ID"]
          ) {
            //Vue.prototype.store.getters.getUserId()
            return;
          }

          // 제3자 채팅
          if (data.DATA[0].SNDRCV_CD == "TRAN") {
            data.DATA[0].content = data.DATA[0].message;
          }

          // 무응답은 error_msg 가 메시지 내용임.
          // console.log("obj.inoutCallback >> " + recvJson.attachment + '-' + recvJson.org_cont_id  + '-' + data.DATA[0].attachment + '-' + data.DATA[0].org_cont_id  + '-' );
          if (
            recvJson.chatEvent == "NO_CUSTOMER_RESPONSE" ||
            recvJson.chatEvent == "EXPIRED_AGENT_SESSION"
          ) {
            data.DATA[0].content = data.HEADER.ERROR_MSG;
            data.DATA[0].sndrcv_cd = "snd";
            data.DATA[0].USER_NAME = "SYSTEM";
          }

          if (
            typeof data.DATA[0].content == "object" ||
            Array.isArray(data.DATA[0].content)
          ) {
            data.DATA[0].content = JSON.stringify(data.DATA[0].content);
          }

          let msg = this.$store.getters["messages/GE_MSG_OBJ"](data.DATA[0]); // Vue.prototype.store.getters.getMsgObj(data.DATA[0]);
          let msgObj = this.$store.getters["messages/GE_CONTACT_MSG"]({
            talk_contact_id: data.DATA[0].TALK_CONTACT_ID,
          }); //Vue.prototype.store.getters.getContactMsg

          // 상담종료 메시지 보여줌. unsubscription ( 필요없음 redis 삭제 되므로.)
          if (recvJson.chatEvent == "EXPIRED_SESSION_CUST") {
            // 상담중 완료
            let talkerObj = _.find(
              this.$store.getters["messages/GE_TALK_LIST"],
              { TALK_CONTACT_ID: data.DATA[0].TALK_CONTACT_ID }
            ); //Vue.prototype.store.getters.getTalkList()

            // console.log("obj.EXPIRED_SESSION_CUST >> " + talkerObj + '-' + data.DATA[0].TALK_CONTACT_ID);
            if (
              talkerObj != null &&
              talkerObj != "" &&
              data.DATA[0].TALK_CONTACT_ID != null
            ) {
              // 이미 상담 시작됨.

              talkerObj.TALK_STAT_CD = data.DATA[0].TALK_STAT_CD;
              talkerObj.TALK_STAT_NM = this.mixin_get_talk_statnm(
                data.DATA[0].TALK_STAT_CD
              );

              msg.msg_notice =
                "상담이 종료되었습니다. 상담이력을 저장해 주시기 바랍니다.";

              this.$store
                .dispatch("messages/AC_SUB_TALKER", data.DATA[0])
                .then(() => {
                  this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", {
                    selected_stat: "",
                    userId: this.$store.getters["userStore/GE_USER_ID"],
                    aspCustKey: this.getAspCustKey(),
                  });
                }); // 구독해제

              //Vue.prototype.store.dispatch('AC_SUB_TALKER', data.DATA[0]).then(() => {
              //  Vue.prototype.store.dispatch("AC_CHANGE_TALK_PROCESS_INFO" , {selected_stat : ''});
              //});     // 구독해제
            } else {
              // 대기중

              this.$store
                .dispatch("messages/AC_SPICE_TALKER", data.DATA[0])
                .then(() => {
                  this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", {
                    selected_stat: "",
                    userId: this.$store.getters["userStore/GE_USER_ID"],
                    aspCustKey: this.getAspCustKey(),
                  });
                }); // // 대기중 완료 목록 삭제 (구독해제 포함)

              //Vue.prototype.store.dispatch('spliceTalker', data.DATA[0]).then(() => {
              //  Vue.prototype.store.dispatch("changeTalkProcessInfo", {selected_stat : ''});
              //});     // // 대기중 완료 목록 삭제 (구독해제 포함)
            }

            // 현재 선택 채팅이 변경 된 경우만 변경 처리  this.$store.state.messages.talkContactInfo.talk_contact_id
            if (
              data.DATA[0].TALK_CONTACT_ID ==
              this.$store.state.messages.talkContactInfo.talk_contact_id
            ) {
              this.changeChatInfo();
            }

            window.focus();
          } else if (recvJson.chatEvent == "EXPIRED_AGENT_SESSION") {
            // 전달완료 (목록에서 삭제 )

            this.$store
              .dispatch("messages/AC_SPICE_TALKER", data.DATA[0])
              .then(() => {
                this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", {
                  selected_stat: "",
                  userId: this.$store.getters["userStore/GE_USER_ID"],
                  aspCustKey: this.getAspCustKey(),
                });

                if (
                  data.DATA[0].TALK_CONTACT_ID ==
                  this.$store.state.messages.talkContactInfo.talk_contact_id
                ) {
                  this.changeChatInfo();
                }
              }); // 목록 삭제 (구독해제 포함)

            //Vue.prototype.store.dispatch('spliceTalker', data.DATA[0]).then(() => {
            //  Vue.prototype.store.dispatch("AC_CHANGE_TALK_PROCESS_INFO", {selected_stat : '', userId: this.$store.getters['userStore/GE_USER_ID'], aspCustKey : this.getAspCustKey()});
            //  if( data.DATA[0].TALK_CONTACT_ID == Vue.prototype.store.state.messages.talkContactInfo.talk_contact_id) {
            //    eventBus.changeChatInfo(()=>{});
            //  }
            //});     // 목록 삭제 (구독해제 포함)

            return;
          }

          this.$store.dispatch("messages/AC_USER_CHAT_MESSAGE", {
            msg: msg,
            msgObj: msgObj,
            aspCustKey: this.getAspCustKey(),
          });
          //Vue.prototype.store.dispatch("userchatMessage", { "msg": msg, "msgObj": msgObj });
        }
      }
    },

      /** 채팅 클릭시 채팅정보 검색 */
    getTalkers() {
      apiChat
        .post("api/chat/main/cnslt/inqire", {
          ASP_NEWCUST_KEY: this.getAspCustKey(),
        })
        .then((ret) => {
          // 서버 error 체크
          if (ret.data.error != null && ret.data.error != "") {
            if (ret.data.error == "-999") {
              alert("상세 목록 조회 Error");
            }
          } else {
            // this.$store.dispatch("messages/AC_SPICE_TALKER", data.DATA[i])
            if (ret.data.code >= 0) {
              this.$store
                .dispatch("messages/AC_INIT_TALK_LIST", ret.data.data)
                .then(() => {
                  this.$store.dispatch("messages/AC_CHANGE_TALK_PROCESS_INFO", {
                    selected_stat: this.selected_stat,
                    userId: this.$store.getters["userStore/GE_USER_ID"],
                    aspCustKey: this.getAspCustKey(),
                  });
                });
            }
          }
        });
    },


    /** 챗 사용자정보 세팅 */
    setChatUserInfo() {
      apiChat
        .post("api/stomp/chat/userinfo", {
          USER_ID: this.$store.getters["userStore/GE_USER_ID"],
          USER_NM: this.$store.getters["userStore/GE_USER_NAME"],
        })
        .then((ret) => {
          // 서버 error 체크
          if (ret.data.error != null && ret.data.error != "") {
            if (ret.data.error == "-999") {
              alert("챗 상담 사용자 정보 Error(Talk Token)");
            }
          } else {
            this.$store.state.messages.token = ret.data.token;
            this.$store.state.messages.userId = ret.data.id;
            this.$store.state.messages.userNm = ret.data.name;
            this.$store.state.messages.aspCustKey = ret.data.aspCustKey;

            //this.setTalkEnv(); // timeout 정보
            //this.connect(); // ready websocket 연결
          }
        });
    },

    /** 상담설정 관련 정보 get */
    setTalkEnv() {
      apiChat
        .post("api/chat/common-vue/env/inqry", {
          ASP_NEWCUST_KEY: this.CUST,
        })
        .then((ret) => {
          // 서버 error 체크
          if (ret.data.error != null && ret.data.error != "") {
            if (ret.data.error == "-999") {
              alert("챗 상담 사용자 정보 Error(Talk Token)");
            }
          } else {
            let retObj = Object.values(ret.data.data);

            if (retObj != null && retObj.length > 0) {
              this.$store.state.readyTimeout =
                retObj[0].RETRY_READY_MAX_TIMEOUT;
            }

            if (retObj != null && retObj.length > 0) {
              this.$store.state.messages.blobYn = retObj[0].BLOB_YN;
            }
          }
        });
    },

    //책깔피 등록
    markUp() {
      let MARK = "";
      if (this.MARK_UP == "N" || this.MARK_UP == null || this.MARK_UP == "") {
        MARK = "Y";
      } else {
        MARK = "N";
      }
      api
        .post(
          "/api/chat/main/markup/regist",
          {
            ASP_NEWCUST_KEY: this.CLK_CUST,
            TALK_CONTACT_ID: this.CLK_CHAT == "" ? "" : this.CLK_CHAT,
            MARK_UP: MARK,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY: this.CUST,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
          },
          {
            head: {
              SERVICE: "chat.main.markup",
              METHOD: "regist",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((ret) => {
          //console.log("등록완료>>>>>>>>>>>>>", ret.data.DATA);
          this.getTalkList();
        });
    },

    //채팅 상세조회 조회조건 초기화
    initSrcDetail() {
      this.DET_SRC_dates = [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ];

      this.DET_SRC_CHENNEL = "";
      this.DET_SRC_RESULT = "";
      this.DET_SRC_AGENT_ID = "";
      this.DET_SRC_CHAT_RESULT = "";
    },

    /** 고객등급 조회(외부API) */
    test() {
      api
        .post("/api/phone/main/blvip/inqire", {
          IN_NUM: "", //내선번호
          PHONE_NUM: "", //전화번호
        })
        .then((ret) => {
          //console.log("고객등급>>>>>>>>>>>>>", ret.data.DATA);
        });
    },

    /**
     * 콜상담이력 저장
     */
    btnCnslSave() {

      let bol = true;

      let procTy = "";                                                                // 콜상담저장_처리구분_켐페인(PROC_TY), 콜백/예약콜(EOT_TY_RLT)
      let fishTy = "";                                                                // 콜상담저장_처리결과_켐페인(처리구분 FISH_TY), 콜백(PROC_CODE)

      let eotTyRlt = "";                                                              // 콜상담저장_처리구분_켐페인(PROC_TY), 콜백/예약콜(EOT_TY_RLT)
      let procCode = "";                                                              // 콜상담저장_처리결과_켐페인(처리구분 FISH_TY), 콜백(PROC_CODE)

      let resvFishYn = "";                                                            // 콜상담저장_처리구분_켐페인(PROC_TY), 콜백/예약콜(EOT_TY_RLT)

      let cnslLv1   = "";                                                             // 상담코드 1
      let cnslLv2   = "";                                                             // 상담코드 2

      cnslLv1   = this.CNSL_LV_1;                                                     // 상담코드 1
      cnslLv2   = this.CNSL_LV_2;                                                     // 상담코드 2

      /** CUS_CUSTOMER_ID 확인 */
      if( this.CUS_CUSTOMER_ID == "" ||  this.CUS_CUSTOMER_ID.length == 0) {

        bol = false;
        alert("신규 고객인 경우 고객 등록 후 상담저장을 할 수 있습니다");  // 오류메시지

      }

      if (bol) {

        if(this.CNSL_BLNG != 'OUT') {

          if (cnslLv1 == ""  || cnslLv2 == "" ) {
            bol = false;
            alert("상담유형1,2 선택하세요");
          }
        }
      }

      if (bol) {
        if(this.CNSL_RESULT == '' || this.CNSL_RESULT == undefined){
          bol = false;
          alert("상담결과를 선택해주세요.");
        }
      }

      if (bol) {

        if(this.CNSL_BLNG == 'IN'){

          if (this.CNSL_CHN_1 == "" || this.CNSL_CHN_1 == undefined) {
            bol = false;
            alert("상담채널을 먼저 선택해주세요.");
          }

        } else if(this.CNSL_BLNG == 'OUT'){
          if (this.CATEGORY == "" || this.CATEGORY == undefined) {
            bol = false;
            alert("카테고리를 먼저 선택해주세요.");
          }
        } else if(this.CNSL_BLNG == 'FUNU'){
          if (this.CNSL_LV_1 == "" || this.CNSL_LV_1 == undefined) {
            bol = false;
            alert("대분류를 먼저 선택해주세요.");
          }
        }
      }

      if (this.CALL_OUT_BOUND.CAM_ID != "" && this.CALL_OUT_BOUND.CAM_ID != "undefined") {       /** 캠페인(아웃바운드) */

        //procTy = this.CNSL_PROC_TY;
        //fishTy = this.CNSL_PROC_RLT;

        procTy = "00001";
        fishTy = "00001";
        //fishTy = this.CNSL_PROC_RLT;

        //if (procTy == "" || procTy == "undefined" || fishTy == "" || fishTy == "undefined") {

        //  bol = false;
        //  alert("캠페인(아웃바운드) 처리결과를 입력하세요");  // 오류메시지
        //}

      } else if (this.CALL_CALL_BACK.CLBK_NO != "" && this.CALL_CALL_BACK.CLBK_NO != "undefined") {      /** 콜백 */

        //eotTyRlt = this.CNSL_PROC_TY;
        //procCode = this.CNSL_PROC_RLT;

        eotTyRlt = "00001";
        procCode = this.CNSL_PROC_RLT;

        //if (eotTyRlt == "" || eotTyRlt == "undefined" || procCode == "" || procCode == "undefined") {

        //  bol = false;
        //  alert("콜백 처리결과를 입력하세요");  // 오류메시지
        //}

      } else if (this.CALL_RESV_CALL.RESV_SEQ != "" && this.CALL_RESV_CALL.RESV_SEQ != "undefined") {    /** 예약콜/재통화 */

        //resvFishYn = this.CNSL_PROC_TY;

        resvFishYn = "Y";

        //if (resvFishYn == "" || resvFishYn == "undefined") {

        //  bol = false;
        //  alert("재통화 처리결과를 입력하세요");  // 오류메시지
        //}
      }


      /** 상담이력 저장 */
      if (bol) {

        let custTelNo = "";                                                                                                                                             // 고객전화번호

        let afProcTime = 0;                                                                                                                                             // 후처리 시간
        let telTime = 0;                                                                                                                                                // 통화시간

        let afDtTm = moment();

        if (typeof this.CTI_INFO.strStartDate == undefined || typeof this.CTI_INFO.strStartDate == "undefined" || this.CTI_INFO.strStartDate == "") {
        } else {

          let stDt = this.CTI_INFO.strStartDate.substring(0, 4) + "-" + this.CTI_INFO.strStartDate.substring(4, 6) + "-" + this.CTI_INFO.strStartDate.substring(6, 8);  // 통화 시작일자
          let stTm = this.CTI_INFO.strStartTime.substring(0, 2) + ":" + this.CTI_INFO.strStartTime.substring(2, 4) + ":" + this.CTI_INFO.strStartTime.substring(4, 6);  // 통화 시작시간

          let edDt = this.CTI_INFO.strEndDate.substring(0, 4) + "-" + this.CTI_INFO.strEndDate.substring(4, 6) + "-" + this.CTI_INFO.strEndDate.substring(6, 8);        // 통화 종료일자
          let edTm = this.CTI_INFO.strEndTime.substring(0, 2) + ":" + this.CTI_INFO.strEndTime.substring(2, 4) + ":" + this.CTI_INFO.strEndTime.substring(4, 6);        // 통화 종료시간

          let stDtTm = moment(stDt + ' ' + stTm, 'YYYY-MM-DD HH:mm:ss');
          let edDtTm = moment(edDt + ' ' + edTm, 'YYYY-MM-DD HH:mm:ss');

          telTime = Math.ceil(moment.duration(edDtTm.diff(stDtTm)).asSeconds());                                                                                        // 통화시간(초)
          afProcTime = Math.ceil(moment.duration(afDtTm.diff(edDtTm)).asSeconds());                                                                                     // 후처리시간(초)
        }

        if (typeof this.UEI_INFO.strInboundNum == undefined || typeof this.UEI_INFO.strInboundNum == "undefined" || typeof this.UEI_INFO.strInboundNum == "") {
          custTelNo = this.CUS_CUSTOMER_PHONE_NO.replace(/-/g, '');
        } else {
          custTelNo = this.UEI_INFO.strInboundNum
        }

        api
          .post(
            "/api/phone/main/cnslt-hist/regist",
            {
              ASP_NEWCUST_KEY: this.CUS_ASP_CUST_KEY,
              ASP_USER_ID   : this.$store.getters["userStore/GE_USER_ROLE"].userId,
              ASP_CUST_KEY  : this.CUS_ASP_CUST_KEY,
              USER_ID       : this.$store.getters["userStore/GE_USER_ROLE"].userId,

              CNSL_CALL_TY  : this.CNSL_CALL_TY,                                      //INOUT콜유형
              CNSL_RESVE_CHK: this.CNSL_RESVE_CHK,                                    //INOUT예약콜체크
              CNSL_RESVE_DT : this.returnEmpty(this.CNSL_RESVE_DT),                   //INOUT예약콜시간
              CNSL_NUM_CHK  : this.CNSL_NUM_CHK,                                      //INOUT차수체크
              CNSL_CHN_1    : this.CNSL_CHN_1,                                        //IN상담채널
              CNSL_CHN_2    : this.CNSL_CHN_2,                                        //IN채널기타
              CONS_BOOK     : this.CONS_BOOK,                                         //IN상담예약
              CONS_BOOK_DT  : this.returnEmpty(this.CONS_BOOK_DT),                 	  //IN상담예약시간
              CNSL_CONT     : this.CNSL_CONT,                                         //IN문의내용
              VIS_PATH      : this.VIS_PATH,                                          //IN내원경로
              DISA_ITEM     : this.DISA_ITEM,                                         //IN진료항목
              VIS_TERM      : this.VIS_TERM,                                          //IN간격일수
              CNSL_BOOK     : this.CNSL_BOOK,                                         //IN문의/예약구분
              CNSL_BOOK_DT  : this.returnEmpty(this.CNSL_BOOK_DT),                    //IN문의/예약일자
              CATEGORY      : this.CATEGORY,                                          //OUT카테고리
              CONDITION     : this.CONDITION,                                         //OUT상태
              VIS_BOOK      : this.VIS_BOOK,                                          //OUT상담내원
              VIS_BOOK_DT   : this.returnEmpty(this.VIS_BOOK_DT),                     //OUT상담내원일자
              VIS_MANAGE    : this.VIS_MANAGE,                                        //OUT상담내원담당
              OPER_BOOK     : this.OPER_BOOK,                                         //OUT수술예약
              OPER_BOOK_DT  : this.returnEmpty(this.OPER_BOOK_DT),                    //OUT수술예약일자
              OPER_MANAGE   : this.OPER_MANAGE,                                       //OUT수술예약담당
              OPERATION     : this.OPERATION,                                         //OUT수술
              OPERATION_DT  : this.returnEmpty(this.OPERATION_DT),                    //OUT수술일자
              OPERATION_COST: this.OPERATION_COST,                                    //OUT수술금액
              MANAGE        : this.MANAGE,                                            //OUT담당자
              ETC           : this.encode(this.encodeEnter(this.ETC)),                                               //INOUT특이사항/비고
              CNSL_CNTN     : this.encode(this.encodeEnter(this.CNSL_CNTN)),                                         //INOUT상담내용

              CNSL_HIST_NO  : this.strCnslId,                                          /*상담이력번호*/
              CSLT_ID       : this.$store.getters["userStore/GE_USER_ROLE"].userId,   /*상담사ID*/
              CNSL_BEGIN_DATE: this.CTI_INFO.strStartDate,                            /*상담시작일자*/
              CNSL_BEGIN_TIME: this.CTI_INFO.strStartTime ,                           /*상담시작시간*/
              CNSL_EOT_DATE : this.CTI_INFO.strEndDate,                               /*상담종료일자*/
              CNSL_EOT_TIME : this.CTI_INFO.strEndTime,                               /*상담종료시간*/
              CALL_TY       : this.CNSL_CALL_TY,                                      /*콜유형*/
              TEL_TIME      : this.TEL_TIME,                                          /*통화시간*/
              CNSL_SAVE_DTIM: this.CNSL_SAVE_DTIM,                                    /*상담저장일시*/
              AF_PROC_TIME  : afProcTime,                                             /*후처리시간 (상담저장 - 통화종료시간=초)*/
              CUST_TEL_NO   : custTelNo,                                              /*고객전화번호*/
              CUST_NM       : this.CUS_CUSTOMER_NM,                                   /*고객명*/
              RDWT_SEND_YN  : this.RDWT_SEND_YN,                                      /*녹취전송여부*/
              RDWT_FILE_NM  : this.CTI_INFO.strRecKey,                                /*녹취파일명*/
              REG_MAN       : this.$store.getters["userStore/GE_USER_ROLE"].userId,   /*등록자*/
              CHNG_MAN      : this.$store.getters["userStore/GE_USER_ROLE"].userId,   /*변경자*/
              PROC_CODE_LCLS: this.CNSL_RESULT,                                       /*상담결과*/
              TEL_TIME      : telTime,                                                /**통화시간 */

              PROC_TY       : procTy,                                                 // 아웃바운드 세팅_처리구분
              FISH_TY       : fishTy,                                                 // 아웃바운드 세팅_처리결과
              CAM_ID        : this.CALL_OUT_BOUND.CAM_ID,                             // 아웃바운드 세팅_캠페인 ID
              CUST_NO_OUT   : this.CALL_OUT_BOUND.CUST_NO,                            // 아웃바운드 세팅_고객번호

              EOT_TY_RLT    : eotTyRlt,                                               // 콜백세팅_처리구분
              PROC_CODE     : procCode,                                               // 콜백세팅_처리결과
              CLBK_NO       : this.CALL_CALL_BACK.CLBK_NO,                            // 콜백세팅_콜백번호

              RESVE_CALL    : this.CNSL_RESVE_CHK,                                    // 예약콜 세팅
              RESV_DATE     : moment(this.CNSL_RESVE_DT).format("YYYYMMDD"),          // 예약콜 세팅
              RESV_TIME_HH  : moment(this.CNSL_RESVE_DT).format("HH"),                // 예약콜 세팅
              RESV_TIME_MM  : moment(this.CNSL_RESVE_DT).format("mm"),                // 예약콜 세팅
              RESV_TEL_NO   : this.UEI_INFO.strInboundNum,                            // 예약콜 세팅

              RESV_SEQ      : this.CALL_RESV_CALL.RESV_SEQ,                           // 예약콜 완료 세팅_예약콜번호
              FISH_YN       : resvFishYn,                                             // 예약콜 완료 세팅_처리구분/결과

              CUSTOMER_ID   : this.CUS_CUSTOMER_ID,                                   // 고객 ID

              CNSL_TYP_CD   : this.CNSL_LV_1,                                           // 대분류(상담유형1)
              CNSL_TYP_CD_2   : this.CNSL_LV_2,                                           // 중분류(상담유형2)
              CNSL_TYP_CD_3   : this.CNSL_LV_3,                                           // 소분류(상담유형3)
            },
            {
              head: {
                SERVICE: "phone/main/cnslt-hist",
                METHOD: "regist",
                TYPE: "XML_SERVICE",
                PRINT_CONSOLE: 'N',
              },
            }
          )
          .then((ret) => {

            if (this.CTI_DB_STATE == "hangup" && this.CTI_LOGIN_BOOLEAN == true) {

              this.btnClassRemove();
              this.ctiBtnEvent("handle");                 // 후처리 이벤트 버튼 재배열
              this.CTI_DB_STATE = "handle";               // 후처리 상태값 변경

              this.intiCall(this.CTI_DB_STATE);           // CTI/UEI/상담저장/고객정보/고객상담이력 초기화

              //퓨처누리 변수 초기화
              this.CNSL_LV_1='';
              this.CNSL_LV_2='';
              this.CNSL_LV_3='';
              this.CNSL_LV_4='';
              this.CNSL_LV_2_LIST=[{
                text:'전체',
                value:''
              }];
              this.CNSL_LV_3_LIST=[{
                text:'전체',
                value:''
              }];
              this.CNSL_LV_4_LIST=[{
                text:'전체',
                value:''
              }];
              this.CNSL_RESULT = '00001';
            }
          });


          /** CTI 로그인 버튼 활성화 */
          this.CTI_ANSWER_BOOLEAN = false;
      }
    },

    //콜 상담이력 저장폼 보여주기
    getCnslSaveForm(aspCustKey) {
      api.post("/api/chat/dashboard/ATTR/inqire", {
        ASP_NEWCUST_KEY: aspCustKey,
        ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
        ASP_CUST_KEY: aspCustKey,
        USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
      },
      {
        head: {
          SERVICE: "chat.dashboard.ATTR",
          METHOD: "inqire",
          TYPE: "BIZ_SERVICE",
        }
      })
      .then((ret) => {
        //console.log("소속정보", ret.data.DATA);
        //console.log("ret.data.DATA[0].USER_ATTR_NM_A", ret.data.DATA[0].USER_ATTR_NM_A);
        if(ret.data.DATA != ''){
          if (ret.data.DATA[0].USER_ATTR_NM_A.includes("한국교육과정평가원")){
            this.CNSL_BLNG = "FUNU";
          } else if(ret.data.DATA[0].USER_ATTR_NM_B != undefined) {
            if (ret.data.DATA[0].USER_ATTR_NM_B.includes("아웃바운드")) {
              this.CNSL_BLNG = "OUT";
            } else {
              this.CNSL_BLNG = "IN";
            }
          }
        }
      });
    },

    //임시 상담폼 변경
    CHG_CNSL_BLNG() {
      if (this.CNSL_BLNG == "IN") {
        this.CNSL_BLNG = "OUT";
      } else if (this.CNSL_BLNG == "OUT") {
        //this.CNSL_BLNG = "FUNU";
        this.CNSL_BLNG = "IN";

        /*
        * 공통코드 -> 상담유형
        *
        api.post("/api/palette/common/get-cmm/list", {
          INFO1: "대분류",
          ASP_NEWCUST_KEY: this.CTI_INFO.strAspCustKey,
          ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
          ASP_CUST_KEY: this.CTI_INFO.strAspCustKey,
          USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
        },
        {
          head: {
            SERVICE: "palette.common.get-cmm",
            METHOD: "list",
            TYPE: "BIZ_SERVICE",
          }
        })
        .then((ret) => {
          console.log("최상위 코드", ret.data.DATA);
          this.CNSL_LV_1_LIST = ret.data.DATA;
        });
        */
        //this.getConsultTY('*', 'FUNU');
        //console.log("this.CNSL_LV_1_LIST", this.CNSL_LV_1_LIST);
      } else if (this.CNSL_BLNG == "FUNU") {
        this.CNSL_BLNG = "IN";
      }
    },

    getCmmCode(){

      let aspCustKey = "";

      if (typeof this.CTI_INFO.strAspCustKey == undefined || typeof this.CTI_INFO.strAspCustKey == "undefined" || this.CTI_INFO.strAspCustKey == "") {

        if (typeof this.CUS_ASP_CUST_KEY == undefined || typeof this.CUS_ASP_CUST_KEY == "undefined" || this.CUS_ASP_CUST_KEY == "") {
        } else {
          aspCustKey = this.CUS_ASP_CUST_KEY;
        }
      } else {
        aspCustKey = this.CTI_INFO.strAspCustKey;
      }

      this.CNSL_CONT_LIST = this.common_code_byCust(this.cmmnCdLstByCus, 'MTC001','전체',aspCustKey);
      this.VIS_PATH_LIST = this.common_code_byCust(this.cmmnCdLstByCus, 'MTC002','전체',aspCustKey);
      this.DISA_ITEM_LIST = this.common_code_byCust(this.cmmnCdLstByCus, 'MTC003','전체',aspCustKey);
      this.CNSL_BOOK_LIST = this.common_code_byCust(this.cmmnCdLstByCus, 'MTC004','전체',aspCustKey);
      this.CONDITION_LIST = this.common_code_byCust(this.cmmnCdLstByCus, 'MTC005','전체',aspCustKey);
      this.CONS_BOOK_LIST = this.common_code_byCust(this.cmmnCdLstByCus, 'MTC006','전체',aspCustKey);
      this.VIS_BOOK_LIST = this.common_code_byCust(this.cmmnCdLstByCus, 'MTC007','전체',aspCustKey);
      this.OPER_BOOK_LIST = this.common_code_byCust(this.cmmnCdLstByCus, 'MTC008','전체',aspCustKey);
      this.CATEGORY_LIST = this.common_code_byCust(this.cmmnCdLstByCus, 'MTC009','전체',aspCustKey);
      this.OPERATION_LIST = this.common_code_byCust(this.cmmnCdLstByCus, 'MTC010','전체',aspCustKey);
      this.OPER_MANAGE_LIST = this.common_code_byCust(this.cmmnCdLstByCus, 'MTC010','전체',aspCustKey);  //리스트 추가해야함 (공통코드인지 다른 리스트인지)
      this.VIS_MANAGE_LIST = this.common_code_byCust(this.cmmnCdLstByCus, 'MTC010','전체',aspCustKey);   //리스트 추가해야함 (공통코드인지 다른 리스트인지)
      this.MANAGE_LIST = this.common_code_byCust(this.cmmnCdLstByCus, 'MTC010','전체',aspCustKey);       //리스트 추가해야함 (공통코드인지 다른 리스트인지)
      this.CNSL_CHN_1_LIST = this.common_code_byCust(this.cmmnCdLstByCus, 'MTC012','전체',aspCustKey);
      //this.CNSL_CHN_2_LIST = this.common_code_byCust(this.cmmnCdLstByCus, 'MTC013','전체',this.CTI_INFO.strAspCustKey);

      this.getConsultTY('*', aspCustKey);

      /*
      * 공통코드 -> 상담유형사용
      *
      if(aspCustKey == 'FUNU'){
        this.CNSL_LV_1_LIST = this.mixin_get_first_cmm("대분류", aspCustKey);
      }
      */

      this.getCnslSaveForm(aspCustKey);
    },

    //회사별 공통코드 조회(MIXIN에서 가져옴)
    common_code_byCust(all_code, group_cd, strHeadText, aspCustKey, CHK){
      var responseData = [];

      if (strHeadText != "" && strHeadText != undefined) {
        responseData.push({ text: strHeadText, value: "" });
      }

      if (all_code && typeof all_code === "object") {
        for (var i = 0; i < all_code.length; i++) {
          if (all_code[i].group_cd === group_cd) {
            for (var a = 0; a < all_code[i].group_value.length; a++) {
              if(all_code[i].group_value[a].ETC_INFO01 == '' || all_code[i].group_value[a].ETC_INFO01 == null){
                //기타코드1 비어있을때
                if(all_code[i].group_value[a].ETC_INFO02 == '' || all_code[i].group_value[a].ETC_INFO02 == null){
                  //기타코드1 비어있고 기타코드2 비어있을때
                  responseData.push({
                    text: all_code[i].group_value[a].CD_NM,
                    value: all_code[i].group_value[a].CD,
                    info1: all_code[i].group_value[a].ETC_INFO01,
                    info2: all_code[i].group_value[a].ETC_INFO02,
                    info3: all_code[i].group_value[a].ETC_INFO03,
                  });
                } else {
                  //기타코드1 비어있고 기타코드2 존재할때
                  let chkAspCus = all_code[i].group_value[a].ETC_INFO02.split(',');
                  let chkAspCusLeng = chkAspCus.length;
                  let n = 0;
                  let cnt = 0;
                  for(n;n<chkAspCusLeng;n++){
                    if(chkAspCus[n] == aspCustKey){
                      cnt++;
                    }
                  }
                  if(cnt == 0){
                    responseData.push({
                      text: all_code[i].group_value[a].CD_NM,
                      value: all_code[i].group_value[a].CD,
                      info1: all_code[i].group_value[a].ETC_INFO01,
                      info2: all_code[i].group_value[a].ETC_INFO02,
                      info3: all_code[i].group_value[a].ETC_INFO03,
                    });
                  }
                }
              } else {
                //기타코드1 존재할때 >> 기타코드2는 조회할 필요 없음
                let chkAspCus = all_code[i].group_value[a].ETC_INFO01.split(',');
                let chkAspCusLeng = chkAspCus.length;
                let n = 0;
                for(n;n<chkAspCusLeng;n++){
                  if(chkAspCus[n] == aspCustKey){
                    responseData.push({
                      text: all_code[i].group_value[a].CD_NM,
                      value: all_code[i].group_value[a].CD,
                      info1: all_code[i].group_value[a].ETC_INFO01,
                      info2: all_code[i].group_value[a].ETC_INFO02,
                      info3: all_code[i].group_value[a].ETC_INFO03,
                    });
                  }
                }
              }

            }
          }
        }
      }
      //console.log("responseData", responseData);
      return responseData;
    },

    //분류코드 가져오기
    getLVCode(all_code, group_cd, strHeadText, CHK){
      var responseData = [];

      if (strHeadText != "" && strHeadText != undefined) {
        responseData.push({ text: strHeadText, value: "" });
      }

      if (all_code && typeof all_code === "object") {
        for (var i = 0; i < all_code.length; i++) {
          if (all_code[i].group_cd === group_cd) {
            for (var a = 0; a < all_code[i].group_value.length; a++) {
              if(all_code[i].group_value[a].ETC_INFO01 == CHK){
                if(CHK == '소분류'){
                  if(all_code[i].group_value[a].CD.includes(this.CNSL_LV_2)){
                    responseData.push({
                      text: all_code[i].group_value[a].CD_NM,
                      value: all_code[i].group_value[a].CD,
                      info1: all_code[i].group_value[a].ETC_INFO01,
                      info2: all_code[i].group_value[a].ETC_INFO02,
                      info3: all_code[i].group_value[a].ETC_INFO03,
                    });
                  }
                } else {
                  responseData.push({
                    text: all_code[i].group_value[a].CD_NM,
                    value: all_code[i].group_value[a].CD,
                    info1: all_code[i].group_value[a].ETC_INFO01,
                    info2: all_code[i].group_value[a].ETC_INFO02,
                    info3: all_code[i].group_value[a].ETC_INFO03,
                  });
                }
              }
            }
          }
        }
      }
      return responseData;
    },

    //채팅가능여부판단
    //220704수정 : 사용자별 -> 회사별
    getChatYN(){
      api
        .post(
          "api/chat/main/chatYN/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: this.CLK_CUST,
            ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            ASP_CUST_KEY: this.CUST,
            USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
          },
          {
            head: {
              SERVICE: "chat.main.chatYN",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          //console.log("<<<<<", response.data.DATA);
          if(response.data.DATA[0].CNT == 0){
            this.chatYN = true;
          } else {
            this.chatYN = false;
          }

        })
        .catch((err) => {
          alert(err);
        });
    },
    //220704수정 : 사용자별 -> 회사별

    //채널 변경시 기타채널 조회
    chgCnslChn(){
      if(this.CNSL_CHN_1 == 3){
        this.CNSL_CHN_2_LIST = this.common_code_byCust(this.cmmnCdLstByCus, 'MTC013','전체',this.CTI_INFO.strAspCustKey);
      } else{
        this.CNSL_CHN_2_LIST = [{text:'전체',value:''}];
        this.CNSL_CHN_2 = '';
      }
    },

    chgCnslLv(CHK){
      if(CHK == '중분류'){
        this.CNSL_LV_2_LIST = this.mixin_getLVCode(this.cmmnCdLstFUNU, this.CNSL_LV_1, '전체', CHK);
      } else if (CHK == '소분류'){
        this.CNSL_LV_3_LIST = this.mixin_getLVCode(this.cmmnCdLstFUNU, this.CNSL_LV_1, '전체', CHK);
      }
    },

    //220530 웹소켓 이벤트 추가
    //

    //
    //상담유형 사용
    //상담유형 select 불러오기
    getConsultTY(consult, aspCustKey){
      if(aspCustKey == '' || aspCustKey == undefined){
        if(this.CTI_INFO.strAspCustKey != '' && this.CTI_INFO.strAspCustKey != undefined){
          aspCustKey = this.CTI_INFO.strAspCustKey;
        } else {
          aspCustKey = this.CUS_ASP_CUST_KEY;
        }
      }
      var box = '';
      if(consult == "*") {
        this.CNSL_LV_1_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_1 = '';
        this.CNSL_LV_2_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_2 = '';
        this.CNSL_LV_3_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_3 = '';
        this.CNSL_LV_4_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_4 = '';
        box = consult;
      } else if(consult == "1"){
        this.CNSL_LV_2_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_2 = '';
        this.CNSL_LV_3_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_3 = '';
        this.CNSL_LV_4_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_4 = '';
        box = this.CNSL_LV_1;
      } else if(consult == "2"){
        this.CNSL_LV_3_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_3 = '';
        this.CNSL_LV_4_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_4 = '';
        box = this.CNSL_LV_2;
      } else if(consult == "3"){
        this.CNSL_LV_4_LIST = [{CD:'', CD_NM:'전체'}]
        this.CNSL_LV_4 = '';
        box = this.CNSL_LV_3;
      }
      api.post("api/setting/consulttype/manage/inqire",   //api url입력
      {
        ASP_NEWCUST_KEY: aspCustKey,
        SPST_CNSL_TYP_CD: box,
        USE_YN: "Y",
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      },
      {head: {
        "SERVICE" : "api.palette-main.aspcustkey",
        "METHOD" : "inqire",
        "TYPE" : "BIZ_SERVICE",
      }})
      .then((response) => {
        //console.log("상담 시작", response.data);
        if(consult == "*") {
          this.CNSL_LV_1_LIST = [...this.CNSL_LV_1_LIST, ...response.data.DATA];
        } else if(consult == "1"){
          if (this.CNSL_LV_1 == ''){
            this.CNSL_LV_2_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_2 = '';
            this.CNSL_LV_3_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_3 = '';
            this.CNSL_LV_4_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_4 = '';
          } else {
            this.CNSL_LV_2_LIST = [...this.CNSL_LV_2_LIST, ...response.data.DATA];
          }
        } else if(consult == "2"){
          if (this.CNSL_LV_2 == ''){
            this.CNSL_LV_3_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_3 = '';
            this.CNSL_LV_4_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_4 = '';
          }else {
            this.CNSL_LV_3_LIST = [...this.CNSL_LV_3_LIST, ...response.data.DATA];
          }
        } else if(consult == "3"){
          if (this.CNSL_LV_3 == ''){
            this.CNSL_LV_4_LIST = [{CD:'', CD_NM:'전체'}]
            this.CNSL_LV_4 = '';
          }else {
            this.CNSL_LV_4_LIST = [...this.CNSL_LV_4_LIST, ...response.data.DATA];
          }
        }
      })
      .catch((err) => {
        alert(err);
      })
    },

    //상담유형 사용
    //


    //시간 빈값 넣기
    returnEmpty(time){
      if(time.endsWith('00:00')){
        return '';
      } else {
        return time;
      }
    },

  },
  computed: {

    ...mapGetters({
      INLNE_NO: "userStore/GE_USER_INLNE_NO",
      IP_ADDR: "userStore/GE_USER_IP_ADDR",
      geUserId: "userStore/GE_USER_ID",
      //topMsg: "userStore/GE_USER_TOPMSG",
    }),

    /** 공통코드 조회 */
    async selectCommonCode() {
      this.commonCodeLst = await this.mixin_common_code_get_all(
        this.commonCode
      );
    },

    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
      // return this.dates.join(' ~ ')
    },

    DET_SRC_dateRangeText() {
      var newStartDate = this.DET_SRC_dates[0];
      var newEndDate = this.DET_SRC_dates[1];
      return newStartDate + " ~ " + newEndDate;
      // return this.dates.join(' ~ ')
    },

    computedDateFormatted() {
      return this.formatDate(this.date);
    },

    computedDateFormatted2() {
      return `${this.date} ${this.timeStep}`;
    },

    CNSL_RESVE_DT() {
      return `${this.cnslResveDate} ${this.cnslResveTimeStep}`;
    },
    CNSL_BOOK_DT() {
      return `${this.cnslBookDate} ${this.cnslBookTimeStep}`;
    },
    CONS_BOOK_DT() {
      return `${this.consBookDate} ${this.consBookTimeStep}`;
    },
    OPER_BOOK_DT() {
      return `${this.operBookDate} ${this.operBookTimeStep}`;
    },
    OPERATION_DT() {
      return `${this.operationDate} ${this.operationTimeStep}`;
    },
    VIS_BOOK_DT() {
      return `${this.visBookDate} ${this.visBookTimeStep}`;
    },

    chatListIng() {
      return this.chatList.filter((arr) => arr.type === "ing");
    },

    chatListDone() {
      return this.chatList.filter((arr) => arr.type === "done");
    },

    chatListAfter() {
      return this.chatList.filter(
        (arr) => arr.type === "after" || arr.type === "after countdown"
      );
    },

    chatListBookmark() {
      return this.chatList.filter((arr) => arr.bookmark === "bookmark");
    },

    chatListCallback() {
      return this.chatList.filter((arr) => arr.callback === "callback");
    },

    chatListReseve() {
      return this.chatList.filter((arr) => arr.statChat === true);
    },

    /****************************************************************************************************************************************************
     * 콜 computed START
     ***************************************************************************************************************************************************/

    ctiStateText() {

      let ctiState;

      console.log("");
      console.log("#####ctiStateText#############################");
      console.log("CTI_BRD_MOVE : " + this.CTI_BRD_MOVE.status);
      console.log("CTI_BRD_MOVE status : " + this.CTI_BRD_MOVE.status);
      console.log("CTI_BRD_MOVE current : " + this.CTI_BRD_MOVE.current);
      console.log("CTI_BRD current : " + this.CTI_BRD.current);

      if (this.CTI_BRD_MOVE.status == undefined ) {

        if (this.CTI_LOGIN_BOOLEAN == true) {
          ctiState = 'CTI ON';
        } else if (this.CTI_LOGIN_BOOLEAN == false) {
          ctiState = 'CTI OFF';
        }

        if (this.CTI_LOGIN_BOOLEAN == true && this.CTI_BRD.current != undefined && this.CTI_BRD.current != '' && this.CTI_BRD.current != 'null' && this.CTI_BRD.current != 'null') {
          ctiState = this.CTI_BRD.current;
        }

      } else if(this.CTI_BRD_MOVE.status == true ) {
        ctiState = this.CTI_BRD_MOVE.current;
      } else{
        ctiState = this.CTI_BRD.current;
      }

      return ctiState;

    },

    /****************************************************************************************************************************************************
     * 콜 computed END
     ***************************************************************************************************************************************************/
  },


  //220525 김태준 채팅 이벤트 추가
  //
  async mounted() {
    /*********************************
     * 1개회사 셀렉트박스 고정
     *******************************/
    this.CHK_ASP_NUM = this.mixin_check_AspCustKey_number();
    if(this.CHK_ASP_NUM == 'Y'){
      this.SCH_ASP_CUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.CUS_ASP_CUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }

    /****************************************************************************************************************************************************
     * 공통 변수 / 함수 정보 START
     ***************************************************************************************************************************************************/

      this.selectCommonCode;                                            //공통코드 조회

    /****************************************************************************************************************************************************
     * 공통 변수 / 함수 정보 END
     ****************************************************************************************************************************************************
     ***************************************************************************************************************************************************/


    /****************************************************************************************************************************************************
     * 챗 변수 / 함수 정보 START
     ***************************************************************************************************************************************************/

      window.addEventListener("unload", this.disconnect()); //this.calculateWindowWidth();
      window.addEventListener("beforeunload", this.disconnect()); //this.calculateWindowWidth();
      window.addEventListener("keydown", this.setAvailableRefresh); // 새로고침 f5 방지

      /** 상담메인 오픈 시 상담원상태 초기화  */
      this.changeUserStat(false, "ONREADY"); // 초기 채팅 상담사 상태 이벤트 처리

      this.setChatUserInfo(); // 상담사 정보 세팅

      this.getTalkList();

    /****************************************************************************************************************************************************
     * 챗 변수 / 함수 정보 END
     ****************************************************************************************************************************************************
     ***************************************************************************************************************************************************/

    /****************************************************************************************************************************************************
     * 콜 mounted START
     ***************************************************************************************************************************************************/

      /* 콜관련 공통코드 일괄조회 cmmnCd: ['PLT002', 'PLT003', 'PLT004','PLT006'] (콜관련공통코드_상담통합이력 콜유형_상담통합이력 IN_상담통합이력 OB_B/L 구분)*/
      this.cmmnCdLst = await this.mixin_common_code_get_all(this.cmmnCd);

      this.initCallCmmn();                    // 공통코드_cmmnCd: ['PLT002', 'PLT003', 'PLT004','PLT006']_콜관련공통코드_상담통합이력 콜유형_상담통합이력 IN_상담통합이력 OB_B/L 구분
      this.intiCall('ALL');                   // 콜관련 전체 초기화

      /** CTI Socket Connect */
      if (this.INLNE_NO != "" && this.INLNE_NO.length > 3) {
        if (this.IP_ADDR != "" && this.IP_ADDR != undefined) {
          this.ctiIpccConnect(this.INLNE_NO, this.IP_ADDR);     // IPCC Connect_내선번호, 상담사 로그인 IP
        }
      }

      /** ASP CUST KEY에 따른 OB 전용 활성화 */
      this.setAspCustKeyCtiOb();

      //페이지 열었을때 첫번째 회사의 이력 저장폼 불러오기
      this.CUS_ASP_CUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.CTI_INFO.strAspCustKey = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;

      this.getCnslSaveForm(this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD);


      /* 회사별 공통코드 조회 */
      this.cmmnCdLstByCus = await this.mixin_common_code_get_all(this.cmmnCdByCus);


      /* 퓨처누리 공통코드 조회 */
      this.cmmnCdLstFUNU = await this.mixin_common_code_get_all(this.cmmnCdFUNU);

      this.CNSL_RESULT_LIST = this.mixin_common_code_get(this.cmmnCdLst, 'CO001');           // 콜상담저장_상담통합 상담결과

        //채팅가능여부 판단
        this.getChatYN();

    /****************************************************************************************************************************************************
     * 고객대기, 상담원대기 콜바
     ***************************************************************************************************************************************************/

      //this.intervalCallbar();                                             //콜바 뷰 조회

    /****************************************************************************************************************************************************
     * 콜 mounted END
     ***************************************************************************************************************************************************/

      //페이지 로드시 셀렉트박스 생성
      this.getCmmCode();
  },
  //
  //220525 김태준 채팅 이벤트 추가

  beforeUnmount() {
    window.removeEventListener('beforeunload', this.disconnect());
  },

  watch: {
  },
};
</script>

<style></style>
