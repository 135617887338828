import axios from "axios";

import Vue      from 'vue';

export const apiCall = new Vue({
    methods: {
        /** CALL이벤트 
         * 
         * param 1 : url데이터
         * 
        */

        ipcc(url){
            let data = {
                "url": url,
                "method": "GET",
                "timeout": 0,
            };
            $.ajax(data).done(function (err, response) {
                if (response){
                    console.log(response);
                    return response;
                } else {
                    return err;
                }
            });
        }
        /*
        ipcc(ip,value){ 
            let url = "http://admin:meta12!!@" + ip + "/cgi-bin/ConfigManApp.com?key=" + value;
            axios.get(url);
        }
        */
    }

})